import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import eventBus from '../../utils/EventBus';

import './Stake.css';
import Actions from '../../actions';
import AppContainer from '../../components/AppContainer/AppContainer';
import LoadingModal from '../../components/Loading/LoadingModal';
import TitleDescValueView from './TitleDescValueView';
import AddNewStakeView from './AddNewStakeView';
import AllStakeView from './AllStakeView';

const Stake = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        eventBus.on("stakeLoading", (data) => {
            setLoading(data.message);
        });

        return () => {
            eventBus.remove("stakeLoading");
        }

    }, []);

    return (
        <AppContainer>
            <LoadingModal show={loading} text={t('Loading...')} />
            <TitleDescValueView />
            <AddNewStakeView />
            <AllStakeView />
        </AppContainer>
    )
}

const mapStateToProps = store => ({
    getWalletAddressData: Actions.getWalletAddressData(store),
});

const mapDispatchToProps = {
};


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Stake);