import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useAsyncEffect from 'use-async-effect';
import { Decimal } from 'decimal.js';

import MosTokenService from '../../services/MosTokenService';
import MosPlatformService from '../../services/MosPlatformService';
import { MOS_TOKEN_CONTRACT_ADDRESS } from '../../config';
import { USDT_DECIMALS } from '../../constant';

import eventBus from '../../utils/EventBus';
import Actions from '../../actions';
import LoadingModal from '../../components/Loading/LoadingModal';
import AppContainer from '../../components/AppContainer/AppContainer';
import ChartAssetOverview from './PieChartAssetOverview/ChartAssetOverview';
import MosStatisticsView from './MosStatistics/MosStatisticsView';
import MosInfoView from './MosInfo/MosInfoView';
import LandingTableView from './LandingTable/LandingTableView';
import DailyClosePriceChart from './LineCharts/DailyClosePriceChart';
import DailyMosPayoutPerMShares from './LineCharts/DailyMosPayoutPerMShares';
import { getDailyData } from '../../api';

const Landing = (props) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [mosTokenService, setMosTokenService] = useState();
    const [mosPlatformService, setMosPlatformService] = useState();
    const [mosPrice, setMosPrice] = useState(0.0000);
    const [dailyData, setDailyData] = useState([]);

    useAsyncEffect(async () => {
        let res = await getDailyData();
        if (res) {
            setDailyData(res);
        }

        eventBus.on("landingLoading", (data) => {
            setLoading(data.message);
        });

        return () => {
            eventBus.remove("landingLoading");
        }

    }, []);

    useAsyncEffect(async () => {
        await initServices();
    }, [props.getWalletAddressData]);

    const initServices = async () => {
        setTimeout(async () => {
            let mosTokenService = new MosTokenService();
            await mosTokenService.connectContract(MOS_TOKEN_CONTRACT_ADDRESS);
            setMosTokenService(mosTokenService);

            let mosPlatformService = new MosPlatformService();
            await mosPlatformService.connectContract();
            setMosPlatformService(mosPlatformService);
        }, 3000);
    }

    useAsyncEffect(async () => {
        if (mosTokenService && mosPlatformService) {
            getMosPrice();
        }
    }, [mosTokenService, mosPlatformService]);

    const getMosPrice = async () => {
        // setTimeout(async () => {
        let mosTokenDecimal = await mosTokenService.getDecimals();
        let currentDay = await mosPlatformService.getCurrentDay();
        let pool = await mosPlatformService.poolValue(currentDay - 1);
        let bid = await mosPlatformService.xfLobby(currentDay - 1);

        let mosPool = new Decimal(pool).dividedBy(10 ** mosTokenDecimal);
        let usdtBid = new Decimal(bid).dividedBy(10 ** USDT_DECIMALS);
        if (currentDay !== 0) {
            let auctRate = mosPool.dividedBy(usdtBid);
            let price = new Decimal(1).dividedBy(auctRate).toFixed(5);
            setMosPrice(price);
        }
        // }, 3000);
    }

    return (
        <AppContainer>
            <LoadingModal show={loading} text={t('Loading...')} />
            <ChartAssetOverview mosPrice={mosPrice} />
            <MosStatisticsView mosTokenService={mosTokenService} mosPlatformService={mosPlatformService} />
            <MosInfoView mosTokenService={mosTokenService} mosPlatformService={mosPlatformService} />
            <DailyClosePriceChart />
            <DailyMosPayoutPerMShares dailyData={dailyData} />

            <LandingTableView dailyData={dailyData} />
        </AppContainer>
    );
}

const mapStateToProps = store => ({
    getWalletAddressData: Actions.getWalletAddressData(store),
});

const mapDispatchToProps = {
};


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Landing);
