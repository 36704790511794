import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import { formatNumber } from '../../../utils/Utils';

const MosInfoWeb = (props) => {
    const { t } = useTranslation();

    return (
        <div className="outer-content-wrapper landing-mos-info-web-container" style={{ color: 'white' }}>
            <ReactTooltip id='mos-info-web-share-rate' aria-haspopup='true' role='example'>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div>{t('share rate')} = </div>
                    <div style={{ textAlign: 'center', paddingLeft: '6px' }}>
                        <div style={{ borderBottom: '1px solid #F3B64E' }}>Total Asset Staked</div>
                        <div>Total Share</div>
                    </div>
                </div>
            </ReactTooltip>

            {/* <div className="mos-info-web-row">
                <div className="mos-info-top-row-element">
                    <span className="font-lora mos-info-web-title">Crypto Rank</span>
                    <span className="font-sf-pro mos-info-web-value">#745</span>
                </div>
                <div className="vertical-rule-web" />

                <div className="mos-info-top-row-element">
                    <span className="font-lora mos-info-web-title">Total Stakes</span>
                    <span className="font-sf-pro mos-info-web-value">24,422</span>
                </div>
                <div className="vertical-rule-web" />

                <div className="mos-info-top-row-element">
                    <span className="font-lora mos-info-web-title">Average Stake Size</span>
                    <span className="font-sf-pro mos-info-web-value">9.18M</span>
                </div>
                <div className="vertical-rule-web" />

                <div className="mos-info-top-row-element">
                    <span className="font-lora mos-info-web-title">Average Stake Length</span>
                    <span className="font-sf-pro mos-info-web-value">2,287 Days</span>
                </div>
            </div>

            <div className="horizontal-rule-web" /> */}

            {/* <div className="mos-info-web-row">
                <div className="mos-info-bottom-row-element">
                    <span className="font-lora mos-info-web-title">Market Cap</span>
                    <span className="font-sf-pro mos-info-web-value">$ 15.91M</span>

                </div>
                <div className="vertical-rule-web" />

                <div className="mos-info-bottom-row-element">
                    <span className="font-lora mos-info-web-title">% Staked</span>
                    <span className="font-sf-pro mos-info-web-value">{props.globalInfo ? props.globalInfo.percentStaked : '0'}%</span>
                </div>
                <div className="vertical-rule-web" />

                <div className="mos-info-bottom-row-element">
                    <span className="font-lora mos-info-web-title" style={{ display: 'flex', alignItems: 'center' }}>Share Rate<a data-tip data-for='mos-info-web-share-rate' className="mos-info-question-mark">?</a></span>
                    <span className="font-sf-pro mos-info-web-value">{props.globalInfo ? props.globalInfo.shareRate : '0'}</span>
                </div>
            </div> */}


            <div className="mos-info-web-row">
                <div className="mos-info-top-row-element">
                    <span className="font-lora mos-info-web-title">Total Stakes</span>
                    <span className="font-sf-pro mos-info-web-value">{props.globalInfo.totalStakes ? formatNumber(props.globalInfo.totalStakes) : '0'}</span>
                </div>
                <div className="vertical-rule-web" />

                <div className="mos-info-top-row-element">
                    <span className="font-lora mos-info-web-title">% Staked</span>
                    <span className="font-sf-pro mos-info-web-value">{props.globalInfo ? props.globalInfo.percentStaked : '0'}%</span>
                </div>
                <div className="vertical-rule-web" />

                <div className="mos-info-top-row-element">
                    <span className="font-lora mos-info-web-title" style={{ display: 'flex', alignItems: 'center' }}>Share Rate<a data-tip data-for='mos-info-web-share-rate' className="mos-info-question-mark">?</a></span>
                    <span className="font-sf-pro mos-info-web-value">{props.globalInfo ? props.globalInfo.shareRate : '0'}</span>
                </div>
                <div className="vertical-rule-web" />

                <div className="mos-info-top-row-element">
                    <span className="font-lora mos-info-web-title">Average Stake Length</span>
                    <span className="font-sf-pro mos-info-web-value">{props.averageStakeInfo ? props.averageStakeInfo["AVG(stakeDays)"] : '0'} Days</span>
                </div>

            </div>
        </div>

    )
}

export default MosInfoWeb;