import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Modal } from 'react-bootstrap';

import Button from '../../components/Button/Button'
import { formatNumber, shortenPriceObj } from '../../utils/Utils';

const SHORTEN_VALUE = 999999;
const DAILY_AUCTION_POOL = '139.51M';
const FIRST_DAY_AUCTION_POOL = '419M';

const AuctionTableWeb = (props) => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [showExitAuctionModal, setShowExitAuctionModal] = useState({ show: false, day: 0 });

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    const formatValue = (val) => {
        let shortenPrice = shortenPriceObj(val);
        if (val > SHORTEN_VALUE) {
            let value = shortenPrice.price.toFixed(2);
            let suffix = shortenPrice.suffix;
            return `${value}${suffix}`;
        } else {
            return formatNumber(val.toFixed(4));
        }
    }

    const ExitAuctionModal = () => {
        return (
            <Modal
                id="tron-listener-modal"
                show={showExitAuctionModal.show}
                onHide={_ => setShowExitAuctionModal({ show: false })}
                centered>
                <Modal.Header>
                    <Modal.Title>{t('Attention')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>Exit all bid for day {showExitAuctionModal.day + 1}</div>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        title={'Cancel'}
                        containerStyle={{ padding: '6px 20px', margin: '10px', backgroundColor: '#898989' }}
                        titleStyle={{ fontSize: '18px' }}
                        onClick={() => {
                            setShowExitAuctionModal({ show: false });
                        }}
                    />
                    <Button
                        title={'Exit Bid'}
                        containerStyle={{ padding: '6px 20px', margin: '10px' }}
                        titleStyle={{ fontSize: '18px' }}
                        onClick={() => {
                            setShowExitAuctionModal({ show: false });
                            props.onExitBidPress(showExitAuctionModal.day);
                        }}
                    />
                </Modal.Footer>

            </Modal>
        )
    }

    return (
        <div className="outer-content-wrapper auction-bottom-container">
            <ExitAuctionModal />
            <div className="content-wrapper ">
                <div className="auction-table-web font-sf-pro">
                    <div style={{ gridArea: '1 / 1 / 2 / 2', borderLeft: 'unset', color: '#F3B64E' }} className="auction-table-web-header"><span>Day</span></div>
                    <div style={{ gridArea: '1 / 2 / 2 / 3' }} className="auction-table-web-header"><span>{t('daily auction pool')}</span></div>
                    <div style={{ gridArea: ' 1 / 3 / 2 / 4' }} className="auction-table-web-header"><span>{t('total usdt bid')}</span></div>
                    <div style={{ gridArea: '1 / 4 / 2 / 5' }} className="auction-table-web-header"><span>MOS / USDT</span></div>
                    <div style={{ gridArea: '1 / 5 / 2 / 6' }} className="auction-table-web-header"><span>{t('status')}</span></div>
                    <div style={{ gridArea: '1 / 6 / 2 / 7' }} className="auction-table-web-header"><span>{t('your mos')}</span></div>
                    <div style={{ gridArea: '1 / 7 / 2 / 8' }} className="auction-table-web-header"><span>{t('your usdt bid')}</span></div>

                    {data && data.map((dayRecord) => {
                        {/* console.log("day rec: ", dayRecord); */ }
                        let status = "";
                        let auctionRate = 0;
                        let dailyAuctionPool = DAILY_AUCTION_POOL;
                        if ((dayRecord.day + 1) === 1) dailyAuctionPool = FIRST_DAY_AUCTION_POOL;
                        if (dayRecord.day === props.currentDay.current) {
                            auctionRate = 'N/A';
                        } else if (dayRecord.usdtBid !== 0) {
                            auctionRate = formatValue(dayRecord.mosPool / dayRecord.usdtBid);
                        }

                        if (dayRecord.day !== props.currentDay.current) {
                            status = "Closed";
                        } else {
                            status = `Open`;
                        }

                        return (
                            <div style={{ display: 'contents' }} key={`${dayRecord.day}+${dayRecord.mosPool}`}>
                                <div style={{ borderLeft: 'unset' }} className="auction-table-web-data"><span>{dayRecord.day + 1}</span></div>
                                <div className="auction-table-web-data"><span>{dailyAuctionPool}</span></div>
                                {/* <div className="auction-table-web-data"><span>{formatValue(dayRecord.mosPool)}</span></div> */}
                                <div className="auction-table-web-data"><span>{formatValue(dayRecord.usdtBid)}</span></div>
                                <div className="auction-table-web-data"><span>{auctionRate}</span></div>
                                <div className="auction-table-web-data"><span>{status}</span></div>
                                <div className="auction-table-web-data" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>{dayRecord.userInfo.claimedMos === 0 ? formatValue(dayRecord.userInfo.unclaimedMos) : formatValue(dayRecord.userInfo.claimedMos)}</span>
                                    {status === "Closed" && dayRecord.userInfo.bidAmount > 0 && dayRecord.userInfo.unclaimedMos > 0 &&
                                        <div className="content-wrapper auction-exit-btn" style={{ color: '#F3B64E', padding: '4px' }} onClick={() => { setShowExitAuctionModal({ show: true, day: dayRecord.day }) }}>Exit</div>
                                        //<div className="content-wrapper auction-exit-btn" style={{ color: '#F3B64E', padding: '4px' }} onClick={() => { props.onExitBidPress(dayRecord.day) }}>Exit</div>
                                    }
                                </div>
                                <div className="auction-table-web-data"><span>{formatValue(dayRecord.userInfo.bidAmount)}</span></div>
                            </div>
                        )
                    })}

                </div>
                {data.length === 0 &&
                    <div style={{ color: 'grey', textAlign: 'center', borderTop: 'thin solid grey', padding: '6px 0px' }}>
                        None
                    </div>
                }
            </div>
        </div>
    )
}

export default AuctionTableWeb;