import React, { useState, useEffect } from 'react';
import { Decimal } from 'decimal.js';
import { PieChart } from 'react-minimal-pie-chart';
import useAsyncEffect from 'use-async-effect';

import { useMediaQuery } from '../../customHooks/useMediaQuery';
import './TitleDescValueView.css';
import { formatNumber, numberFormatOwnDecimal, priceFormat, shortenPriceObj } from '../../utils/Utils';
import { useTranslation } from 'react-i18next';
import eventBus from '../../utils/EventBus';
import { USDT_DECIMALS } from '../../constant';
import MosPlatformService from '../../services/MosPlatformService';
import MosTokenService from '../../services/MosTokenService';

const SHORTEN_VALUE = 999999;

const formatValue = (val) => {
    let shortenPrice = shortenPriceObj(val);
    if (val > SHORTEN_VALUE) {
        let value = shortenPrice.price.toFixed(2);
        let suffix = shortenPrice.suffix;
        return `${value}${suffix}`;
    } else {
        return formatNumber(val.toFixed(0));
    }
}

const TitleDescValueView = (props) => {
    const { t } = useTranslation();
    let isMobile = useMediaQuery();
    const [mosTokenService, setMosTokenService] = useState(null);
    const [mosPlatformService, setMosPlatformService] = useState(null);

    const [totalSupply, setTotalSupply] = useState(10000);
    const [staked, setStaked] = useState(10000);
    const [liquid, setLiquid] = useState(10000);
    const [mosPrice, setMosPrice] = useState(0.0000);

    // const [outerChartData, setOuterChartData] = useState([]);
    const [innerChartData, setInnerChartData] = useState([]);

    useAsyncEffect(async () => {
        initServices();
    }, []);

    const initServices = () => {
        setTimeout(async () => {
            let mosPlatformService = new MosPlatformService();
            await mosPlatformService.connectContract();
            setMosPlatformService(mosPlatformService);

            let mosTokenService = new MosTokenService();
            await mosTokenService.connectContract();
            setMosTokenService(mosTokenService);
        }, 3000);
    }

    useAsyncEffect(async () => {
        try {
            if (mosTokenService && mosPlatformService) {
                eventBus.dispatch("stakeLoading", { message: true });
                let decimal = await mosTokenService.getDecimals();

                let staked = await mosPlatformService.allocatedSupply();
                let stakedMos = new Decimal(staked._hex).dividedBy(10 ** decimal);
                setStaked(stakedMos.toFixed(2));

                let total = await mosTokenService.totalSupply();
                let totalSupply = new Decimal(total._hex).dividedBy(10 ** decimal);
                setLiquid(totalSupply.toFixed(2));

                let cached = await mosPlatformService.allocatedSupply();
                let cachedSupply = new Decimal(cached._hex).dividedBy(10 ** decimal);

                let totalAssetValue = cachedSupply.add(totalSupply);
                setTotalSupply(totalAssetValue.toFixed(2));

                let currentDay = await mosPlatformService.getCurrentDay();
                let pool = await mosPlatformService.poolValue(currentDay - 1);
                let bid = await mosPlatformService.xfLobby(currentDay - 1);

                let mosPool = new Decimal(pool).dividedBy(10 ** decimal);
                let usdtBid = new Decimal(bid).dividedBy(10 ** USDT_DECIMALS);
                if (currentDay !== 0) {
                    let auctRate = mosPool.dividedBy(usdtBid);
                    let price = new Decimal(1).dividedBy(auctRate).toFixed(5);
                    setMosPrice(price);
                }

                eventBus.dispatch("stakeLoading", { message: false });
            }
        } catch (err) {
            eventBus.dispatch("stakeLoading", { message: false });
        }
    }, [mosTokenService, mosPlatformService]);

    useEffect(() => {
        // let outerChartData = [
        //     { title: 'Total', value: Number(totalSupply ?? '0'), color: '#F3B64E' },
        //     { title: 'Staked', value: Number(staked), color: '#FFE98D' },
        //     { title: 'Liquid', value: Number(liquid), color: '#E8EFCE' },
        // ]
        // setOuterChartData(outerChartData)
        let innerChartData = [
            { title: 'Staked', value: Number(staked), color: '#176EBF', key: 'innerChart-staked' },
            { title: 'Liquid', value: Number(liquid), color: '#00BDFF', key: 'innerChart-liquid' },
        ]
        setInnerChartData(innerChartData);
    }, [totalSupply, staked, liquid]);

    const TitleValueView = (props) => {
        return (
            <div className="tdvv__item-container" style={props.containerStyle}>
                <div className="tdvv__item-title-container">
                    {(props.showDot ?? true) &&
                        <div className="ttdv__item-dot" style={props.dotColor && { backgroundColor: props.dotColor }} />
                    }
                    <div className="ttdv__item-title font-lora">{props.title}</div>
                </div>
                <div className="tdvv__item-amount">{props.amount}</div>
                <div className="tdvv__item-price">{props.price}</div>
            </div>
        )
    }

    const ValueCircleView = () => {
        return (
            // <div className="tdvv__circle-background">
            //     <div className="tdvv__circle-title">Total</div>
            //     <div className="tdvv__circle-value">277B</div>
            // </div>
            <PieChart
                // data={outerChartData}
                data={innerChartData}
                lineWidth={4}
                paddingAngle={12}
                rounded
                animate
                label={({ x, y, dx, dy }) => {
                    // return (
                    //     <PieChart
                    //         data={innerChartData}
                    //         lineWidth={4}
                    //         paddingAngle={12}
                    //         rounded
                    //         animate
                    //         viewBoxSize={[120, 120]}
                    //         center={[60, 60]}
                    //         label={({ x, y, dx, dy }) => {
                    return (
                        <>
                            <text
                                x={x}
                                y={38}
                                dx={dx}
                                dy={dy}
                                dominantBaseline="central"
                                textAnchor="middle"
                                className='tdvv__circle-title'>
                                {`Total`}
                            </text>
                            <text
                                x={x}
                                y={58}
                                dx={dx}
                                dy={dy}
                                dominantBaseline="central"
                                textAnchor="middle"
                                className="tdvv__circle-value">
                                {formatValue(Number(totalSupply))}
                            </text>
                        </>
                    );
                    //         }}
                    //         labelPosition={0}
                    //     />
                    // );
                }}
                labelPosition={0}
            />
        )
    }

    return (
        <div className="outer-content-wrapper tdvv__container">
            {isMobile ?
                <React.Fragment>
                    <div className="tdvv__right-contaner">
                        <ValueCircleView />
                    </div>
                    <div className="tdvv__title font-lora">Staking</div>
                    <div className="tdvv__desc"><br />Stake MOS to earn high-yield time locked deposit.<br /><br />By creating a stake, your $MOS are put aside as a Certified Deposit, you will receive multiple rewards as your interest.</div>
                    <TitleValueView
                        title={'Staked'}
                        amount={numberFormatOwnDecimal(staked)}
                        price={`≈ $ ${priceFormat(Number(staked) * mosPrice)}`}
                        containerStyle={{ marginTop: '10vw' }}
                    />
                    <TitleValueView
                        title={'Circulating Supply'}
                        amount={numberFormatOwnDecimal(liquid)}
                        price={`≈ $ ${priceFormat(Number(liquid) * mosPrice)}`}
                        dotColor={'#00BDFF'}
                    />
                    <TitleValueView
                        title={'Total Asset Value'}
                        amount={formatNumber(`${totalSupply}`)}
                        price={`≈ $ ${priceFormat(Number(totalSupply) * mosPrice)}`}
                        showDot={false}
                        containerStyle={{ borderStyle: 'none' }}
                    />
                </React.Fragment>
                :
                <React.Fragment>
                    <div className="tdvv__left-container">
                        <div>
                            <div className="tdvv__title font-lora">Staking</div>
                            <div className="tdvv__desc">Stake MOS to earn high-yield time locked deposit.<br /><br />By creating a stake, your $MOS are put aside as a Certified Deposit,<br />you will receive multiple rewards as your interest.</div>
                        </div>
                        <div className="ttdv__item">
                            <TitleValueView
                                title={'Staked'}
                                amount={numberFormatOwnDecimal(staked)}
                                price={`≈ $ ${priceFormat(Number(staked) * mosPrice)}`}
                            />
                            <TitleValueView
                                title={'Circulating Supply'}
                                amount={numberFormatOwnDecimal(liquid)}
                                price={`≈ $ ${priceFormat(Number(liquid) * mosPrice)}`}
                                dotColor={'#00BDFF'}
                            />
                            <TitleValueView
                                title={'Total Asset Value'}
                                amount={formatNumber(`${totalSupply}`)}
                                price={`≈ $ ${priceFormat(Number(totalSupply) * mosPrice)}`}
                                showDot={false}
                            />
                        </div>
                    </div>
                    <div className="tdvv__right-contaner">
                        <ValueCircleView />
                    </div>
                </React.Fragment>
            }

        </div>
    )
}


export default TitleDescValueView;