import React, { lazy, Suspense } from 'react';
import SpinnerLoading from '../components/Spinner/Spinner';

import Auction from '../containers/Auction/Auction';
import Refer from '../containers/Refer/Refer';
import Landing from '../containers/Landing/Landing';
import Stake from '../containers/Stake/Stake';
import Claim from '../containers/Claim/Claim';

const loadable = (importFunc, { fallback = null } = { fallback: null }) => {
    const LazyComponent = lazy(importFunc);

    // eslint-disable-next-line react/display-name
    return (props) => (
        <Suspense fallback={fallback}>
            <LazyComponent {...props} />
        </Suspense>
    );
};

const HomeComponent = loadable(() => import('../containers/Landing/Landing'), {
    fallback: (
        <div style={{ backgroundColor: '#191919', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <SpinnerLoading text="loading home page" />
        </div>
    )
});

const ClaimComponent = loadable(() => import('../containers/Claim/Claim'), {
    fallback: (
        <div style={{ backgroundColor: '#191919', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <SpinnerLoading text="loading claim page" />
        </div>
    )
});

const StakeComponent = loadable(() => import('../containers/Stake/Stake'), {
    fallback: (
        <div style={{ backgroundColor: '#191919', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <SpinnerLoading text="loading stake page" />
        </div>
    )
});

const AuctionComponent = loadable(() => import('../containers/Auction/Auction'), {
    fallback: (
        <div style={{ backgroundColor: '#191919', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <SpinnerLoading text="loading auction page" />
        </div>
    )
});

const ReferComponent = loadable(() => import('../containers/Refer/Refer'), {
    fallback: (
        <div style={{ backgroundColor: '#191919', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <SpinnerLoading text="loading refer page" />
        </div>
    )
});

export const routes = [
    { path: ["/", "/home"], render: <HomeComponent /> },
    { path: "/claim", render: <ClaimComponent /> },
    { path: "/stake", render: <StakeComponent /> },
    { path: "/auction/:refCode?", render: <AuctionComponent /> },
    { path: "/refer", render: <ReferComponent /> },
]