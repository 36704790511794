import React from "react";
import { useTranslation } from 'react-i18next';
import BounceLoader from "react-spinners/BounceLoader";

const SpinnerLoading = (props) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <BounceLoader color='#F3B64E' size={60} />
            <div style={{ color: '#fafafa', margin: '10px' }}>{props.text === "" ? "Preparing... " : t(`${props.text}`)}</div>
        </React.Fragment>
    );

}

export default SpinnerLoading;