import React from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './WebHeader.css'
import mosLogo from '../../assets/images/mos-logo-word-white.svg';
import languageLogo from '../../assets/images/header/language.svg';
import TronModal from '../../services/TronModal';

const WebHeader = (props) => {
    const { i18n, t } = useTranslation();

    return (
        <Navbar bg="transparent" expand="lg" variant="dark" className="web-header__container">
            <Navbar.Brand href="/">
                <img
                    src={mosLogo}
                    width="120"
                    className="web-header__brand-logo"
                />
            </Navbar.Brand>
            <Nav className="web-header__nav-body">
                <NavLink to="/" exact className="web-header-link">
                    Home
                </NavLink>
                <NavLink to="/stake" className="web-header-link">
                    Stake
                </NavLink>
                <NavLink to="/claim" className="web-header-link">
                    Claim
                </NavLink>
                <NavLink to="/auction" className="web-header-link">
                    Auction
                </NavLink>
                <NavLink to="/refer" className="web-header-link">
                    Refer
                </NavLink>
            </Nav>
            <Nav className="web-header__language__container">

                <NavDropdown
                    alignRight
                    title={
                        <div style={{ display: 'flex' }}>
                            <img
                                src={languageLogo}
                                width="24"
                                height="24"
                                style={{ marginRight: '8px' }}
                            />
                            {i18n.language}
                        </div>
                    }
                    id="basic-nav-dropdown" className="web-header__language">
                    <NavDropdown.Item onClick={() => { i18n.changeLanguage('en') }}>English</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item>简体中文</NavDropdown.Item>
                    {/* <NavDropdown.Item onClick={() => { i18n.changeLanguage('zh') }}>简体中文</NavDropdown.Item> */}
                </NavDropdown>
            </Nav>
            <TronModal />
        </Navbar>
    )
}

export default WebHeader;