import React from 'react';
import './Button.css';

const Button = (props) => {
    return (
        <div 
            type="submit" 
            className={`button-component-container ${props.containerClassName}`} 
            style={props.containerStyle} 
            onClick={props.onClick}>
            <span 
                className={`font-lora button-component-title ${props.className}`} 
                style={props.titleStyle}>
                {props.title}
            </span>
        </div>
    )
}

export default Button;