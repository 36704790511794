import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import Actions from '../actions';
import Button from '../components/Button/Button';
import './TronModal.css';

import { getAddress } from './TronServices';
import { NETWORK_API, NETWORK } from '../config';
import { getShorterAddress } from '../utils/Utils';
// import { NETWORK_API_ARR_MAINNET } from '../constant.js';

const TronModal = (props) => {
    const { t } = useTranslation();
    const [status, setStatus] = useState(-1); // please reference constants tronweb status

    const getTronWebStatus = () => {
        setStatus(0);
        let retry = 0;
        let interval = setInterval(() => {
            try {
                if (!!window.tronWeb) {
                    // alert(window.tronWeb?.fullNode?.host);
                    // alert(window.tronWeb.defaultAddress.base58);
                    clearInterval(interval)
                    if (!window.tronWeb?.ready) return setStatus(2);
                    // if (!(window.tronWeb?.fullNode?.host === NETWORK_API)) return setStatus(3);
                    if (!NETWORK_API.includes(window.tronWeb?.fullNode?.host)) return setStatus(3);
                    return setStatus(1)
                }
                if (retry === 2) {
                    clearInterval(interval)
                    return setStatus(4);
                }
            } catch (error) {
                throw new Error(`TronWeb Status Error: ${error.message}`)
            }
            retry++;
        }, 1500)
    }

    useEffect(() => {
        async function setWalletAddress() {
            if (status === 1) {
                let address = await getAddress();
                props.onWalletAddress(address);
            } else if (status !== 0) {
                window.removeEventListener('message', onSwitchAddress);
                window.removeEventListener('message', onSwitchNode);
                props.onWalletAddress(null);
            }
        }
        setWalletAddress();
    }, [status])

    useEffect(() => {
        if (props.getWalletAddressData.data) {
            window.addEventListener("message", onSwitchAddress);
            window.addEventListener("message", onSwitchNode);
        } else {
            window.removeEventListener('message', onSwitchAddress);
            window.removeEventListener('message', onSwitchNode);
        }
    }, [props.getWalletAddressData])

    const onSwitchNode = async (event) => {
        if (event.data.message && event.data.message.action == "setNode") {
            // console.log("setNode event", event.data.message.data.node.fullNode)
            getTronWebStatus();
        }
    }


    const onSwitchAddress = async (event) => {
        if (event.data.message && event.data.message.action === 'setAccount') {
            let userAddress = props.getWalletAddressData.data;
            let newAddress = event?.data?.message?.data?.address;
            // console.log("setAccount event", event?.data?.message?.data?.address)
            if (newAddress !== userAddress) {
                getTronWebStatus();
            }
        }
    }

    return (
        <div>
            <div
                onClick={() => { getTronWebStatus() }}
                className={'tron-listener-modal-button tron-listener-modal-connect'}
            >
                <span className={"font-lora tron-listener-modal-button-title tron-listener-modal-connect-title"}>
                    {props.getWalletAddressData.data ? getShorterAddress(props.getWalletAddressData.data) : 'Connect'}
                </span>
            </div>
            <Modal
                id="tron-listener-modal"
                show={status !== 1 && status !== -1}
                onHide={_ => { }}
                centered>
                <Modal.Header>
                    <Modal.Title>{t('Attention')}</Modal.Title>
                </Modal.Header>
                {status === 3 && NETWORK === "Mainnet" &&
                    <Modal.Body>
                        {t("Please connect to TRON Mainnet")}
                        {/* {t('Please connect to Nile Testnet')} */}
                    </Modal.Body>
                }
                {status === 3 && NETWORK === "Testnet" &&
                    <Modal.Body>
                        {t('Please connect to Shasta Testnet')}
                    </Modal.Body>
                }
                {status === 4 &&
                    <Modal.Body>
                        {/* {t('TronLink wallet extension is not detected')}
                        {t('Please download the extension')} <a href={`https://www.tronlink.org/`} target="_blank">{t('here')}</a>. */}
                        Wallet not detected. Please download MathWallet or TokenPocket for your mobile, or download the TronLink wallet extension on desktop.
                    </Modal.Body>
                }
                {status === 2 &&
                    <Modal.Body>{t('Please login to your TronLink extension')}</Modal.Body>
                }
                {status === 0 &&
                    <Modal.Body>{t('Connecting TronLink wallet')}...</Modal.Body>
                }
                {status !== 0 &&
                    <Modal.Footer>
                        <Button
                            title={'Ok'}
                            containerClassName={'tron-listener-modal-button'}
                            className={"tron-listener-modal-button-title"}
                            onClick={() => { setStatus(-1) }}
                        />
                    </Modal.Footer>
                }
            </Modal>
        </div>
    );
}




const mapStateToProps = store => ({
    getWalletAddressData: Actions.getWalletAddressData(store),
});

const mapDispatchToProps = {
    onWalletAddress: Actions.walletAddress,
};


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TronModal);