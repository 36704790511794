import React, { useState } from 'react';
import { useMediaQuery } from '../../../customHooks/useMediaQuery';
import useAsyncEffect from 'use-async-effect';
import Decimal from 'decimal.js';

import MosInfoMobile from './MosInfoMobile';
import MosInfoWeb from './MosInfoWeb';
import './MosInfoView.css';
import { getAverageStakeLength } from '../../../api';

const MosInfoView = (props) => {
    let isMobile = useMediaQuery();
    const [globalInfo, setGlobalInfo] = useState({});
    const [averageStakeInfo, setAverageStakeInfo] = useState({});

    useAsyncEffect(async () => {
        let averageStakeLength = await getAverageStakeLength();
        setAverageStakeInfo(averageStakeLength[0]);
    }, []);

    useAsyncEffect(async () => {
        if (props.mosPlatformService) {
            let globalInfo = await props.mosPlatformService.globalInfo();
            let allocatedSupply = await props.mosPlatformService.allocatedSupply();
            if (globalInfo) {
                let percentStaked = 0;
                if (new Decimal(globalInfo[0]._hex).eq(0) || new Decimal(allocatedSupply._hex).eq(0)) {
                    percentStaked = percentStaked.toFixed(2);
                } else {
                    percentStaked = new Decimal(globalInfo[0]._hex).dividedBy(allocatedSupply._hex).toFixed(2);
                }
                let data = {
                    totalStakes: new Decimal(globalInfo[6]._hex).toString(),
                    shareRate: new Decimal(globalInfo[0]._hex).dividedBy(globalInfo[5]._hex).toFixed(4),
                    percentStaked: percentStaked,
                }
                // console.log("data: ", data);
                setGlobalInfo(data);
            }
        }
    }, [props.mosPlatformService]);

    return isMobile ?
        (<MosInfoMobile globalInfo={globalInfo} averageStakeInfo={averageStakeInfo} />)
        :
        (<MosInfoWeb globalInfo={globalInfo} averageStakeInfo={averageStakeInfo} />)
}

export default MosInfoView;