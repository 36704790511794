import React from 'react';

import './AppContainer.css';
// import Header from '../Header/Header';
// import TronModal from '../../services/TronModal';

const AppContainer = (props) => {
    return (
        <div className="app-container">
            {/* <TronModal /> */}
            <div className="content-container">
                {props.children}
            </div>
        </div>
    )
}

export default AppContainer;