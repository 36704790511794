import { MOS_PLATFORM_CONTRACT_ADDRESS } from "../config";
import MosPlatformABI from '../assets/abi/MosPlatform.json';
import TronProvider from '../TronProvider';
import { convertToDecimal } from '../utils/Utils';

async function _connectReadContract(addr) {
    try {
        return await TronProvider.contract(MosPlatformABI, addr);
        // return await TronProvider.contract().at(addr);
    } catch (err) {
        console.error(`TronWeb Connect Contract Error: `, err);
    }
}

async function _connectWriteContract(addr) {
    try {
        // return await window.tronWeb.contract().at(addr);
        return await window.tronWeb.contract(MosPlatformABI, addr);
    } catch (err) {
        console.error(`TronWeb Connect Contract Error: `, err);
    }
}

class MosPlatformService {
    constructor(props) {
        this.readContract = null;
        this.writeContract = null;
    }

    async connectContract() {
        [this.readContract, this.writeContract] = await Promise.all([
            _connectReadContract(MOS_PLATFORM_CONTRACT_ADDRESS),
            _connectWriteContract(MOS_PLATFORM_CONTRACT_ADDRESS)
        ])
        return;
    }

    async allocatedSupply() {
        try {
            return await this.readContract.allocatedSupply().call();
        } catch (err) {
            console.error(`allocatedSupply Error: `, err);
        }
    }

    async globalInfo() {
        try {
            return await this.readContract.globalInfo().call();
        } catch (err) {
            console.error(`globalInfo Error: `, err);
        }
    }

    async getCurrentDay() {
        try {
            return convertToDecimal(await this.readContract._currentDay().call());
        } catch (err) {
            console.error(`currentDay Error: `, err);
        }
    }

    async xfLobby(day) {
        try {
            let xfLobby = await this.readContract.xfLobby(day).call();
            return convertToDecimal(xfLobby._hex);
        } catch (err) {
            console.error(`xfLobby Error: `, err);
        }
    }

    async poolValue(day) {
        try {
            let poolValue = await this.readContract.poolValue(day).call();
            return convertToDecimal(poolValue._poolValue._hex);
        } catch (err) {
            console.error(`poolValue Error: `, err);
        }
    }

    async decimals() {
        try {
            return await this.readContract.decimals().call();
        } catch (err) {
            console.error(`mos decimals Error: `, err);
        }
    }

    async userAuctionResult(enterDay, address) {
        try {
            let res = await this.readContract.userAuctionResult(enterDay, address).call();
            // console.log("res: ", res);
            let convertedInfo = {
                bidAmount: convertToDecimal(res.bidAmount._hex),
                claimedMos: convertToDecimal(res.claimedMos._hex),
                unclaimedMos: convertToDecimal(res.unclaimedMos._hex)
            };
            return convertedInfo;
        } catch (err) {
            console.error(`userAuctionResult Error: `, err);
        }
    }

    async balanceOf(address) {
        try {
            let balance = await this.readContract.balanceOf(address).call();
            return convertToDecimal(balance._hex);
        } catch (err) {
            console.error(`balanceOf Error: `, err);
        }
    }

    async getGbOne() {
        try {
            return await this.readContract._GB1().call();
        } catch (err) {
            console.error(`GB1 Error: `, err);
        }
    }

    async getGbTwo() {
        try {
            return await this.readContract._GB2().call();
        } catch (err) {
            console.error(`GB2 Error: `, err);
        }
    }

    async stakeCount(address) {
        try {
            let res = await this.readContract.stakeCount(address).call();
            return convertToDecimal(res._hex);
        } catch (err) {
            console.error(`stakeCount Error: `, err);
        }
    }

    async stakeReward(stakeAmount, stakeDays) {
        try {
            let res = await this.readContract.stakeReward(stakeAmount, stakeDays).call();
            return res;
        } catch (err) {
            console.error(`stakeReward Error: `, err);
        }
    }

    async referralRecordCount(address) {
        try {
            let res = await this.readContract.referralRecordCount(address).call();
            return convertToDecimal(res._hex);
        } catch (err) {
            console.error(`referralRecordCount Error: `, err);
        }
    }

    async referralRecord(address, index) {
        try {
            let res = await this.readContract.referralRecord(address, index).call();
            return res;
        } catch (err) {
            console.error(`referralRecord Error: `, err);
        }
    }

    async stakeHistoryCount(address) {
        try {
            let res = await this.readContract.stakeHistoryCount(address).call();
            return convertToDecimal(res._hex);
        } catch (err) {
            console.error(`stakeHistoryCount Error: `, err);
        }
    }

    async stakeHistoryLists(address, stakeIndex) {
        try {
            let res = this.readContract.stakeHistoryLists(address, stakeIndex).call();
            return res;
        } catch (err) {
            console.error(`stakeHistoryLists Error: `, err);
        }
    }

    async stakeLists(address, stakeIndex) {
        try {
            let res = this.readContract.stakeLists(address, stakeIndex).call();
            return res;
        } catch (err) {
            console.error(`stakeLists Error: `, err);
        }
    }

    async stakeReward(stakeAmount, stakeDays) {
        try {
            return await this.readContract.stakeReward(stakeAmount, stakeDays).call();
        } catch (err) {
            console.error(`stakeReward Error: `, err);
        }
    }

    async stakeStart(stakeAmount, stakeDays) {
        try {
            return await this.writeContract.stakeStart(stakeAmount, stakeDays).send({
                feeLimit: 100000000
            });
        } catch (err) {
            console.error(`stakeStart Error: `, err);
        }
    }

    async stakeEnd(stakeIndex, stakeId) {
        try {
            return await this.writeContract.stakeEnd(stakeIndex, stakeId).send({
                feeLimit: 100000000
            });
        } catch (err) {
            console.error(`stakeEnd Error: `, err);
        }
    }

    async incrementDay() {
        try {
            return await this.writeContract.incrementDay().send({
                feeLimit: 100000000
            });
        } catch (err) {
            console.error(`incrementDay Error: `, err);
        }
    }

    async dailyDataUpdate(day) {
        // console.log('hello', day);
        try {
            return await this.writeContract.dailyDataUpdate(day).send({
                feeLimit: 100000000
            });
        } catch (err) {
            console.error(`dailyDataUpdate Error: `, err);
            return err.message;
        }
    }

    async xfLobbyEnter(address, amount) {
        try {
            let xfLobbyEnter = await this.writeContract.xfLobbyEnter(address, amount).send({
                feeLimit: 100000000
            });
            // alert("xfLobbyEnter: ", xfLobbyEnter)
            return xfLobbyEnter;
        } catch (err) {
            console.error(`xfLobbyEnter Error: `, err);
            // return -1;
        }
    }

    async xfLobbyExit(enterDay, count) {
        try {
            let xfLobbyExit = await this.writeContract.xfLobbyExit(enterDay, count).send({
                feeLimit: 100000000
            });
            return xfLobbyExit;
        } catch (err) {
            console.error(`xfLobbyExit Error: `, err);
            // return -1;
        }
    }

    //Testing purpose
    async setClaimStartTimestamp(timestamp) {
        try {
            let setClaimStartTimestamp = await this.writeContract.setClaimStartTimestamp(timestamp).send({
                feeLimit: 100000000
            });
            return setClaimStartTimestamp;
        } catch (err) {
            console.error(`setClaimStartTimestamp Error: `, err);
            // return -1;
        }
    }

    // claimMOSfromSRT
    async claimMOSfromSRT(amount) {
        try {
            let claimMOSfromSRT = await this.writeContract.claimMOSfromSRT(amount).send({
                feeLimit: 100000000
            });
            return claimMOSfromSRT;
        } catch (err) {
            console.error(`claimMOSfromSRT Error: `, err);
            // return -1;
        }
    }

    async claimInfo() {
        try {
            let claimInfo = await this.readContract.claimInfo().call();
            return claimInfo;
        } catch (err) {
            console.error(`claimInfo Error: `, err);
            // return -1;
        }
    }
}

export default MosPlatformService;