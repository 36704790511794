export const NAME = 'CLAIM';

export const CLAIM_INFO = `${NAME}/CLAIM_INFO`;
export const RESET_CLAIM_INFO = `${NAME}/RESET_CLAIM_INFO`;

export const getClaimInfoData = store => store[NAME].claimInfo;

export const claimInfo = data => ({
  type: CLAIM_INFO,
  data,
});

export const resetclaimInfo = () => ({
  type: RESET_CLAIM_INFO,
});
