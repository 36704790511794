import React, { useEffect, useState } from 'react';
import './FormInput.css'

const FormInput = (props) => {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        setIsActive(props.value && props.value.length > 0)
    }, [props.value])

    return (
        <div id="float-label" style={props.containerStyle}>
            <div className="input-container">
                <input
                    type={props.type}
                    readOnly={props.readOnly}
                    key={props.title}
                    value={props.value}
                    onChange={(e) => props.setValue(e.target.value)}
                />
                {props.actionLabel &&
                    <div onClick={props.onClick} className="action-label">
                        {props.actionLabel}
                    </div>
                }
                {props.actionIcon &&
                    <div onClick={props.onClick} className="action-icon">
                        <img src={props.actionIcon} style={{ width: '20px' }} />
                    </div>
                }
                {props.logo &&
                    <img src={props.logo} style={{ width: '32px', marginLeft: '12px' }} />
                }
            </div>

            <label className={isActive ? "Active" : ""}>
                {props.title}
            </label>
            {props.note &&
                <div className="note">{props.note}</div>
            }
        </div>
    )
}

export default FormInput;