import React, { Suspense } from 'react';
import {
  Switch,
  Route,
  BrowserRouter,
  Redirect,
} from "react-router-dom";
import Header from './components/Header/Header';
import { routes } from './routes/routes';
import Decimal from 'decimal.js';

Decimal.set({ precision: 30 });

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          {
            routes.map((route, index) =>
              <Route
                key={`${route}${route.path}`}
                exact
                path={route.path}
                render={() => route.render}
              />
            )
          }
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
        </Switch>
      </Suspense>
    </BrowserRouter>
  )
}

export default App;