import Actions from '../../actions';

const getDefaultState = () => ({ data: null });

function walletAddress(state, action) {
    if (typeof state === 'undefined') {
        return getDefaultState();
    }

    switch (action.type) {
        case Actions.WALLET_ADDRESS:
            return { data: action.data };
        case Actions.RESET_WALLET_ADDRESS:
            return getDefaultState();
        default:
            return state;
    }
}

export default walletAddress;
