
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LineChart, Line, XAxis, YAxis, Label, Tooltip, ResponsiveContainer } from 'recharts';
import { Decimal } from 'decimal.js';

import './LineCharts.css';

const DailyMosPayoutPerMShares = (props) => {
    const { t } = useTranslation();
    const [dailyData, setDailyData] = useState([]);

    useEffect(() => {
        if (props.dailyData) {
            let data = props.dailyData;
            setDailyData(data);
        }
    }, [props.dailyData]);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            let payout = new Decimal(payload[0].value).toFixed(2, Decimal.ROUND_DOWN);
            return (
                <div className="landing__line-chart-custom-tooltip">
                    <p>{`Day: ${label}`}</p>
                    <p>{`Payout: ${payout} MOS/M-Share`}</p>
                </div>
            );
        }

        return null;
    };

    return (
        <div className="outer-content-wrapper landing-line-chart-container">
            <span className="font-lora landing-title">{t('daily mos payout per m-shares')} <span className="landing-title-units">(10,000 Units)</span></span>

            <div className="landing-line-chart-canvas">
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        width={500}
                        height={300}
                        data={dailyData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 5,
                            bottom: 50,
                        }}
                    >
                        <XAxis
                            dataKey="day"
                            tickLine={false}
                            axisLine={false}
                            padding={{ left: 40, right: 30 }}
                            tick={({ x, y, payload }) => {
                                return (
                                    <g transform={`translate(${x},${y})`}>
                                        <text
                                            x={0} y={0}
                                            fill="#FFFFFF"
                                            dy={16}
                                            textAnchor="middle"
                                            className="font-sf-pro"
                                            style={{
                                                fontSize: '0.8rem'
                                            }}
                                        >
                                            {payload.value}
                                        </text>
                                    </g>
                                )
                            }}>
                            <Label
                                content={(props) => {
                                    return (
                                        <text
                                            x={props.viewBox.x + (props.viewBox.width / 2)}
                                            y={props.viewBox.y + props.viewBox.height + 40}
                                            style={{
                                                fontSize: '1.2rem',
                                                fontWeight: '200',
                                                fill: '#898989'
                                            }}
                                            textAnchor="middle"
                                            className="font-lora"
                                        >
                                            Day
                                        </text>
                                    )
                                }}
                            />
                        </XAxis>
                        <YAxis
                            tickLine={false}
                            axisLine={false}
                            padding={{ top: 30, bottom: 30 }}
                            domain={[dataMin => dataMin.toFixed(6), dataMax => dataMax.toFixed(6)]}
                            // allowDataOverflow={true}
                            tick={({ x, y, payload }) => {
                                return (
                                    <g transform={`translate(${x},${y})`}>
                                        <text
                                            x={0} y={0}
                                            fill="#FFFFFF"
                                            dy={16}
                                            textAnchor="middle"
                                            className="font-sf-pro"
                                            style={{
                                                fontSize: '0.8rem'
                                            }}
                                        >
                                            {payload.value}
                                        </text>
                                    </g>
                                )
                            }}
                        >
                            <Label
                                content={(props) => {
                                    return (
                                        <g transform="rotate(-90)">
                                            <text
                                                x={props.viewBox.x - (props.viewBox.height / 2)}
                                                y={props.viewBox.y + 20}
                                                style={{
                                                    fontSize: '1.2rem',
                                                    fontWeight: '200',
                                                    fill: '#898989'
                                                }}
                                                textAnchor="middle"
                                                className="font-lora"
                                            >
                                                MOS
                                            </text>
                                        </g>
                                    )
                                }}
                            />
                        </YAxis>
                        <Tooltip content={<CustomTooltip />} />
                        <Line type="monotone"
                            dataKey="payoutPerMShare"
                            stroke="#898989"
                            dot={{ stroke: '#F3B64E', fill: '#F3B64E', r: 6 }}
                            activeDot={{ stroke: '#F3B64E', fill: '#F3B64E', r: 8 }}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}

export default DailyMosPayoutPerMShares;