import { useState, useEffect } from 'react';

export function useMediaQuery(minWidth = 1000) { //default min width is 1000
    const [isMobileHeader, setIsMobileHeader] = useState(Boolean(window.innerWidth < minWidth));
    const [screenWidth, setScreenWidth] = useState(0);

    useEffect(() => {
        window.addEventListener("resize", () => setScreenWidth(window.innerWidth))

        return () => {
            window.removeEventListener('resize', () => setScreenWidth(window.innerWidth))
        }
    }, [])

    useEffect(() => {
        if (screenWidth !== 0) onResize()

    }, [screenWidth])

    function onResize() {
        if (isMobileHeader !== Boolean(screenWidth < minWidth)) setIsMobileHeader(!isMobileHeader)
    }

    return isMobileHeader;
}