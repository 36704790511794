import React from 'react';

import { useMediaQuery } from '../../customHooks/useMediaQuery';
import AuctionTableMobile from './AuctionTableMobile';
import AuctionTableWeb from './AuctionTableWeb';

const AuctionTableView = (props) => {
    let isMobile = useMediaQuery();

    return isMobile ?
        (<AuctionTableMobile data={props.data} onExitBidPress={props.onExitBidPress} currentDay={props.currentDay} />)
        :
        (<AuctionTableWeb data={props.data} onExitBidPress={props.onExitBidPress} currentDay={props.currentDay} />)
}

export default AuctionTableView;