import React, { useEffect, useRef, useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import { useTranslation } from 'react-i18next';
import { DateTime } from "luxon";

import './MosStatisticsView.css';
import statisticsBg from '../../../assets/images/home/home-statistics-bg.png';
import eventBus from '../../../utils/EventBus';
import { formatNumber } from '../../../utils/Utils';
import { MOS_GB_ONE_DATE, MOS_GB_TWO_DATE } from '../../../constant';


const leftFillNum = (num, targetLength) => {
    let val = num.toString();
    if (val.length > targetLength) return formatNumber(val);
    return val.padStart(targetLength, 0);
}

const MosStatisticsView = (props) => {
    const { t } = useTranslation();
    let mosPlatformService = useRef();
    const [currentDay, setCurrentDay] = useState(0);
    const [gbOneCountdown, setGBOneCountdown] = useState(0);
    const [gbTwoCountdown, setGBTwoCountdown] = useState(0);

    useEffect(() => {
        calculateDaysTillGB();
    }, []);

    const calculateDaysTillGB = () => {
        let now = DateTime.now();
        let gbOneStart = DateTime.fromISO(MOS_GB_ONE_DATE);
        let gbTwoStart = DateTime.fromISO(MOS_GB_TWO_DATE);

        let gbOneDaysLeft = gbOneStart.diff(now, 'days').toObject();
        let gbTwoDaysLeft = gbTwoStart.diff(now, 'days').toObject();
        setGBOneCountdown(Math.floor(gbOneDaysLeft.days));
        setGBTwoCountdown(Math.floor(gbTwoDaysLeft.days));
    }

    useAsyncEffect(() => {
        if (props.mosPlatformService) {
            setTimeout(async () => {
                // eventBus.dispatch("landingLoading", { message: true });
                mosPlatformService.current = props.mosPlatformService;
                let current = await mosPlatformService.current.getCurrentDay();
                setCurrentDay(current + 1);
                // eventBus.dispatch("landingLoading", { message: false });
            }, 3000);
        }
    }, [props.mosPlatformService]);

    return (
        <div className="outer-content-wrapper landing-mos-statistics-container">
            <div className="landing-mos-stats-info">
                <span className="font-lora landing-title">MOS {t('statistics')}</span>

                <div className="landing-mos-stats-element mos-stats-day">
                    <span className="font-lora mos-stats-title">{t('current day')}:</span>
                    <span className="font-sf-pro mos-stats-value">{currentDay ? leftFillNum(currentDay, 3) : '0'}</span>
                </div>
                <div className="landing-mos-stats-element mos-stats-gb1">
                    <span className="font-lora mos-stats-title">{t('countdown to gb1')}:</span>
                    <span className="font-sf-pro mos-stats-value">{gbOneCountdown ? leftFillNum(gbOneCountdown, 3) : '365'}</span>
                </div>
                <div className="landing-mos-stats-element">
                    <span className="font-lora mos-stats-title">{t('countdown to gb2')}:</span>
                    <span className="font-sf-pro mos-stats-value">{gbTwoCountdown ? leftFillNum(gbTwoCountdown, 3) : '730'}</span>
                </div>
            </div>
            <div className="mos-statistics-background">
                <img src={statisticsBg} />
            </div>
        </div>

    )
}

export default MosStatisticsView;