import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { PieChart } from 'react-minimal-pie-chart';
import { connect } from 'react-redux';
import useAsyncEffect from 'use-async-effect';
import { Decimal } from 'decimal.js';

import Actions from '../../../actions';
import './ChartAssetOverview.css';
import { convertToDecimal, formatNumber, shortenPriceObj } from '../../../utils/Utils';
import eventBus from '../../../utils/EventBus';

import MosTokenService from '../../../services/MosTokenService';
import MosPlatformService from '../../../services/MosPlatformService';
import { MOS_TOKEN_CONTRACT_ADDRESS } from '../../../config';
import { getStakeRewardCalculation } from '../../../api';


const SHORTEN_VALUE = 999999999;

const formatValue = (val) => {
    let shortenPrice = shortenPriceObj(val);
    if (val > SHORTEN_VALUE) {
        let value = shortenPrice.price.toFixed(0);
        let suffix = shortenPrice.suffix;
        return `${value}${suffix}`;
    } else {
        return formatNumber(Number(val).toFixed(2));
    }
}


const ChartAssetOverview = (props) => {
    const { t } = useTranslation();
    let mosDecimal = useRef();
    const [mosTokenService, setMosTokenService] = useState(null);
    const [mosPlatformService, setMosPlatformService] = useState(null);

    const [walletAddress, setWalletAddress] = useState('');
    const [mosBalance, setMosBalance] = useState(10);
    const [stakedMos, setStakedMos] = useState(10);
    const [pieChartData, setPieChartData] = useState([]);
    const [userTotalAsset, setUserTotalAsset] = useState('');
    const [cached, setCached] = useState('')
    const [convertedCached, setConvertedCached] = useState('');
    const [totalAvailable, setTotalAvailable] = useState('');
    const [convertedTotal, setConvertedTotal] = useState('');
    const [totalAssetValue, setTotalAssetValue] = useState('');
    const [convertedTav, setConvertedTav] = useState('');
    const [mosPrice, setMosPrice] = useState(0.0000);

    useAsyncEffect(async () => {
        if (props.getWalletAddressData.data) {
            setWalletAddress(props.getWalletAddressData.data);
        }
    }, [props.getWalletAddressData]);

    useEffect(() => {
        if (props.mosPrice) {
            setMosPrice(props.mosPrice);
        }
    }, [props.mosPrice])

    useAsyncEffect(() => {
        setTimeout(async () => {
            let mosTokenService = new MosTokenService();
            await mosTokenService.connectContract(MOS_TOKEN_CONTRACT_ADDRESS);
            setMosTokenService(mosTokenService);

            let mosPlatformService = new MosPlatformService();
            await mosPlatformService.connectContract();
            setMosPlatformService(mosPlatformService);
        }, 3000);
    }, []);


    useAsyncEffect(async () => {
        try {
            eventBus.dispatch("landingLoading", { message: true });

            if (mosTokenService && mosPlatformService) {
                let decimal = await mosTokenService.getDecimals();
                mosDecimal.current = decimal;

                let cached = await mosPlatformService.allocatedSupply();
                // console.log("cached: ", cached)
                let cachedSupply = new Decimal(cached._hex).dividedBy(10 ** mosDecimal.current);
                let convertedCached = cachedSupply.times(mosPrice);
                setCached(cachedSupply.toFixed(2));
                setConvertedCached(convertedCached.toFixed(2));

                let total = await mosTokenService.totalSupply();
                // console.log("total: ", total)
                let totalSupply = new Decimal(total._hex).dividedBy(10 ** mosDecimal.current);
                let convertedTotal = totalSupply.times(mosPrice);
                setTotalAvailable(totalSupply.toFixed(2));
                setConvertedTotal(convertedTotal.toFixed(2));

                let totalAssetValue = cachedSupply.add(totalSupply);
                let convertedTAV = convertedCached.add(convertedTotal);
                setTotalAssetValue(totalAssetValue.toFixed(2));
                setConvertedTav(convertedTAV.toFixed(2));
            }

            if (walletAddress && mosTokenService && mosPlatformService) {
                let mos = await mosTokenService.getBalance(walletAddress);
                let mosBalance = mos / (10 ** mosDecimal.current);
                setMosBalance(mosBalance.toFixed(0));

                let stakeCount = await mosPlatformService.stakeCount(walletAddress);
                getStakeList(stakeCount);
            }

            eventBus.dispatch("landingLoading", { message: false });
        } catch (err) {
            eventBus.dispatch("landingLoading", { message: false });
        }
    }, [walletAddress, mosTokenService, mosPlatformService, mosPrice]);

    const getStakeList = async (count) => {
        // eventBus.dispatch("landingLoading", { message: true });

        let stakedMos = new Decimal(0);
        for (let i = 0; i < count; i++) {
            let res = await mosPlatformService.stakeLists(walletAddress, i);
            // let param = {
            //     amount: res.stakedHearts.toString(),
            //     day: res.stakedDays
            // }
            // let interest = new Decimal(0);
            // let stakeReward = await getStakeRewardCalculation(param);
            // if (stakeReward) {
            //     let reward = stakeReward[0];
            //     interest = new Decimal(reward.Interest);
            // }
            // let convertedHearts = new Decimal(res.stakedHearts._hex).dividedBy(10 ** mosDecimal.current);
            // let currentValueMos = interest.plus(convertedHearts);

            // stakedMos = stakedMos.plus(currentValueMos);
            let convertedHearts = new Decimal(res.stakedHearts._hex).dividedBy(10 ** mosDecimal.current);
            stakedMos = stakedMos.plus(convertedHearts);

        }
        // console.log("staked mos: ", stakedMos.toString());
        setStakedMos(stakedMos.toString());
        // eventBus.dispatch("landingLoading", { message: false });
    }

    useEffect(() => {
        let data = [
            { title: 'Staked MOS', value: Number(stakedMos), color: 'white', key: 'landing-staked-MOS' },
            { title: 'MOS', value: Number(mosBalance), color: '#F3B64E', key: 'landing-MOS' },
        ];
        setPieChartData(data);

        let totalMos = new Decimal(mosBalance).plus(stakedMos);
        let total = totalMos.times(mosPrice).toFixed(2, Decimal.ROUND_DOWN);
        // console.log(`stkaed: ${stakedMos.toString()}, balance: ${mosBalance.toString()}, total: ${new Decimal(mosBalance).plus(stakedMos).times(mosPrice).toFixed(2, Decimal.ROUND_DOWN).toString()}`)
        setUserTotalAsset(formatValue(total));
    }, [stakedMos, mosBalance]);

    const ValueCircleView = (props) => {
        return (
            <PieChart
                data={pieChartData}
                lineWidth={4}
                paddingAngle={12}
                rounded
                animate
                label={({ x, y, dx, dy }) => {
                    return (
                        <>
                            <text
                                x={x}
                                y={34}
                                dx={dx}
                                dy={dy}
                                dominantBaseline="central"
                                textAnchor="middle"
                                style={{
                                    fontSize: '8px',
                                    fontWeight: '200',
                                    fill: '#898989'
                                }}
                            >
                                {`Your asset`}
                            </text>
                            <text
                                x={x}
                                y={56}
                                dx={dx}
                                dy={dy}
                                dominantBaseline="central"
                                textAnchor="middle"
                                style={{
                                    fontSize: '12px',
                                    fill: 'white',
                                }}
                            >
                                $ {userTotalAsset}
                            </text>
                        </>
                    );
                }}
                labelPosition={0}
            />
        )
    }

    return (
        <div className="landing-pie-overview-container">
            <div className="font-sf-pro outer-content-wrapper landing-pie-container" >
                <div className="font-lora landing-mos-price">MOS Current Price:</div>
                <div className="font-sf-pro landing-mos-price-value">$ {mosPrice}</div>
                <div className="landing-pie-canvas">
                    <ValueCircleView />
                </div>
                <div className="landing-pie-legend-container">
                    <div className="landing-pie-legend-element">
                        <div className="landing-pie-legend-circle" style={{ backgroundColor: 'white' }} /><span className="landing-pie-legend-value">Staked MOS</span>
                    </div>
                    {/* <div className="landing-pie-legend-element">
                        <div className="landing-pie-legend-circle" style={{ backgroundColor: '#FFE98D' }} /><span>USDT</span>
                    </div> */}
                    <div className="landing-pie-legend-element">
                        <div className="landing-pie-legend-circle" style={{ backgroundColor: '#F3B64E' }} /><span className="landing-pie-legend-value">Available MOS</span>
                    </div>
                </div>
            </div>

            <div className="outer-content-wrapper landing-asset-overview-container">
                <span className="font-lora landing-title">{t('global asset overview')}</span>
                <div className="asset-overview-info">
                    <div className="content-wrapper landing-asset-overview-element">
                        <span className="font-lora asset-overview-element-title">{t('total asset')}</span>
                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                            <span className="font-sf-pro asset-overview-element-value">{formatNumber(totalAssetValue)}</span>
                            <span className="font-sf-pro asset-overview-element-value-usd">≈ $ {formatNumber(convertedTav)}</span>
                        </div>
                    </div>
                    <div className="content-wrapper landing-asset-overview-element">
                        <span className="font-lora asset-overview-element-title">{t('staked asset')}</span>
                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                            <span className="font-sf-pro asset-overview-element-value">{formatNumber(cached)}</span>
                            <span className="font-sf-pro asset-overview-element-value-usd">≈ $ {formatNumber(convertedCached)}</span>
                        </div>
                    </div>
                    <div className="content-wrapper landing-asset-overview-element">
                        <span className="font-lora asset-overview-element-title">{t('available')} MOS</span>
                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                            <span className="font-sf-pro asset-overview-element-value">{formatNumber(totalAvailable)}</span>
                            <span className="font-sf-pro asset-overview-element-value-usd">≈ $ {formatNumber(convertedTotal)}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

const mapStateToProps = store => ({
    getWalletAddressData: Actions.getWalletAddressData(store),
});

const mapDispatchToProps = {
};


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ChartAssetOverview);

