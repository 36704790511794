import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Nav } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { HiDotsHorizontal } from "react-icons/hi";
import { RiCloseLine } from "react-icons/ri";
import './MobileHeader.css';

import mosLogo from '../../assets/images/mos-logo-word-white.svg';
import TronModal from '../../services/TronModal';

const MobileHeaderContainer = styled.div`
    width: 100%;
    position: sticky;
    top:0;
    background: #222222;
    z-index: 3;


    .shaded-bg{
    background-color: #282828;
    opacity: 0.8;
    position: fixed;
    top: 0px;
    right: 0px;
    width: ${props => props.activeBar ? '100%' : '0%'};

    height: 100%;
    transition: 0.5s;
    z-index: 2;
    }

`

const MobileHeaderBody = styled.div.attrs((props) => ({ tabIndex: 0 }))`
    width: 90%;
    margin: auto;
    padding: 16px 0px;
    border-bottom: thin solid rgba(255, 255, 255, 0.15);
    color: #FFFFFF;
    display: flex;
    align-items: center;

    &:hover {
        cursor: pointer;
    }

    .mobile-header-logo {
        width: 100px;
    }
`

const TripleDotButton = styled.div`
    height: 24px;
    width: 24px;
    color: #F3B64E;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #F3B64E;
    margin-left: auto;

    &:hover {
        cursor: pointer;
    }
`

const MobileSidebarBody = styled.div`
    height: 100%;
    position: fixed;
    top: 0px;
    right: 0px;
    transition: 0.5s;
    z-index: 2;
    background: #222222;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: ${props => props.activeBar ? '65%' : '0%'};

    a {
        padding: 12px 32px;
    }

    a.active {
        color: #F3B64E;
    }
`


const MobileHeader = (props) => {
    const { i18n, t } = useTranslation();
    const [activeBar, setActiveBar] = useState(false);
    const [openLang, setOpenLang] = useState(false);

    useEffect(() => {
        if (!activeBar) setOpenLang(false);
    }, [activeBar])

    return (
        <MobileHeaderContainer activeBar={activeBar}>
            <div id="shadedBg" className="shaded-bg" onClick={_ => setActiveBar(!activeBar)}></div>
            <MobileHeaderBody>
                <img src={mosLogo} className="mobile-header-logo" />
                <TripleDotButton onClick={() => setActiveBar(!activeBar)}>
                    <HiDotsHorizontal />
                </TripleDotButton>
            </MobileHeaderBody>
            <MobileSidebarBody activeBar={activeBar}>
                <RiCloseLine style={{ marginLeft: 'auto', marginRight: '10px', color: '#F3B64E' }} size="32" onClick={() => setActiveBar(!activeBar)} />
                <Nav className="mobile-header__nav-body">
                    <NavLink className="mobile-header-link" to="/" exact onClick={() => setActiveBar(false)}>Home</NavLink>
                    <NavLink className="mobile-header-link" to="/stake" onClick={() => setActiveBar(false)}>Stake</NavLink>
                    <NavLink className="mobile-header-link" to="/claim" onClick={() => setActiveBar(false)}>Claim</NavLink>
                    <NavLink className="mobile-header-link" to="/auction" onClick={() => setActiveBar(false)}>Auction</NavLink>
                    <NavLink className="mobile-header-link" to="/refer" onClick={() => setActiveBar(false)}>Refer</NavLink>
                    <a className="mobile-header-link" onClick={() => setOpenLang(!openLang)}>Language</a>
                    <div style={{ display: openLang ? 'flex' : 'none' }} className="mobile-header-language" >
                        <a onClick={() => { i18n.changeLanguage('en'); setActiveBar(false) }}>English</a>
                        <a onClick={() => { setActiveBar(false) }}>简体中文</a>
                        {/* <a onClick={() => { i18n.changeLanguage('zh'); setActiveBar(false) }}>简体中文</a> */}
                    </div>
                    <a onClick={() => { setActiveBar(false) }}><TronModal /></a>
                </Nav>

            </MobileSidebarBody>
        </MobileHeaderContainer>
    )
}

export default MobileHeader;