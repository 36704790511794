import React, { useRef, useState } from 'react';
// import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { Decimal } from 'decimal.js';
import useAsyncEffect from 'use-async-effect';
import { useTranslation } from 'react-i18next';

import './Auction.css';
import Actions from '../../actions';
import { checkTransaction } from '../../services/TronServices';
import TokenService from '../../services/TokenService';
import { USDT_TOKEN_ADDRESS, MOS_TOKEN_CONTRACT_ADDRESS, MOS_PLATFORM_CONTRACT_ADDRESS } from '../../config';
import { ZERO_ADDRESS } from '../../constant';

import AppContainer from '../../components/AppContainer/AppContainer';
import AuctionPoolView from './AuctionPoolView';
import AuctionBidView from './AuctionBidView';
import AuctionTableView from './AuctionTableView';
import LoadingModal from '../../components/Loading/LoadingModal';

import MosPlatformService from '../../services/MosPlatformService';
import MosTokenService from '../../services/MosTokenService';

const MINIMUM_BID_AMOUNT = 150;

const Auction = (props) => {
    const { t } = useTranslation();
    // const { refCode } = useParams();
    const [bidValue, setBidValue] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [usdtBalance, setUsdtBalance] = useState(0);
    const [auctionRate, setAuctionRate] = useState(0);
    const [auctionTableData, setAuctionTableData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [mosTService, setMosTService] = useState(null);
    const [mosPService, setMosPService] = useState(null);
    const [usdtTService, setUsdtTService] = useState(null);

    let mosPlatformService = useRef(null);
    let mosTokenService = useRef(null);
    let usdtTokenService = useRef(null);
    let usdtTokenDecimal = useRef(null);
    let mosTokenDecimal = useRef(null);
    let currentDay = useRef();

    useAsyncEffect(async () => {
        if (props.getWalletAddressData.data && walletAddress != props.getWalletAddressData.data) {
            setLoading(true);
            setWalletAddress(props.getWalletAddressData.data);
            // await initServices(props.getWalletAddressData.data);
            // getAuctionTableData(props.getWalletAddressData.data);
        }
    }, [props.getWalletAddressData]);

    useAsyncEffect(async () => {
        await initServices();
    }, []);

    useAsyncEffect(async () => {
        if (walletAddress.length > 0 && usdtTService) {
            await getUserBalance(walletAddress);
            getAuctionTableData(walletAddress);
        }
    }, [walletAddress, usdtTService]);

    const initServices = async () => {
        setTimeout(async () => {
            let auctService = new MosPlatformService();
            await auctService.connectContract();
            mosPlatformService.current = auctService;
            // setMosPService(auctService);
            currentDay.current = await auctService.getCurrentDay();

            let mosTokenService = new MosTokenService();
            await mosTokenService.connectContract();
            mosTokenService.current = mosTokenService;
            // setMosTService(mosTokenService);
            mosTokenDecimal.current = await mosTokenService.getDecimals();

            let usdtService = new TokenService();
            await usdtService.connectContract(USDT_TOKEN_ADDRESS);
            setUsdtTService(usdtService);
            usdtTokenService.current = usdtService;
            usdtTokenDecimal.current = await usdtService.getDecimals();

        }, 3000);

    }

    const getUserBalance = async (address) => {
        let balance = await usdtTService.getBalance(address);
        let usdtBal = new Decimal(balance).toFixed(6, Decimal.ROUND_DOWN);
        setUsdtBalance(usdtBal);
    }

    const getAuctionTableData = async (address) => {
        let currentArray = [];
        for (let i = 0; i <= currentDay.current; i++) {
            let mosPool = await mosPlatformService.current.poolValue(i);
            let usdtBid = await mosPlatformService.current.xfLobby(i);
            let userInfo = await mosPlatformService.current.userAuctionResult(i, address);
            let data = {
                day: i,
                mosPool: mosPool / (10 ** mosTokenDecimal.current),
                usdtBid: usdtBid / (10 ** usdtTokenDecimal.current),
                userInfo: {
                    bidAmount: (userInfo.bidAmount / (10 ** usdtTokenDecimal.current)),
                    claimedMos: (userInfo.claimedMos / (10 ** mosTokenDecimal.current)),
                    unclaimedMos: (userInfo.unclaimedMos / (10 ** mosTokenDecimal.current)),
                },
            };
            currentArray.push(data);
        }
        if (currentDay.current !== 0) {
            let yesterday = currentArray[0];
            let auctRate = 0.000000;
            if (currentDay.current > 0) yesterday = currentArray[currentArray.length - 2];
            if (yesterday.usdtBid > 0) auctRate = yesterday.mosPool / yesterday.usdtBid;
            setAuctionRate(auctRate);
        }

        currentArray.reverse();
        setAuctionTableData(currentArray);
        setLoading(false);
    }

    const getNewUserBidInfo = async (day) => {
        let mosPool = await mosPlatformService.current.poolValue(day);
        let usdtBid = await mosPlatformService.current.xfLobby(day);
        let userInfo = await mosPlatformService.current.userAuctionResult(day, walletAddress);
        let currentTableArr = [...auctionTableData];
        let updateIndex = currentTableArr.findIndex(data => data.day === day);
        let updatedData = {
            day: day,
            mosPool: mosPool / (10 ** mosTokenDecimal.current),
            usdtBid: usdtBid / (10 ** usdtTokenDecimal.current),
            userInfo: {
                bidAmount: (userInfo.bidAmount / (10 ** usdtTokenDecimal.current)),
                claimedMos: (userInfo.claimedMos / (10 ** mosTokenDecimal.current)),
                unclaimedMos: (userInfo.unclaimedMos / (10 ** mosTokenDecimal.current)),
            },
        };
        currentTableArr.splice(updateIndex, 1, updatedData);
        setAuctionTableData(currentTableArr);
        setLoading(false);
    }

    const triggerNextDay = async () => {
        if (walletAddress) {
            setLoading(true);
            await mosPlatformService.current.incrementDay();
            setTimeout(async () => {
                currentDay.current = await mosPlatformService.current.getCurrentDay();
                await mosPlatformService.current.dailyDataUpdate(currentDay.current);
                getAuctionTableData(walletAddress);
            }, 3000);

        }
    }

    const isEnterBidValid = async (refAddress) => {
        let isValid = true;
        if (bidValue.length === 0 || isNaN(bidValue) || Number(bidValue) <= 0) {
            alert("Please enter a valid amount");
            isValid = false;
        } else if (walletAddress.length === 0) {
            alert("Please connect your wallet first");
        } else {
            let usdtBalance = await usdtTokenService.current.getBalance(walletAddress);
            let bidInput = new Decimal(bidValue);

            if (bidInput > usdtBalance) {
                alert("Insufficient Balance");
                isValid = false;
            } else if (bidInput.lt(MINIMUM_BID_AMOUNT)) {
                alert("Minimum bid amount is 150 USDT");
                isValid = false;
            } else if (walletAddress.length === 0) {
                alert("Please connect your wallet first");
                isValid = false;
            } else if (bidInput.dp() > usdtTokenDecimal.current) {
                alert("Bid value cannot exceed 18 decimal places");
                isValid = false;
            } else if (!window.tronWeb.isAddress(refAddress)) {
                alert("Invalid referral address");
                isValid = false;
            }
        }
        return isValid;
    }

    const checkAllowance = async () => {
        let allowance = await usdtTokenService.current.getAllowance(walletAddress, MOS_PLATFORM_CONTRACT_ADDRESS);
        if (allowance === 0) await usdtTokenService.current.approveAllowance(MOS_PLATFORM_CONTRACT_ADDRESS);
    }

    const onEnterBidPress = async (refInput) => {
        try {
            setLoading(true);
            let refAddress = refInput.length > 0 ? refInput.trim() : ZERO_ADDRESS;
            // let refAddress = refCode ?? ZERO_ADDRESS;
            if (await isEnterBidValid(refAddress)) {
                await checkAllowance();
                let bidInput = new Decimal(bidValue);
                let bidAmount = bidInput.times(10 ** usdtTokenDecimal.current).toString();
                let tx;
                tx = await mosPlatformService.current.xfLobbyEnter(refAddress, bidAmount);
                if (tx) {
                    setTimeout(async () => {
                        if (await checkTransaction(tx)) {
                            alert('Transaction confirmed');
                            setBidValue("");
                            setTimeout(() => {
                                getNewUserBidInfo(currentDay.current);
                            }, 4000);
                        }
                        else {
                            alert('Transaction failed');
                        }
                        let balance = await usdtTokenService.current.getBalance(walletAddress);
                        let usdtBal = new Decimal(balance).toFixed(6, Decimal.ROUND_DOWN);
                        setUsdtBalance(usdtBal);
                        setLoading(false);
                    }, 5000);
                } else {
                    alert('Transaction failed');
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
        }
        setLoading(false);
    }

    const onExitBidPress = async (day) => {
        setLoading(true);
        if (walletAddress.length !== 0) {
            // alert(`Exit all bid for day ${day + 1}`);
            let tx;
            // currently hard code at day = previous day, count = 0 (all bids in previous day)
            tx = await mosPlatformService.current.xfLobbyExit(day, 0);

            if (tx) {
                setTimeout(async () => {
                    if (await checkTransaction(tx)) {
                        alert('Transaction confirmed');
                        setTimeout(() => {
                            getNewUserBidInfo(day);
                        }, 4000);
                    }
                    else {
                        alert('Transaction failed');
                    }
                }, 5000);
            }
        } else {
            alert("Please connect your wallet first");
        }
        setLoading(false);
    }

    // console.log("auction service: ", mosPlatformService);
    // console.log("auction table: ", auctionTableData);

    return (
        <AppContainer>
            <LoadingModal show={loading} text={`${t('Loading')}...`} />
            <AuctionPoolView auctionRate={auctionRate} />
            <AuctionBidView value={bidValue} setValue={setBidValue} onEnterBid={onEnterBidPress} auctionRate={auctionRate} triggerNextDay={triggerNextDay} usdtBalance={usdtBalance} />
            <AuctionTableView onExitBidPress={onExitBidPress} data={auctionTableData} currentDay={currentDay} />
        </AppContainer>
    );
}

const mapStateToProps = store => ({
    getWalletAddressData: Actions.getWalletAddressData(store),
});

const mapDispatchToProps = {
};


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Auction);