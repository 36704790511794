import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAsyncEffect from 'use-async-effect';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import Actions from '../../actions';
import MosPlatformService from '../../services/MosPlatformService';
import MosTokenService from '../../services/MosTokenService';
import TokenService from '../../services/TokenService';
import './ClaimInput.css'

import { useMediaQuery } from '../../customHooks/useMediaQuery';
import { SRT_TOKEN_ADDRESS, MOS_PLATFORM_CONTRACT_ADDRESS } from '../../config';

import Button from '../../components/Button/Button';
import FormInput from '../../components/Input/FormInput';

import srtCoin from '../../assets/images/srt_logo.png';
import mosCoinLogo from '../../assets/images/mos-coin-gold.svg';
import mosCoin from '../../assets/images/mos-coin.svg';
import swipeUpDown from '../../assets/images/claim/swipe-up-down.svg';
import { numberFormatOwnDecimal } from '../../utils/Utils';
import { checkTransaction } from '../../services/TronServices';
import LoadingModal from '../../components/Loading/LoadingModal';


const ClaimInput = (props) => {
    const { t } = useTranslation();

    let isMobile = useMediaQuery();
    let mosTokenService = useRef(null);
    let mosPlatformService = useRef(null);
    let srtTokenService = useRef(null);

    // const [mosTokenService, setMosTokenService] = useState(null);
    // const [mosPlatformService, setMosPlatformService] = useState(null);

    const [walletAddress, setWalletAddress] = useState('');
    const [availableBalance, setAvailableBalance] = useState(0);
    const [inputBalance, setInputBalance] = useState();
    const [inputDecimal, setInputDecimal] = useState(0);
    const [totalMOS, setTotalMOS] = useState();
    const [MOSClaimed, setMOSClaimed] = useState();
    const [latePenalty, setLatePenalty] = useState();

    const [claimDaysCount, setClaimDaysCount] = useState();
    const [claimPeriod, setClaimPeriod] = useState();
    const [hasClaimStart, setHasClaimStart] = useState(false);
    const [maxPenaltyPercentage, setMaxPenaltyPercentage] = useState();

    const [loading, setLoading] = useState(false);
    const [timestamp, setTimestamp] = useState(0);
    const [srtService, setSrtService] = useState(null);

    useAsyncEffect(async () => {
        setLoading(true);
        await initServices();
        setLoading(false);
    }, []);

    useAsyncEffect(async () => {
        if (props.getWalletAddressData.data) {
            setWalletAddress(props.getWalletAddressData.data);
        }
    }, [props.getWalletAddressData]);

    useAsyncEffect(async () => {
        if (walletAddress && srtService) {
            setLoading(true);
            // if (!mosTokenService || !mosPlatformService || !srtTokenService) await initServices();
            // await getUserBalance();

            // note mutable flag
            let isMounted = true;
            getUserBalance().then((data) => {
                // add conditional check
                if (isMounted) {
                    setAvailableBalance(data.availableBal);
                    setInputDecimal(data.inputDec);
                }
            })

            setLoading(false);
            // cleanup toggles value, if unmounted
            return () => { isMounted = false };
        }
    }, [walletAddress, srtService]);

    const initServices = async () => {
        setTimeout(async () => {
            let auctionService = new MosPlatformService();
            await auctionService.connectContract();
            mosPlatformService.current = auctionService;
            // console.log(mosPlatformService.current);

            let mosToken = new MosTokenService();
            await mosToken.connectContract();
            mosTokenService.current = mosToken;
            // console.log(mosTokenService.current);

            let tokenService = new TokenService();
            await tokenService.connectContract(SRT_TOKEN_ADDRESS);
            srtTokenService.current = tokenService;
            setSrtService(tokenService);
            // console.log(srtTokenService.current);

            await getClaimInfo();
        }, 3000)
    }

    const getClaimInfo = async () => {
        if (mosPlatformService.current) {
            let claimInfo = await mosPlatformService.current.claimInfo();
            setClaimDaysCount(claimInfo.claimDaysCount.toString())
            setClaimPeriod(claimInfo.claimPeriod.toString())
            setHasClaimStart(claimInfo.hasClaimStart)
            setMaxPenaltyPercentage(claimInfo.maxPenaltyPercentage.toString())
            // console.log("ClaimInfo: ", {
            //     total: claimInfo.total.toString(),
            //     totalClaim: claimInfo.totalClaim.toString(),
            //     claimDaysCount: claimInfo.claimDaysCount.toString(),
            //     claimPeriod: claimInfo.claimPeriod.toString(),
            //     maxPenaltyPercentage: claimInfo.maxPenaltyPercentage.toString(),
            //     hasClaimStart: claimInfo.hasClaimStart,
            // });
            props.onClaimInfo(claimInfo);
        }
    }

    const getUserBalance = async () => {
        if (srtTokenService.current) {
            let balance = await srtTokenService.current.getBalance(walletAddress);
            let decimal = await srtTokenService.current.getDecimals();
            // setAvailableBalance(balance);
            // setInputDecimal(decimal);
            return { availableBal: balance, inputDec: decimal }
        }
    }

    useEffect(() => {
        if (!hasClaimStart) return;
        let input = Number(inputBalance);
        if (input > 0) {
            setTotalMOS(numberFormatOwnDecimal(input));
            let penalty = 0;
            if (Number(claimDaysCount) > Number(claimPeriod)) {
                penalty = Math.ceil((Number(claimDaysCount) - Number(claimPeriod)) / 7) * 2;
                if (penalty > Number(maxPenaltyPercentage)) penalty = Number(maxPenaltyPercentage);
            }
            setMOSClaimed(numberFormatOwnDecimal(input * (100 - penalty) / 100));
            setLatePenalty(numberFormatOwnDecimal(input * penalty / 100));
        } else {
            setTotalMOS();
            setMOSClaimed();
            setLatePenalty();
        }
    }, [inputBalance])

    const MobileView = (
        <>
            <ReactTooltip id='cl__late-claim-penalty-mobile' aria-haspopup='true' overridePosition={({ left, top },
                currentEvent, currentTarget, node) => {
                const d = document.documentElement;
                left = Math.min(d.clientWidth - node.clientWidth, left);
                top = Math.min(d.clientHeight - node.clientHeight, top);
                left = Math.max(0, left);
                top = Math.max(0, top);
                return { top, left }
            }}>
                <p>LateClaimPenalty condition:</p>
                <ol>
                    <li>Unclaimed SRT amount will deteriorate by 2% every week as penalty</li>
                    <li>Maximum penalty at 50% of the original SRT amount owned</li>
                </ol>
            </ReactTooltip>

            <FormInput
                title={t('SRT')}
                actionLabel={t('Max')}
                type="number"
                readOnly={!walletAddress || /^\s*$/.test(walletAddress)}
                onClick={() => setInputBalance(availableBalance > 0 ? availableBalance : null)}
                note={`${t('Balance')}: ${numberFormatOwnDecimal(availableBalance.toString())}`}
                value={`${inputBalance ?? ''}`}
                setValue={setInputBalance}
                logo={srtCoin}
            />
            <div className="cl__center">
                <img src={swipeUpDown} className="cl__swipe-icon" />
            </div>
            <FormInput
                title={t('Total MOS')}
                // actionIcon={mosCoinLogo}
                value={`${totalMOS ?? ''}`}
                logo={mosCoin}
                readOnly
            />
            {/* <div className="cl__center">
                <img src={swipeUpDown} className="cl__swipe-icon" />
            </div> */}
            <FormInput
                title={t('MOS Claimed')}
                // actionIcon={mosCoinLogo}
                value={`${MOSClaimed ?? ''}`}
                readOnly
                logo={mosCoin}
                containerStyle={{ margin: '8vw 0px 0vw 0px' }}
            />
            <FormInput
                title={t('Late Claim Penalty')}
                // actionIcon={mosCoinLogo}
                value={`${latePenalty ?? ''}`}
                logo={mosCoin}
                readOnly
                containerStyle={{ margin: '8vw 0px 0vw 0px' }}
            />
            <div className="cl__tooltip-question-mark"><a data-tip data-for='cl__late-claim-penalty-mobile'>Show Calculation</a></div>

        </>
    )

    const WebView = (
        <>
            <ReactTooltip id='cl__late-claim-penalty' aria-haspopup='true' overridePosition={({ left, top },
                currentEvent, currentTarget, node) => {
                const d = document.documentElement;
                left = Math.min(d.clientWidth - node.clientWidth, left);
                top = Math.min(d.clientHeight - node.clientHeight, top);
                left = Math.max(0, left);
                top = Math.max(0, top);
                return { top, left }
            }}>
                <p>LateClaimPenalty condition:</p>
                <ol>
                    <li>Unclaimed SRT amount will deteriorate by 2% every week as penalty</li>
                    <li>Maximum penalty at 50% of the original SRT amount owned</li>
                </ol>
            </ReactTooltip>

            <div className="cl__top-container">
                <FormInput
                    title={t('SRT')}
                    actionLabel={t('Max')}
                    type="number"
                    readOnly={!walletAddress || /^\s*$/.test(walletAddress)}
                    onClick={() => setInputBalance(availableBalance > 0 ? availableBalance : null)}
                    note={`${t('Balance')}: ${numberFormatOwnDecimal(availableBalance.toString())}`}
                    value={`${inputBalance ?? ''}`}
                    setValue={setInputBalance}
                    containerStyle={{ width: '100%' }}
                    logo={srtCoin}
                />
                <div className="cl__center">
                    <img src={swipeUpDown} className="cl__swipe-icon cl__rotate-icon" />
                </div>
                <FormInput
                    title={t('Total MOS')}
                    // actionIcon={mosCoinLogo}
                    value={`${totalMOS ?? ''}`}
                    logo={mosCoin}
                    readOnly
                    containerStyle={{ width: '100%' }}
                />
            </div>
            <div className="cl__right">
                <img src={swipeUpDown} className="cl__swipe-icon" />
            </div>
            <div className="cl__bottom-container">
                <FormInput
                    title={t('MOS Claimed')}
                    // actionIcon={mosCoinLogo}
                    value={`${MOSClaimed ?? ''}`}
                    readOnly
                    containerStyle={{ width: '100%' }}
                    logo={mosCoin}
                />
                <div className="cl__center" style={{ alignItems: 'flex-end' }}>
                    <div className="cl__swipe-icon" style={{ margin: '-2.5vw 3vw 0px 3vw' }} />
                    <div className="cl__tooltip-question-mark"><a data-tip data-for='cl__late-claim-penalty'>?</a></div>
                </div>
                <FormInput
                    title={t('Late Claim Penalty')}
                    // actionIcon={mosCoinLogo}
                    value={`${latePenalty ?? ''}`}
                    logo={mosCoin}
                    readOnly
                    containerStyle={{ width: '100%' }}
                />
            </div>
        </>
    )

    const handleSubmit = async (e) => {
        e.preventDefault();
        let inputAmount = Number(inputBalance) * 10 ** inputDecimal;
        if (inputBalance > availableBalance) {
            alert(t('Insufficient Fund'));
            return;
        }
        setLoading(true)
        let amount = inputAmount.toLocaleString('fullwide', { useGrouping: false })
        await checkAllowance();
        // console.log("Claim Balance: ", amount)
        let tx = await mosPlatformService.current.claimMOSfromSRT(amount);
        if (tx) {
            setTimeout(async () => {
                if (await checkTransaction(tx)) {
                    setInputBalance();
                    let balance = await srtTokenService.current.getBalance(walletAddress);
                    setAvailableBalance(balance);
                    getClaimInfo();
                    alert(t('Claimed successful'));
                }
                else {
                    alert(t('Claimed failed. Please check your account bandwidth and TRX balance.'));
                }
                setLoading(false)
            }, 4000);
        } else {
            setLoading(false)
            alert(t('Claimed failed. Please check your account bandwidth and TRX balance.'));
        }


        // let balance = await srtTokenService.current.getBalance(walletAddress);
        // console.log("SRT Balance: ", balance);
    };

    const checkAllowance = async (amount) => {
        let allowance = await srtTokenService.current.getAllowanceWithoutConversion(walletAddress, MOS_PLATFORM_CONTRACT_ADDRESS);
        // console.log("Allowance: ", Number(allowance.toString()));
        if (Number(allowance.toString()) === 0) {
            let tx = await srtTokenService.current.approveAllowance(MOS_PLATFORM_CONTRACT_ADDRESS);
            setTimestamp(async () => {
                await checkTransaction(tx)
            }, 3000)
        }
    }

    return (
        <div className="outer-content-wrapper cl__container">
            <ReactTooltip id='tdvv__title-tooltip' aria-haspopup='true' overridePosition={({ left, top },
                currentEvent, currentTarget, node) => {
                const d = document.documentElement;
                left = Math.min(d.clientWidth - node.clientWidth, left);
                top = Math.min(d.clientHeight - node.clientHeight, top);
                left = Math.max(0, left);
                top = Math.max(0, top);
                return { top, left }
            }}>
                <p>Grace Period: Day 1 - 7 (25/08 – 01/09)</p>
                <p>Claims from Day 8 onwards will trigger LateClaimPenalty</p>
            </ReactTooltip>

            <div style={{ display: 'flex' }}>
                <div className="tdvv__title font-lora">{t('Claim')} </div>
                <div className="cl__tooltip-question-mark cl__title-qm"><a data-tip data-for='tdvv__title-tooltip'>?</a></div>
            </div>

            {isMobile ? MobileView : WebView}

            <Button
                title={t('Claim Your MOS')}
                onClick={hasClaimStart ? handleSubmit : () => { }}
                containerStyle={{ backgroundColor: hasClaimStart ? '#F4B956' : 'rgba(255,255,255,0.4)' }}
            />

            {/* {!(!walletAddress || /^\s*$/.test(walletAddress)) &&
                <>
                    <FormInput
                        title={t('Timestamp')}
                        type="number"
                        value={`${timestamp ?? ''}`}
                        setValue={setTimestamp}
                    />
                    <Button
                        title={t('Set Timestamp')}
                        onClick={async () => {
                            if (timestamp > 0) {
                                let result = await mosPlatformService.current.setClaimStartTimestamp(timestamp);
                                if (result) {
                                    await getClaimInfo();
                                    alert("Updated Timestamp")
                                }
                                // console.log("Result: ", result);
                            }
                        }}
                        containerStyle={{ backgroundColor: '#176EBF' }}
                    />
                    <LoadingModal
                        show={loading}
                        text={`${t('Loading')}...`}
                    />
                </>
            } */}
        </div>
    )
}


const mapStateToProps = store => ({
    getWalletAddressData: Actions.getWalletAddressData(store),
    getClaimInfoData: Actions.getClaimInfoData(store),
});

const mapDispatchToProps = {
    onClaimInfo: Actions.claimInfo,
};


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ClaimInput);