export const NAME = 'WALLET';

export const WALLET_ADDRESS = `${NAME}/WALLET_ADDRESS`;
export const RESET_WALLET_ADDRESS = `${NAME}/RESET_WALLET_ADDRESS`;

export const getWalletAddressData = store => store[NAME].walletAddress;

export const walletAddress = data => ({
  type: WALLET_ADDRESS,
  data,
});

export const resetWalletAddress = () => ({
  type: RESET_WALLET_ADDRESS,
});
