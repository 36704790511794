import React from 'react';
import { useMediaQuery } from '../../../customHooks/useMediaQuery';

import LandingTableMobile from './LandingTableMobile';
import LandingTableWeb from './LandingTableWeb';

const LandingTableView = (props) => {
    let isMobile = useMediaQuery();

    return isMobile ?
        (<LandingTableMobile dailyData={props.dailyData} />)
        :
        (<LandingTableWeb dailyData={props.dailyData} />)
}

export default LandingTableView;