import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import useAsyncEffect from 'use-async-effect';
import { connect } from 'react-redux';
import { DateTime } from "luxon";
import { Decimal } from 'decimal.js';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import ReactTooltip from 'react-tooltip';

import './AddNewStakeView.css'
import Actions from '../../actions';
import { useMediaQuery } from '../../customHooks/useMediaQuery';
import { formatNumber } from '../../utils/Utils';
import eventBus from '../../utils/EventBus';
import { checkTransaction } from '../../services/TronServices';
import FormInput from '../../components/Input/FormInput';
import Button from '../../components/Button/Button';
import { MOS_GB_ONE_DATE, MOS_GB_TWO_DATE } from '../../constant';
import MosPlatformService from '../../services/MosPlatformService';
import MosTokenService from '../../services/MosTokenService';
import { MOS_PLATFORM_CONTRACT_ADDRESS } from '../../config';
import { getStakeRewardCalculation, getGiftingBonus } from '../../api/index';

const MINIMUM_STAKE_DAYS = 10;
const MINIMUM_STAKE_AMOUNT = 10;
const MAX_DAY = 6666;

const SliderWithTooltip = createSliderWithTooltip(Slider);

const checkBoxStyles = theme => ({
    root: {
        '&$checked': {
            color: '#F3B64E',
        },
        '&:hover': {
            background: "none",
        },
    },
    checked: {},
})

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

const AddNewStakeView = (props) => {
    const { t } = useTranslation();
    let isMobile = useMediaQuery();
    let mosDecimal = useRef();
    let startDay = DateTime.utc().plus({ days: 1 });

    const [mosTokenService, setMosTokenService] = useState(null);
    const [mosPlatformService, setMosPlatformService] = useState(null);

    const [walletAddress, setWalletAddress] = useState('');
    const [mosBalance, setMosBalance] = useState('0');
    const [stakeAmount, setStakeAmount] = useState();
    const [stakeDays, setStakeDays] = useState('');
    const [sliderValue, setSliderValue] = useState(10);
    const [shareRate, setShareRate] = useState('-');
    // formInput is for user changing the form input for stake days, ultimately we use stakeDays when submitting
    const [stakeDaysFormInput, setStakeDaysFormInput] = useState('');
    const [endDay, setEndDay] = useState("");
    const [calculatedReward, setCalculatedReward] = useState({});
    const [gb1, setGb1] = useState('0');
    const [gb2, setGb2] = useState('0');

    useAsyncEffect(async () => {
        // let mosPlatformService = new MosPlatformService();
        // await mosPlatformService.connectContract();
        // setMosPlatformService(mosPlatformService);

        // let mosTokenService = new MosTokenService();
        // await mosTokenService.connectContract();
        // setMosTokenService(mosTokenService);

        // let decimal = await mosTokenService.getDecimals();
        // mosDecimal.current = decimal;
        // let globalInfo = await mosPlatformService.globalInfo();
        // let shareRate = new Decimal(globalInfo[2]._hex).dividedBy(10 ** 5).toFixed(2, Decimal.ROUND_DOWN);
        // setShareRate(shareRate);

        // let _gb1 = await mosPlatformService.getGbOne();
        // setGb1(new Decimal(_gb1._hex).dividedBy(10 ** decimal).toString());
        // let _gb2 = await mosPlatformService.getGbTwo();
        // setGb2(new Decimal(_gb2._hex).dividedBy(10 ** decimal).toString());
        initServices();
    }, []);

    const initServices = () => {
        setTimeout(async () => {
            let mosPlatformService = new MosPlatformService();
            await mosPlatformService.connectContract();
            setMosPlatformService(mosPlatformService);

            let mosTokenService = new MosTokenService();
            await mosTokenService.connectContract();
            setMosTokenService(mosTokenService);

            let decimal = await mosTokenService.getDecimals();
            mosDecimal.current = decimal;
            let globalInfo = await mosPlatformService.globalInfo();
            // share rate dividedby 1e5 initially, and now adjust to multiply additional 1e4 
            let shareRate = new Decimal(globalInfo[2]._hex).dividedBy(10 ** 1).toFixed(4, Decimal.ROUND_DOWN);
            // console.log("new Decimal(globalInfo[2]._hex): ", new Decimal(globalInfo[2]._hex).dividedBy(10 ** 1).toFixed(4, Decimal.ROUND_DOWN))
            setShareRate(shareRate);

            let _gb1 = await mosPlatformService.getGbOne();
            // setGb1(new Decimal(_gb1._hex).dividedBy(10 ** decimal).toString());
            let _gb2 = await mosPlatformService.getGbTwo();
            // setGb2(new Decimal(_gb2._hex).dividedBy(10 ** decimal).toString());

            let res = await getGiftingBonus();
            if (res) {
                let gb1 = new Decimal(res[0].gb1).add(_gb1._hex).dividedBy(10 ** 8).toFixed(2);
                let gb2 = new Decimal(res[0].gb2).add(_gb2._hex).dividedBy(10 ** 8).toFixed(2);
                setGb1(gb1);
                setGb2(gb2);
            }

        }, 3000);
    }

    useAsyncEffect(async () => {
        if (props.getWalletAddressData.data) {
            setWalletAddress(props.getWalletAddressData.data);
        }
    }, [props.getWalletAddressData]);

    useAsyncEffect(async () => {
        if (walletAddress && mosTokenService) {
            setTimeout(async () => {
                let balance = await mosTokenService.getBalance(walletAddress);
                let convertedBalance = new Decimal(balance._hex).dividedBy(10 ** mosDecimal.current).toString();
                setMosBalance(convertedBalance);
            }, 3000);
        }
    }, [walletAddress, mosTokenService]);

    useEffect(() => {
        setCalculatedReward({});
    }, [stakeDays, stakeAmount]);

    useEffect(() => {
        handleSliderChange(stakeDaysFormInput);
    }, [stakeDaysFormInput]);

    const handleStakedaysFormInput = (value) => {
        if (isNaN(Number(value))) {
            setStakeDaysFormInput('');
        } else if (Number(value) > MAX_DAY) {
            setStakeDaysFormInput('6666');
        } else {
            setStakeDaysFormInput(value);
        }
    }

    const handleSliderChange = (value) => {
        setSliderValue(value);
        onStakeDaysInputChange(value);
    };

    const onStakeDaysInputChange = (value) => {
        setStakeDays(value);
        if (isNaN(Number(value)) || value.length === 0) {
            setEndDay('');
        } else {
            setEndDay(startDay.plus({ days: value }));
        }
    }

    const sliderTipView = (value) => {
        if (value === 1825) {
            return '5 Years';
        } else if (value === 3650) {
            return '10 Years';
        } else {
            return `${value}`;
        }
    }

    const isValid = (isCalculate) => {
        let isValid = false;
        let stakeInput = Number(stakeAmount);
        let stakeDaysInput = Number(stakeDays);

        if (walletAddress.length === 0) {
            alert("Please connect your wallet");
            return isValid;
        }

        if (isNaN(stakeInput) || isNaN(stakeDaysInput) || stakeInput === 0 || stakeDaysInput === 0 || stakeInput < 0 || stakeDaysInput < 0) {
            alert("Invalid input");
            return isValid;
        } else if (!isCalculate && stakeInput > Number(mosBalance)) {
            alert("Insufficient fund");
            return isValid;
        } else if (stakeInput < MINIMUM_STAKE_AMOUNT) {
            alert("Minimum stake amount is 10 MOS");
            return isValid;
        } else if (stakeDaysInput < MINIMUM_STAKE_DAYS) {
            alert(`Minimum of ${MINIMUM_STAKE_DAYS} stakedays is required`);
            return isValid;
        } else if (stakeDaysInput > MAX_DAY) {
            alert(`Maximum number of stake days is ${MAX_DAY}`);
            return isValid;
        }
        else {
            return true;
        }
    }

    const checkAllowance = async () => {
        let allowance = await mosTokenService.getAllowance(walletAddress, MOS_PLATFORM_CONTRACT_ADDRESS);
        if (allowance === 0) await mosTokenService.approveAllowance(MOS_PLATFORM_CONTRACT_ADDRESS);
    }

    const onCalculateRewardPress = async () => {
        eventBus.dispatch("stakeLoading", { message: true });

        // if (isValid()) {
        //     let stake = new Decimal(stakeAmount).times(10 ** mosDecimal.current).toString();
        //     let res = await mosPlatformService.stakeReward(stake, `${stakeDays}`);
        //     if (res) {
        //         let data = {
        //             colossal: new Decimal(res.colossal._hex).dividedBy(10 ** mosDecimal.current).toFixed(4),
        //             endurance: new Decimal(res.endurance._hex).dividedBy(10 ** mosDecimal.current).toFixed(4),
        //             gb1: new Decimal(res.gb1._hex).dividedBy(10 ** mosDecimal.current).toFixed(4),
        //             gb2: new Decimal(res.gb2._hex).dividedBy(10 ** mosDecimal.current).toFixed(4),
        //             interest: new Decimal(res.interest._hex).dividedBy(10 ** mosDecimal.current).toFixed(4),
        //             share: new Decimal(res.share._hex).dividedBy(10 ** mosDecimal.current).toFixed(4),
        //         }
        //         // console.log("stake reward data: ", data);
        //         setCalculatedReward(data);
        //     }
        // }


        // TODO: ADD THIS WHEN PUSHING TO MAINNET 
        //          ADD INSIDE ISVALID
        if (isValid(true)) {
            let stake = new Decimal(stakeAmount).times(10 ** mosDecimal.current).toString();
            let param = {
                amount: stake,
                day: stakeDays
            }
            setEndDay(startDay.plus({ days: stakeDays }));
            let res = await getStakeRewardCalculation(param);
            if (res) {
                let reward = res[0];
                let data = {
                    colossal: new Decimal(reward.Colossal).toFixed(4, Decimal.ROUND_DOWN),
                    endurance: new Decimal(reward.Endurance).toFixed(4, Decimal.ROUND_DOWN),
                    gb1: new Decimal(reward.GiftingBonus1).toFixed(4, Decimal.ROUND_DOWN),
                    gb2: new Decimal(reward.GiftingBonus2).toFixed(4, Decimal.ROUND_DOWN),
                    interest: new Decimal(reward.Interest).toFixed(4, Decimal.ROUND_DOWN),
                    share: new Decimal(reward.Shares).toFixed(4, Decimal.ROUND_DOWN),
                }
                setCalculatedReward(data);
            }
        }

        eventBus.dispatch("stakeLoading", { message: false });
    }

    const onStakePress = async () => {
        try {
            eventBus.dispatch("stakeLoading", { message: true });
            if (isValid()) {
                await checkAllowance();
                let stakeInput = new Decimal(stakeAmount);
                let stake = stakeInput.times(10 ** mosDecimal.current).toString();

                let tx = await mosPlatformService.stakeStart(stake, `${stakeDays}`);
                if (tx) {
                    setTimeout(async () => {
                        if (await checkTransaction(tx)) {
                            alert('Transaction confirmed');
                            let count = await mosPlatformService.stakeCount(walletAddress);
                            eventBus.dispatch("addNewStake", { message: count });

                            let balance = await mosTokenService.getBalance(walletAddress);
                            let convertedBalance = new Decimal(balance._hex).dividedBy(10 ** mosDecimal.current).toString();
                            setMosBalance(convertedBalance);

                            setStakeAmount('');
                            setStakeDays('');
                            setStakeDaysFormInput('');
                        } else {
                            alert('Transaction failed');
                            eventBus.dispatch("stakeLoading", { message: false });
                        }
                    }, 5000);
                } else {
                    alert('Transaction failed');
                    eventBus.dispatch("stakeLoading", { message: false });
                }
            } else {
                eventBus.dispatch("stakeLoading", { message: false });
            }
            eventBus.dispatch("stakeLoading", { message: false });
        } catch (err) {
            eventBus.dispatch("stakeLoading", { message: false });
        }
    }

    const PackageView = (props) => {
        return (
            <div className="content-wrapper ans__p-container">
                <div className="ans__p-title-container">
                    <div className="ans__p-title font-lora">{props.title}</div>
                    <CustomCheckbox
                        disabled
                        checked={props.checked}
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                    />
                </div>
                <div className="ans__p-date">{props.date}</div>
            </div>
        )
    }

    const AddNewStakeItemView = (props) => {
        return (
            <div className="ans__item-container" style={props.containerStyle}>
                <ReactTooltip id='ans__tooltip-interest' aria-haspopup='true' role='interest tooltip'>
                    <p>Total Interest = Endurance Payout + Colossal Payout + Base Interest</p>
                </ReactTooltip>

                <ReactTooltip id='ans__tooltip-gb1' aria-haspopup='true' role='gb tooltip' overridePosition={({ left, top },
                    currentEvent, currentTarget, node) => {
                    const d = document.documentElement;
                    left = Math.min(d.clientWidth - node.clientWidth, left);
                    top = Math.min(d.clientHeight - node.clientHeight, top);
                    left = Math.max(0, left);
                    top = Math.max(0, top);
                    return { top, left }
                }}>
                    <div>Current Gifting Bonus 1: {formatNumber(gb1)}MOS</div>

                    <div style={{ paddingTop: '8px' }}>Equally distributed base on M-Share holders. </div>
                    <div>Stake MOS during launchphase up to the BonusReleaseDate or longer to entitle for the exclusive bonus.</div>
                </ReactTooltip>

                <ReactTooltip id='ans__tooltip-gb2' aria-haspopup='true' role='gb tooltip' overridePosition={({ left, top },
                    currentEvent, currentTarget, node) => {
                    const d = document.documentElement;
                    left = Math.min(d.clientWidth - node.clientWidth, left);
                    top = Math.min(d.clientHeight - node.clientHeight, top);
                    left = Math.max(0, left);
                    top = Math.max(0, top);
                    return { top, left }
                }}>
                    <div>Current Gifting Bonus 2: {formatNumber(gb2)}MOS</div>

                    <div style={{ paddingTop: '8px' }}>Equally distributed base on M-Share holders. </div>
                    <div>Stake MOS during launchphase up to the BonusReleaseDate or longer to entitle for the exclusive bonus.</div>
                </ReactTooltip>

                <div className="ans__item-title">{props.title}</div>
                <a data-tip data-for={props.tooltip} className="ans__item-tooltip" style={props.tooltip && { cursor: 'pointer' }}>
                    <div className="ans__item-value font-lora">{props.value.split('\n').map((line, i) => (
                        <span key={i}>
                            {line} {props.gb && i === 1 && <span className="ans__item-question-mark">?</span>}
                            <br />
                        </span>
                    ))}</div>
                    <span className="ans__item-subvalue">{props.subvalue}  {!props.gb && props.tooltip && <span className="ans__item-question-mark">?</span>}</span>
                </a>
            </div>
        )

    }

    const marks = {
        0: {
            style: {
                color: 'white',
                fontSize: isMobile ? '3vw' : '1.2vw'
            },
            label: '0 Days'
        },
        1825: {
            style: {
                color: 'white',
                fontSize: isMobile ? '3vw' : '1.2vw'
            },
            label: '5 Years'
        },
        3650: {
            style: {
                color: 'white',
                fontSize: isMobile ? '3vw' : '1.2vw'
            },
            label: '10 Years'
        },
        6666: {
            style: {
                color: 'white',
                fontSize: isMobile ? '3vw' : '1.2vw'
            },
            label: 'Max'
        },
    };


    const AddNewStakeViewMobile = (
        <>
            <div className="tdvv__title font-lora">Add New Stake</div>
            <div className="tdvv__desc"><br />Be an early staker today! Stake bigger or longer to get bonus shares!</div>
            <PackageView
                checked={stakeDays >= 365 ? true : false}
                title={'Gifting Bonus 1'}
                date={DateTime.fromISO(MOS_GB_ONE_DATE).toFormat('dd/MM/y')}
            />
            <PackageView
                checked={stakeDays >= 730 ? true : false}
                title={'Gifting Bonus 2'}
                date={DateTime.fromISO(MOS_GB_TWO_DATE).toFormat('dd/MM/y')}
            />
            <div className="ans__horizontal-divier" />
            <FormInput
                title={'Amount to stake'}
                value={`${stakeAmount ?? ''}`}
                setValue={setStakeAmount}
                onClick={() => setStakeAmount(mosBalance)}
                actionLabel={'Max'}
                note={`Balance: ${formatNumber(mosBalance)}`}
            />
            <FormInput
                title={'Stake length (days)'}
                value={`${stakeDays}`}
                setValue={handleStakedaysFormInput}
                onClick={() => setStakeDays(`${MAX_DAY}`)}
                actionLabel={'Max'}
                note={`Max Stake Length: ${MAX_DAY}`}
                containerStyle={{ marginTop: "4vw" }}
            />
            <div className="ans__horizontal-divier" />
            <div className="ans__item">
                <AddNewStakeItemView
                    title={startDay.toFormat('MMMM dd, yyyy')}
                    value={'Start Date'}
                />
                <div className="ans__vertical-divier" />
                <AddNewStakeItemView
                    title={calculatedReward.endurance ? formatNumber(calculatedReward.endurance) : '0'}
                    value={'Endurance Payout'}
                    subvalue={'Estimated Value'}
                // tooltip={'ans__tooltip-endurance'}
                />
            </div>
            <div className="ans__item" style={{ paddingTop: "5vw" }}>
                <AddNewStakeItemView
                    title={endDay ? endDay.toFormat('MMMM dd, yyyy') : '-'}
                    value={'End Date'}
                />
                <div className="ans__vertical-divier" />
                <AddNewStakeItemView
                    title={calculatedReward.colossal ? formatNumber(calculatedReward.colossal) : '0'}
                    value={'Colossal Payout'}
                    subvalue={'Estimated Value'}
                // tooltip={'ans__tooltip-colossal'}
                />
            </div>
            <div className="ans__item" style={{ paddingTop: "5vw" }}>
                <AddNewStakeItemView
                    title={calculatedReward.share ? formatNumber(calculatedReward.share) : '0'}
                    value={'M-Share Rewards'}
                    subvalue={`${shareRate} MOS/M-Share`}
                />
                <div className="ans__vertical-divier" />
                <AddNewStakeItemView
                    title={calculatedReward.interest ? formatNumber(calculatedReward.interest) : '0'}
                    value={'Total Interests'}
                    subvalue={'Estimated Value'}
                    tooltip={'ans__tooltip-interest'}
                />
            </div>
            <div className="ans__item" style={{ paddingTop: "5vw" }}>
                <AddNewStakeItemView
                    title={calculatedReward.gb1 ? formatNumber(calculatedReward.gb1) : '0'}
                    value={'Estimated\nGifting Bonus 1'}
                    tooltip={'ans__tooltip-gb1'}
                    gb
                />
                <div className="ans__vertical-divier" />
                <AddNewStakeItemView
                    title={calculatedReward.gb2 ? formatNumber(calculatedReward.gb2) : '0'}
                    value={'Estimated\nGifting Bonus 2'}
                    tooltip={'ans__tooltip-gb2'}
                    gb
                />
            </div>
            <div className="ans__slider">
                <SliderWithTooltip
                    value={sliderValue}
                    trackStyle={{ backgroundColor: '#F3B64E', height: 6, marginTop: -2 }}
                    handleStyle={{
                        borderColor: '#F3B64E',
                        height: 16,
                        width: 16,
                        marginTop: -7,
                        backgroundColor: '#F3B64E',
                    }}
                    railStyle={{ backgroundColor: 'rgba(243,182,78,0.3)', height: 2 }}
                    dotStyle={{ borderColor: 'white', backgroundColor: 'white' }}
                    activeDotStyle={{ borderColor: '#f7cd85', backgroundColor: '#f7cd85' }}
                    onChange={handleSliderChange}
                    max={6666}
                    marks={marks}
                    tipFormatter={sliderTipView}
                />
            </div>
            <Button title={t('Calculate Reward')} onClick={() => onCalculateRewardPress()} />
            <Button title={t('Stake')} onClick={() => onStakePress()} />
        </>
    )

    const AddNewStakeViewWeb = (
        <>
            <div className="ans__header-container">
                <div className="ans__title-container">
                    <div className="tdvv__title font-lora">Add New Stake</div>
                    <div className="tdvv__desc">Be an early staker today! Stake bigger or longer to get bonus shares!</div>
                </div>
                <PackageView
                    checked={stakeDays >= 365 ? true : false}
                    title={'Gifting Bonus 1'}
                    date={DateTime.fromISO(MOS_GB_ONE_DATE).toFormat('dd/MM/y')}
                />
                <PackageView
                    checked={stakeDays >= 730 ? true : false}
                    title={'Gifting Bonus 2'}
                    date={DateTime.fromISO(MOS_GB_TWO_DATE).toFormat('dd/MM/y')}
                />
            </div>

            <div className="ans__body-container">
                <div style={{ width: '35%' }}>
                    <FormInput
                        title={'Amount to stake'}
                        value={`${stakeAmount ?? ''}`}
                        setValue={setStakeAmount}
                        onClick={() => setStakeAmount(mosBalance)}
                        actionLabel={'Max'}
                        note={`Balance: ${formatNumber(mosBalance)}`}
                    />
                    <FormInput
                        title={'Stake length (days)'}
                        value={`${stakeDays}`}
                        setValue={handleStakedaysFormInput}
                        onClick={() => setStakeDays(`${MAX_DAY}`)}
                        actionLabel={'Max'}
                        note={`Max Stake Length: ${MAX_DAY}`}
                        containerStyle={{ marginTop: "4vw" }}
                    />
                </div>
                <div className="ans__body-right-container">
                    <AddNewStakeItemView
                        title={startDay.toFormat('MMMM dd, yyyy')}
                        value={'Start Date'}
                    />
                    <AddNewStakeItemView
                        title={calculatedReward.endurance ? formatNumber(calculatedReward.endurance) : '0'}
                        value={'Endurance Payout'}
                        subvalue={'Estimated Value'}
                    // tooltip={'ans__tooltip-endurance'}
                    />
                    <AddNewStakeItemView
                        title={calculatedReward.gb1 ? formatNumber(calculatedReward.gb1) : '0'}
                        value={'Estimated\nGifting Bonus 1'}
                        tooltip={'ans__tooltip-gb1'}
                        gb
                    />
                    <AddNewStakeItemView
                        title={endDay ? endDay.toFormat('MMMM dd, yyyy') : '-'}
                        value={'End Date'}
                    />
                    <AddNewStakeItemView
                        title={calculatedReward.colossal ? formatNumber(calculatedReward.colossal) : '0'}
                        value={'Colossal Payout'}
                        subvalue={'Estimated Value'}
                    // tooltip={'ans__tooltip-colossal'}
                    />
                    <AddNewStakeItemView
                        title={calculatedReward.gb2 ? formatNumber(calculatedReward.gb2) : '0'}
                        value={'Estimated\nGifting Bonus 2'}
                        tooltip={'ans__tooltip-gb2'}
                        gb
                    />
                    <AddNewStakeItemView
                        containerStyle={{ borderBottomWidth: 0 }}
                        title={calculatedReward.share ? formatNumber(calculatedReward.share) : '0'}
                        value={`M-Share Rewards`}
                        subvalue={`${formatNumber(shareRate)} MOS/M-Share`}
                    />

                    <AddNewStakeItemView
                        containerStyle={{ borderBottomWidth: 0 }}
                        title={calculatedReward.interest ? formatNumber(calculatedReward.interest) : '0'}
                        value={'Total Interests'}
                        subvalue={'Estimated Value'}
                        tooltip={'ans__tooltip-interest'}
                    />

                    <AddNewStakeItemView
                        containerStyle={{ borderBottomWidth: 0 }}
                        title={''}
                        value={''}
                    />
                </div>
            </div>
            <div className="ans__slider">
                <SliderWithTooltip
                    value={sliderValue}
                    trackStyle={{ backgroundColor: '#F3B64E', height: 6, marginTop: -2 }}
                    handleStyle={{
                        borderColor: '#F3B64E',
                        height: 16,
                        width: 16,
                        marginTop: -7,
                        backgroundColor: '#F3B64E',
                    }}
                    railStyle={{ backgroundColor: 'rgba(243,182,78,0.3)', height: 2 }}
                    dotStyle={{ borderColor: 'white', backgroundColor: 'white' }}
                    activeDotStyle={{ borderColor: '#f7cd85', backgroundColor: '#f7cd85' }}
                    onChange={handleSliderChange}
                    max={6666}
                    marks={marks}
                    tipFormatter={sliderTipView}
                />
            </div>
            <div style={{ display: 'flex' }}>
                <Button title={t('Calculate Reward')} containerStyle={{ flex: 1, marginRight: '3vw' }} onClick={() => onCalculateRewardPress()} />
                <Button title={t('Stake')} containerStyle={{ flex: 1, marginLeft: '3vw' }} onClick={() => onStakePress()} />
            </div>
        </>
    )

    return (
        <div className="outer-content-wrapper ans__container">
            {isMobile ? AddNewStakeViewMobile : AddNewStakeViewWeb}
        </div>
    )
}

const mapStateToProps = store => ({
    getWalletAddressData: Actions.getWalletAddressData(store),
});

const mapDispatchToProps = {
};


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AddNewStakeView);