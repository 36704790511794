import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import useAsyncEffect from 'use-async-effect';
import { Decimal } from 'decimal.js';
import ReactTooltip from 'react-tooltip';

import './AllStakeView.css'
import Actions from '../../actions';
import { useMediaQuery } from '../../customHooks/useMediaQuery';
import Button from '../../components/Button/Button'
import { convertToDecimal, formatNumber, shortenPriceObj } from '../../utils/Utils';
import eventBus from '../../utils/EventBus';
import { checkTransaction } from '../../services/TronServices';
import { getStakesList } from '../../api';
import { USDT_DECIMALS } from '../../constant';
import MosPlatformService from '../../services/MosPlatformService';
import MosTokenService from '../../services/MosTokenService';
import { getStakeRewardCalculation } from '../../api/index';

const SHORTEN_VALUE = 999;

const formatValue = (val) => {
    let shortenPrice = shortenPriceObj(val);
    if (val > SHORTEN_VALUE) {
        let value = shortenPrice.price.toFixed(2);
        let suffix = shortenPrice.suffix;
        return `${value}${suffix}`;
    } else {
        return formatNumber(Number(val).toFixed(2));
    }
}

const AllStakeView = (props) => {
    const { t } = useTranslation();
    let isMobile = useMediaQuery();
    let mosDecimal = useRef();
    let currentDay = useRef();
    let mobileModalData = useRef();
    const [mosTokenService, setMosTokenService] = useState(null);
    const [mosPlatformService, setMosPlatformService] = useState(null);

    const [walletAddress, setWalletAddress] = useState('');
    const [stakeCount, setStakeCount] = useState('');
    const [historyCount, setHistoryCount] = useState('');
    const [stakeList, setStakeList] = useState([]);
    const [historyList, setHistoryList] = useState([]);
    const [stakeSharesTotal, setStakeSharesTotal] = useState(new Decimal(1));
    const [gbOne, setGbOne] = useState('1');
    const [gbTwo, setGbTwo] = useState('1');
    const [mosPrice, setMosPrice] = useState(0.0000);

    useAsyncEffect(() => {
        eventBus.on("addNewStake", (data) => {
            setStakeCount(data.message);
        });

        return () => {
            eventBus.remove("addNewStake");
        }
    }, []);

    useAsyncEffect(async () => {
        await initServices();
    }, []);

    const initServices = async () => {
        setTimeout(async () => {
            let auctService = new MosPlatformService();
            await auctService.connectContract();
            setMosPlatformService(auctService);

            let mosService = new MosTokenService();
            await mosService.connectContract();
            setMosTokenService(mosService);

            let decimal = await mosService.getDecimals();
            mosDecimal.current = decimal;
            let day = await auctService.getCurrentDay();
            currentDay.current = day;

            try {
                let globalInfo = await auctService.globalInfo();
                setStakeSharesTotal(new Decimal(globalInfo[5]._hex).dividedBy(10 ** decimal));

                let gb1Supply = await auctService.getGbOne();
                setGbOne(new Decimal(gb1Supply._hex).dividedBy(10 ** decimal));

                let gb2Supply = await auctService.getGbTwo();
                setGbTwo(new Decimal(gb2Supply._hex).dividedBy(10 ** decimal));

                let pool = await auctService.poolValue(currentDay.current - 1);
                let bid = await auctService.xfLobby(currentDay.current - 1);

                let mosPool = new Decimal(pool).dividedBy(10 ** decimal);
                let usdtBid = new Decimal(bid).dividedBy(10 ** USDT_DECIMALS);
                if (currentDay.current !== 0) {
                    let auctRate = mosPool.dividedBy(usdtBid);
                    let price = new Decimal(1).dividedBy(auctRate).toFixed(5);
                    setMosPrice(price);
                }

            } catch (err) {

            }
        }, 3000);
    }

    useAsyncEffect(async () => {
        if (props.getWalletAddressData.data) {
            setWalletAddress(props.getWalletAddressData.data);
        }
    }, [props.getWalletAddressData]);

    useAsyncEffect(async () => {
        if (walletAddress && mosPlatformService) {
            // eventBus.dispatch("stakeLoading", { message: true });
            let stakeCount = await mosPlatformService.stakeCount(walletAddress);
            setStakeCount(stakeCount);
            let stakeHistory = await getStakesList(props.getWalletAddressData.data);
            // console.log("stake: ", stakeHistory);
            if (stakeHistory === undefined) stakeHistory = [];
            setHistoryList(stakeHistory);

            // eventBus.dispatch("stakeLoading", { message: false });
        }
    }, [walletAddress, mosPlatformService]);

    useAsyncEffect(async () => {
        getStakeList(stakeCount);
    }, [stakeCount]);

    const getStakeList = async (count) => {
        // eventBus.dispatch("stakeLoading", { message: true });

        let list = [];
        for (let i = 0; i < count; i++) {
            let res = await mosPlatformService.stakeLists(walletAddress, i);
            let param = {
                amount: res.stakedHearts.toString(),
                day: res.stakedDays
            }
            let newData = { interest: new Decimal(0) };
            let stakeReward = await getStakeRewardCalculation(param);
            if (stakeReward) {
                let reward = stakeReward[0];
                newData.interest = new Decimal(reward.Interest);
            }
            let convertedHearts = convertToDecimal(res.stakedHearts._hex) / (10 ** mosDecimal.current);
            let convertedShares = convertToDecimal(res.stakeShares._hex) / (10 ** mosDecimal.current);
            res.stakedHearts = convertedHearts;
            res.stakeShares = convertedShares;

            let modifiedRes = { ...res, ...newData };
            list.push(modifiedRes);
        }
        list.reverse();
        setStakeList(list);
        // eventBus.dispatch("stakeLoading", { message: false });
    }

    const onEndStakeClick = async (stakeId) => {
        eventBus.dispatch("stakeLoading", { message: true });

        let list = [...stakeList];
        list.reverse();
        let index = list.findIndex(data => data.stakeId === stakeId);
        let tx = await mosPlatformService.stakeEnd(index, stakeId);
        if (tx) {
            setTimeout(async () => {
                if (await checkTransaction(tx)) {
                    alert('Transaction confirmed');
                    setStakeCount(await mosPlatformService.stakeCount(walletAddress));
                    let stakeHistory = await getStakesList(walletAddress);
                    // console.log("stake end: ", stakeHistory);
                    setHistoryList(stakeHistory);
                } else {
                    alert('Transaction failed');
                }
            }, 6000);
        } else {
            alert('Transaction failed');
            eventBus.dispatch("stakeLoading", { message: false });
        }

        eventBus.dispatch("stakeLoading", { message: false });
    }

    const AllStakeViewWeb = () => {
        return (
            <div className="outer-content-wrapper asv__container">
                <EarlyEndStakeModal />
                <ReactTooltip id='asv_apy' aria-haspopup='true' overridePosition={({ left, top },
                    currentEvent, currentTarget, node) => {
                    const d = document.documentElement;
                    left = Math.min(d.clientWidth - node.clientWidth, left);
                    top = Math.min(d.clientHeight - node.clientHeight, top);
                    left = Math.max(0, left);
                    top = Math.max(0, top);
                    return { top, left }
                }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        APY <div style={{ color: '#F3B64E', paddingLeft: '8px' }}>=</div>
                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', padding: '0px 8px' }}>
                            <span style={{ borderBottom: '1px solid #F3B64E' }}> Total Interest</span>
                            <span>Principal</span>
                        </div>
                        <div style={{ color: '#F3B64E' }}>x</div>
                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', padding: '0px 8px' }}>
                            <span style={{ borderBottom: '1px solid #F3B64E' }}>365</span>
                            <span>Stakedays Served</span>
                        </div>
                    </div>
                </ReactTooltip>

                <ReactTooltip id='asv_m-share' aria-haspopup='true' overridePosition={({ left, top },
                    currentEvent, currentTarget, node) => {
                    const d = document.documentElement;
                    left = Math.min(d.clientWidth - node.clientWidth, left);
                    top = Math.min(d.clientHeight - node.clientHeight, top);
                    left = Math.max(0, left);
                    top = Math.max(0, top);
                    return { top, left }
                }}>
                    <p>M-Shares = Amount of share you get in the pool</p>

                </ReactTooltip>

                <ReactTooltip id='asv_interest' aria-haspopup='true' overridePosition={({ left, top },
                    currentEvent, currentTarget, node) => {
                    const d = document.documentElement;
                    left = Math.min(d.clientWidth - node.clientWidth, left);
                    top = Math.min(d.clientHeight - node.clientHeight, top);
                    left = Math.max(0, left);
                    top = Math.max(0, top);
                    return { top, left }
                }}>
                    <p>Interest is given based on stakelength and amount of MOS staked</p>
                </ReactTooltip>

                <ReactTooltip id='asv_gb' aria-haspopup='true' overridePosition={({ left, top },
                    currentEvent, currentTarget, node) => {
                    const d = document.documentElement;
                    left = Math.min(d.clientWidth - node.clientWidth, left);
                    top = Math.min(d.clientHeight - node.clientHeight, top);
                    left = Math.max(0, left);
                    top = Math.max(0, top);
                    return { top, left }
                }}>
                    <div style={{ textAlign: 'center' }}>
                        <div>Included in Interest of Day 365 for GB1, </div>
                        <div>and Day 730 for GB2. </div>
                        <div>Does not include Principal</div>

                        <div style={{ paddingTop: '8px' }}>Wed, August 25, 2021, 08:00:00PM</div>
                        <div>to</div>
                        <div>Fri, August 25, 2023, 07:59:59PM</div>
                    </div>
                </ReactTooltip>

                <div className="tdvv__title font-lora">Active Stakes</div>
                <div className="asv-table-web-new font-sf-pro content-wrapper">
                    <div style={{ gridArea: '1 / 1 / 3 / 2' }} className="asv-table-web-new-header"><span style={{ color: '#F3B64E' }}>Start</span></div>
                    <div style={{ gridArea: '1 / 2 / 3 / 3' }} className="asv-table-web-new-header"><span>End</span></div>
                    <div style={{ gridArea: '1 / 3 / 3 / 4' }} className="asv-table-web-new-header"><span>Progress</span></div>
                    <div style={{ gridArea: '1 / 4 / 3 / 5' }} className="asv-table-web-new-header"><span>APY <a data-tip data-for='asv_apy' className="asv-table-question-mark">?</a></span></div>
                    <div style={{ gridArea: '1 / 5 / 3 / 6' }} className="asv-table-web-new-header"><span>Principal</span></div>
                    <div style={{ gridArea: '1 / 6 / 3 / 7' }} className="asv-table-web-new-header"><span>M-Shares <a data-tip data-for='asv_m-share' className="asv-table-question-mark">?</a></span></div>
                    <div style={{ gridArea: '1 / 7 / 3 / 8' }} className="asv-table-web-new-header"><span>Interest <a data-tip data-for='asv_interest' className="asv-table-question-mark">?</a></span></div>
                    <div style={{ gridArea: '1 / 8 / 2 / 10', justifyContent: 'center', borderBottom: '1px solid rgba(137,137,137,0.6)' }} className="asv-table-web-new-header"><span>Current Value</span></div>
                    <div style={{ gridArea: '2 / 8 / 3 / 9' }} className="asv-table-web-new-header"><span>MOS</span></div>
                    <div style={{ gridArea: '2 / 9 / 3 / 10' }} className="asv-table-web-new-header"><span>USD</span></div>
                    <div style={{ gridArea: '1 / 10 / 2 / 12', justifyContent: 'center', borderBottom: '1px solid rgba(137,137,137,0.6)' }} className="asv-table-web-new-header">
                        <span style={{ fontSize: '0.8vw' }}>Estimated Gifting Bonus <a data-tip data-for='asv_gb' className="asv-table-question-mark">?</a></span>
                    </div>
                    <div style={{ gridArea: '2 / 10 / 3 / 11' }} className="asv-table-web-new-header"><span>GB1</span></div>
                    <div style={{ gridArea: '2 / 11 / 3 / 12' }} className="asv-table-web-new-header"><span>GB2</span></div>
                    <div style={{ gridArea: '1 / 12 / 3 / 13' }} className="asv-table-web-new-header"><span>End</span></div>

                    {stakeList && stakeList.map((data) => {
                        let startDay = new Decimal(data.lockedDay).plus(1);
                        let endDay = startDay.plus(data.stakedDays);
                        let progress = 0;
                        let gb1 = 0;
                        let gb2 = 0;
                        let interest = data.interest;
                        let apy = 0;

                        if (currentDay.current + 1 > startDay) {
                            let prog = ((currentDay.current - data.lockedDay) / Number(data.stakedDays)) * 100;
                            progress = (prog > 100) ? 100 : prog;
                        }
                        apy = new Decimal(interest).dividedBy(data.stakedHearts).times(365 / (data.lockedDay + data.stakedDays)).toString();
                        let currentValueMos = interest.plus(data.stakedHearts);
                        let currentValueUsd = currentValueMos.times(mosPrice);
                        let gbDenominator = stakeSharesTotal.plus(data.stakeShares);
                        if (endDay.gte(365)) gb1 = new Decimal(data.stakeShares).times(gbOne).dividedBy(gbDenominator);
                        if (endDay.gte(730)) gb2 = new Decimal(data.stakeShares).times(gbTwo).dividedBy(gbDenominator);

                        return (
                            <div style={{ display: 'contents' }} key={data.stakeId}>
                                <div className="asv-table-web-data"><span>{formatNumber(startDay.toString())}</span></div>
                                <div className="asv-table-web-data"><span>{formatNumber(endDay.toString())}</span></div>
                                <div className="asv-table-web-data"><span>{progress.toFixed()}%</span></div>
                                <div className="asv-table-web-data"><span>{formatValue(apy)} %</span></div>
                                <div className="asv-table-web-data"><span>{formatValue(data.stakedHearts)}</span></div>
                                <div className="asv-table-web-data"><span>{formatValue(data.stakeShares)}</span></div>
                                <div className="asv-table-web-data"><span>{formatValue(interest.toString())}</span></div>
                                <div className="asv-table-web-data"><span>{formatValue(currentValueMos.toString())}</span></div>
                                <div className="asv-table-web-data"><span>{formatValue(currentValueUsd.toString())}</span></div>
                                <div className="asv-table-web-data"><span>{formatValue(gb1.toString())}</span></div>
                                <div className="asv-table-web-data"><span>{formatValue(gb2.toString())}</span></div>
                                <div className="asv-table-web-data">
                                    <div className="content-wrapper asv-exit-btn" onClick={() => {
                                        if (currentDay.current + 1 < endDay) {
                                            setEarlyEndStake({ show: true, stakeId: data.stakeId, earlyEnd: true });
                                        } else {
                                            setEarlyEndStake({ show: true, stakeId: data.stakeId, earlyEnd: false });
                                        }
                                    }}>End</div>
                                </div>
                            </div>
                        )
                    })}
                    {stakeList.length === 0 &&
                        <div style={{ gridArea: 'auto/1/auto/15', color: 'grey', textAlign: 'center', padding: '6px 0px', borderTop: '1px solid rgba(137,137,137,0.6)' }}>
                            None
                        </div>
                    }
                </div>

                <div className="tdvv__title font-lora" style={{ marginTop: '3vw' }}>Stake History</div>
                <div className="asv-table-web-new-history font-sf-pro content-wrapper">
                    <div style={{ gridArea: '1 / 1 / 3 / 2' }} className="asv-table-web-new-header"><span style={{ color: '#F3B64E' }}>Start</span></div>
                    <div style={{ gridArea: '1 / 2 / 3 / 3' }} className="asv-table-web-new-header"><span>End</span></div>
                    <div style={{ gridArea: '1 / 3 / 3 / 4' }} className="asv-table-web-new-header"><span>APY <a data-tip data-for='asv_apy' className="asv-table-question-mark">?</a></span></div>
                    <div style={{ gridArea: '1 / 4 / 3 / 5' }} className="asv-table-web-new-header"><span>Principal</span></div>
                    <div style={{ gridArea: '1 / 5 / 3 / 6' }} className="asv-table-web-new-header"><span>M-Shares <a data-tip data-for='asv_m-share' className="asv-table-question-mark">?</a></span></div>
                    <div style={{ gridArea: '1 / 6 / 3 / 7' }} className="asv-table-web-new-header"><span>Interest <a data-tip data-for='asv_interest' className="asv-table-question-mark">?</a></span></div>
                    <div style={{ gridArea: '1 / 7 / 2 / 9', justifyContent: 'center', borderBottom: '1px solid rgba(137,137,137,0.6)' }} className="asv-table-web-new-header"><span>Current Value</span></div>
                    <div style={{ gridArea: '2 / 7 / 3 / 8' }} className="asv-table-web-new-header"><span>MOS</span></div>
                    <div style={{ gridArea: '2 / 8 / 3 / 9' }} className="asv-table-web-new-header"><span>USD </span></div>
                    <div style={{ gridArea: '1 / 9 / 2 / 11', justifyContent: 'center', borderBottom: '1px solid rgba(137,137,137,0.6)' }} className="asv-table-web-new-header">
                        <span style={{ fontSize: '0.8vw' }}>Estimated Gifting Bonus <a data-tip data-for='asv_gb' className="asv-table-question-mark">?</a></span>
                    </div>
                    <div style={{ gridArea: '2 / 9 / 3 / 10' }} className="asv-table-web-new-header"><span>GB1</span></div>
                    <div style={{ gridArea: '2 / 10 / 3 / 11' }} className="asv-table-web-new-header"><span>GB2</span></div>

                    {historyList && historyList.map((data, index) => {
                        let startDay = new Decimal(data.stake_on_day).plus(1).toString();
                        let endDay = new Decimal(data.stake_on_day).plus(data.staked_days).toString();
                        let apy = new Decimal(data.interest).dividedBy(data.principal).times(365 / (data.stake_on_day + data.staked_days)).toString();
                        let principal = new Decimal(data.principal).dividedBy(10 ** mosDecimal.current).toString();
                        let share = new Decimal(data.share).dividedBy(10 ** mosDecimal.current).toString();
                        let interest = new Decimal(data.interest).dividedBy(10 ** mosDecimal.current).toString();
                        let currentValueMos = new Decimal(data.interest).plus(principal).toString();
                        let currentValueUsd = new Decimal(currentValueMos).times(mosPrice).toString();
                        let gb1 = new Decimal(data.gb1).dividedBy(10 ** mosDecimal.current).toString();
                        let gb2 = new Decimal(data.gb2).dividedBy(10 ** mosDecimal.current).toString();

                        return (
                            <div style={{ display: 'contents' }} key={data.stakeShares}>
                                <div className="asv-table-web-data"><span>{formatNumber(startDay)}</span></div>
                                <div className="asv-table-web-data"><span>{formatNumber(endDay)}</span></div>
                                <div className="asv-table-web-data"><span>{formatValue(apy)} %</span></div>
                                <div className="asv-table-web-data"><span>{formatValue(principal)}</span></div>
                                <div className="asv-table-web-data"><span>{formatValue(share)}</span></div>
                                <div className="asv-table-web-data"><span>{formatValue(interest)}</span></div>
                                <div className="asv-table-web-data"><span>{formatValue(currentValueMos)}</span></div>
                                <div className="asv-table-web-data"><span>{formatValue(currentValueUsd)}</span></div>
                                <div className="asv-table-web-data"><span>{formatValue(gb1)}</span></div>
                                <div className="asv-table-web-data"><span>{formatValue(gb2)}</span></div>
                            </div>
                        )
                    })}
                    {historyList.length === 0 &&
                        <div style={{ gridArea: 'auto/1/auto/14', color: 'grey', textAlign: 'center', padding: '6px 0px', borderTop: '1px solid rgba(137,137,137,0.6)' }}>
                            None
                        </div>
                    }
                </div>



            </div>
        )
    }

    const [showDetail, setShowDetail] = useState(false);
    const [earlyEndStake, setEarlyEndStake] = useState({
        show: false,
        stakeId: '-',
        earlyEnd: true,
    });

    const EarlyEndStakeModal = () => {
        return (
            <Modal
                id="tron-listener-modal"
                show={earlyEndStake.show}
                onHide={_ => setEarlyEndStake({ show: false })}
                centered>
                <Modal.Header>
                    <Modal.Title>{t('Attention')}</Modal.Title>
                </Modal.Header>

                {earlyEndStake.earlyEnd ?
                    <Modal.Body>
                        <div style={{ paddingBottom: '6px' }}>Warning! Ending your stake earlier than the contracted term will incur Early Revocation Penalty. </div>
                        <div>Details:
                            <a href="https://themos.io/whitepaper#staking"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="asv__early-end-modal-link"
                            >
                                https://themos.io/whitepaper#staking
                            </a>
                        </div>
                        <div style={{ paddingTop: '18px' }}>Are you sure you want to end your stake early?</div>
                    </Modal.Body>
                    :
                    <Modal.Body>
                        <div style={{ paddingBottom: '6px' }}>Friendly reminder: Ending your stake late after the grace period will incur LateRevocationPenalty. </div>
                        <div>Details:
                            <a href="https://themos.io/whitepaper#staking"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="asv__early-end-modal-link"
                            >
                                https://themos.io/whitepaper#staking
                            </a>
                        </div>
                        <div style={{ paddingTop: '18px' }}>Kindly end your stake within the grade period (14 days after contract fulfilled)</div>
                    </Modal.Body>
                }

                <Modal.Footer>
                    <Button
                        title={'Cancel'}
                        containerStyle={{ padding: '6px 20px', margin: '10px', backgroundColor: '#898989' }}
                        titleStyle={{ fontSize: '18px' }}
                        onClick={() => {
                            setEarlyEndStake({ show: false });
                        }}
                    />
                    <Button
                        title={'End Stake'}
                        containerStyle={{ padding: '6px 20px', margin: '10px' }}
                        titleStyle={{ fontSize: '18px' }}
                        onClick={() => {
                            onEndStakeClick(earlyEndStake.stakeId);
                            setEarlyEndStake({ show: false });
                        }}
                    />
                </Modal.Footer>
            </Modal>
        )
    }

    const StakeDetailModal = () => {
        let data = mobileModalData.current.data;
        let isHistory = mobileModalData.current.isHistory;
        let startDay = 0;
        let endDay = 0;
        let progress = 0;
        let principal = 0;
        let shares = 0;
        let interest = 0;
        let apy = 0;
        let currentValueMos = 0;
        let currentValueUsd = 0;
        let gb1 = 0;
        let gb2 = 0;

        if (data && !isHistory) {
            startDay = new Decimal(data.lockedDay).plus(1);
            endDay = startDay.plus(data.stakedDays);
            principal = data.stakedHearts;
            shares = data.stakeShares;
            interest = data.interest;

            if (currentDay.current + 1 > startDay) {
                let prog = ((currentDay.current - data.lockedDay) / Number(data.stakedDays)) * 100;
                progress = (prog > 100) ? 100 : prog;
            }

            apy = new Decimal(interest).dividedBy(data.stakedHearts).times(365 / (data.lockedDay + data.stakedDays)).toString();
            currentValueMos = interest.plus(data.stakedHearts);
            currentValueUsd = currentValueMos.times(mosPrice);
            let gbDenominator = stakeSharesTotal.plus(data.stakeShares);
            if (endDay.gte(365)) gb1 = new Decimal(data.stakeShares).times(gbOne).dividedBy(gbDenominator);
            if (endDay.gte(730)) gb2 = new Decimal(data.stakeShares).times(gbTwo).dividedBy(gbDenominator);
        }
        if (data && isHistory) {
            startDay = new Decimal(data.stake_on_day).plus(1);
            endDay = new Decimal(data.stake_on_day).plus(data.staked_days);
            apy = new Decimal(data.interest).dividedBy(data.principal).times(365 / (data.stake_on_day + data.staked_days)).toString();
            principal = new Decimal(data.principal).dividedBy(10 ** mosDecimal.current);
            shares = new Decimal(data.share).dividedBy(10 ** mosDecimal.current);
            interest = new Decimal(data.interest).dividedBy(10 ** mosDecimal.current);
            currentValueMos = new Decimal(data.interest).plus(principal).toString();
            currentValueUsd = new Decimal(currentValueMos).times(mosPrice);
            gb1 = new Decimal(data.gb1).dividedBy(10 ** mosDecimal.current);
            gb2 = new Decimal(data.gb2).dividedBy(10 ** mosDecimal.current);
        }


        return (
            <Modal
                id="tron-listener-modal"
                show={showDetail}
                onHide={_ => setShowDetail(false)}
                centered>
                <div className="content-wrapper asv-table-web" style={{ marginTop: 0 }}>
                    <ReactTooltip id='asv_apy' aria-haspopup='true' overridePosition={({ left, top },
                        currentEvent, currentTarget, node) => {
                        const d = document.documentElement;
                        left = Math.min(d.clientWidth - node.clientWidth, left);
                        top = Math.min(d.clientHeight - node.clientHeight, top);
                        left = Math.max(0, left);
                        top = Math.max(0, top);
                        return { top, left }
                    }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            APY <div style={{ color: '#F3B64E', paddingLeft: '8px' }}>=</div>
                            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', padding: '0px 8px' }}>
                                <span style={{ borderBottom: '1px solid #F3B64E' }}> Total Interest</span>
                                <span>Principal</span>
                            </div>
                            <div style={{ color: '#F3B64E' }}>x</div>
                            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', padding: '0px 8px' }}>
                                <span style={{ borderBottom: '1px solid #F3B64E' }}>365</span>
                                <span>Stakedays Served</span>
                            </div>
                        </div>
                    </ReactTooltip>

                    <ReactTooltip id='asv_m-share' aria-haspopup='true' overridePosition={({ left, top },
                        currentEvent, currentTarget, node) => {
                        const d = document.documentElement;
                        left = Math.min(d.clientWidth - node.clientWidth, left);
                        top = Math.min(d.clientHeight - node.clientHeight, top);
                        left = Math.max(0, left);
                        top = Math.max(0, top);
                        return { top, left }
                    }}>
                        <p>M-Shares = Amount of share you get in the pool</p>
                    </ReactTooltip>

                    <ReactTooltip id='asv_interest' aria-haspopup='true' overridePosition={({ left, top },
                        currentEvent, currentTarget, node) => {
                        const d = document.documentElement;
                        left = Math.min(d.clientWidth - node.clientWidth, left);
                        top = Math.min(d.clientHeight - node.clientHeight, top);
                        left = Math.max(0, left);
                        top = Math.max(0, top);
                        return { top, left }
                    }}>
                        <p>Interest is given based on stakelength and amount of MOS staked</p>
                    </ReactTooltip>

                    <ReactTooltip id='asv_gb' aria-haspopup='true' overridePosition={({ left, top },
                        currentEvent, currentTarget, node) => {
                        const d = document.documentElement;
                        left = Math.min(d.clientWidth - node.clientWidth, left);
                        top = Math.min(d.clientHeight - node.clientHeight, top);
                        left = Math.max(0, left);
                        top = Math.max(0, top);
                        return { top, left }
                    }}>
                        <div style={{ textAlign: 'center' }}>
                            <div>Included in Interest of Day 365 for GB1, </div>
                            <div>and Day 730 for GB2. </div>
                            <div>Does not include Principal</div>

                            <div style={{ paddingTop: '8px' }}>Wed, August 25, 2021, 08:00:00PM</div>
                            <div>to</div>
                            <div>Fri, August 25, 2023, 07:59:59PM</div>
                        </div>
                    </ReactTooltip>


                    <div style={{ gridArea: '1 / 1 / 1 / 2' }} className="asv-table-web-header"><span>Start</span></div>
                    <div style={{ gridArea: '1 / 3 / 1 / 4' }} className="asv-table-web-header"><span>End</span></div>
                    {!isHistory && <div style={{ gridArea: '1 / 5 / 1 / 6' }} className="asv-table-web-header"><span>Progress</span></div>}
                    <div style={{ gridArea: '2 / 1 / 2 / 2' }} className="asv-table-web-data"><span>{formatNumber(startDay.toString())}</span></div>
                    <div style={{ gridArea: '2 / 3 / 2 / 4' }} className="asv-table-web-data"><span>{formatNumber(endDay.toString())}</span></div>
                    {!isHistory && <div style={{ gridArea: '2 / 5 / 2 / 6' }} className="asv-table-web-data"><span>{progress.toFixed()}%</span></div>}

                    <div className="asv-table-web-header" style={{ gridArea: '3 / 1 / 3 / 3', alignItems: 'flex-start' }}><span>Principal</span></div>
                    <div className="asv-table-web-header" style={{ gridArea: '4 / 1 / 4 / 3', alignItems: 'flex-start' }}><span>M-Shares <a data-tip data-for='asv_m-share' className="asv-table-question-mark-mobile">?</a></span></div>
                    <div className="asv-table-web-header" style={{ gridArea: '5 / 1 / 5 / 3', alignItems: 'flex-start' }}><span>Interest <a data-tip data-for='asv_interest' className="asv-table-question-mark-mobile">?</a></span></div>
                    <div className="asv-table-web-header" style={{ gridArea: '6 / 1 / 6 / 3', alignItems: 'flex-start' }}><span>Current Value</span></div>

                    <div className="asv-table-web-data" style={{ gridArea: '3 / 3 / 3 / 6', textAlign: 'right' }}><span>{formatValue(principal.toString())} MOS</span></div>
                    <div className="asv-table-web-data" style={{ gridArea: '4 / 3 / 4 / 6', textAlign: 'right' }}><span>{formatValue(shares.toString())}</span></div>
                    <div className="asv-table-web-data" style={{ gridArea: '5 / 3 / 5 / 6', textAlign: 'right' }}><span>{formatValue(interest.toString())} MOS</span></div>
                    <div className="asv-table-web-data" style={{ gridArea: '6 / 3 / 6 / 6', textAlign: 'right' }}><span>{formatValue(currentValueMos.toString())} MOS<br />{formatValue(currentValueUsd.toString())} USD</span></div>

                    <div style={{ gridArea: '7 / 1 / 7 / 6' }} className="asv-table-web-header asv-table-web-divide-top"><span>APY  <a data-tip data-for='asv_apy' className="asv-table-question-mark-mobile">?</a></span></div>
                    {/* <div style={{ gridArea: '8 / 1 / 8 / 2' }} className="asv-table-web-header"><span>Chart</span></div> */}
                    {/* <div style={{ gridArea: '8 / 3 / 8 / 4' }} className="asv-table-web-header"><span>Yesterday</span></div> */}
                    {/* <div style={{ gridArea: '8 / 5 / 8 / 6' }} className="asv-table-web-header"><span>Add</span></div> */}
                    {/* <div style={{ gridArea: '9 / 1 / 9 / 2' }} className="asv-table-web-data"><span>123</span></div> */}
                    <div style={{ gridArea: '9 / 3 / 9 / 4' }} className="asv-table-web-data"><span>{formatValue(apy.toString())} %</span></div>
                    {/* <div style={{ gridArea: '9 / 5 / 9 / 6' }} className="asv-table-web-data"><span>322</span></div> */}

                    <div style={{ display: 'contents' }}>
                        <div style={{ gridArea: '10 / 1 / 10 / 6' }} className="asv-table-web-header asv-table-web-divide-top"><span>Estimated Gifting Bonus  <a data-tip data-for='asv_gb' className="asv-table-question-mark-mobile">?</a></span></div>
                        <div style={{ gridArea: '11 / 1 / 11 / 3' }} className="asv-table-web-header"><span>GB1</span></div>
                        <div style={{ gridArea: '11 / 4 / 11 / 6' }} className="asv-table-web-header"><span>GB2</span></div>
                        <div style={{ gridArea: '12 / 1 / 12 / 3' }} className="asv-table-web-data"><span>{formatValue(gb1.toString())}</span></div>
                        <div style={{ gridArea: '12 / 4 / 12 / 6' }} className="asv-table-web-data"><span>{formatValue(gb2.toString())}</span></div>
                    </div>

                    {!isHistory &&
                        <div style={{ gridArea: '13 / 1 / 13 / 6' }}>
                            <Button
                                title={'End Stake'}
                                containerStyle={{ padding: '1vw 0', marginBottom: '2vw' }}
                                onClick={() => {
                                    if (currentDay.current + 1 < endDay) {
                                        setEarlyEndStake({ show: true, stakeId: data.stakeId, earlyEnd: true });
                                    } else {
                                        setEarlyEndStake({ show: true, stakeId: data.stakeId, earlyEnd: false });
                                    }
                                    setShowDetail(false);
                                }}
                            />
                        </div>}
                </div>
            </Modal>
        )
    }

    const StakeItemView = (props) => {
        let data = props.data;
        let isHistory = props.isHistory;
        let startDay = 0;
        let endDay = 0;
        let progress = 0;
        let principal = 0;
        let shares = 0;
        let interest = 0;

        if (!isHistory) {
            startDay = new Decimal(data.lockedDay).plus(1);
            endDay = startDay.plus(data.stakedDays);
            if (currentDay.current + 1 > startDay) {
                let prog = ((currentDay.current - data.lockedDay) / Number(data.stakedDays)) * 100;
                progress = (prog > 100) ? 100 : prog;
            }
            interest = data.interest;
            principal = data.stakedHearts;
            shares = data.stakeShares;
        } else {
            startDay = new Decimal(data.stake_on_day).plus(1);
            endDay = new Decimal(data.stake_on_day).plus(data.staked_days);
            principal = new Decimal(data.principal).dividedBy(10 ** mosDecimal.current);
            shares = new Decimal(data.share).dividedBy(10 ** mosDecimal.current);
            interest = new Decimal(data.interest).dividedBy(10 ** mosDecimal.current);
        }

        return (
            <div className="content-wrapper asv-table-web"
                onClick={() => {
                    mobileModalData.current = props;
                    setShowDetail(true);
                }}>
                <div style={{ gridArea: '1 / 1 / 1 / 2' }} className="asv-table-web-header"><span>Start</span></div>
                <div style={{ gridArea: '1 / 3 / 1 / 4' }} className="asv-table-web-header"><span>End</span></div>
                {!props.isHistory && <div style={{ gridArea: ' 1 / 5 / 1 / 6' }} className="asv-table-web-header"><span>Progress</span></div>}
                <div style={{ gridArea: '2 / 1 / 2 / 2' }} className="asv-table-web-data"><span>{formatNumber(startDay.toString())}</span></div>
                <div style={{ gridArea: '2 / 3 / 2 / 4' }} className="asv-table-web-data"><span>{formatNumber(endDay.toString())}</span></div>
                {!props.isHistory && <div style={{ gridArea: ' 2 / 5 / 2 / 6' }} className="asv-table-web-data"><span>{progress.toFixed()}%</span></div>}

                <div className="asv-table-web-header" style={{ gridArea: '3 / 1 / 3 / 3', alignItems: 'flex-start' }}><span>Principal</span></div>
                <div className="asv-table-web-header" style={{ gridArea: '4 / 1 / 4 / 3', alignItems: 'flex-start' }}><span>M-Shares</span></div>
                <div className="asv-table-web-header" style={{ gridArea: '5 / 1 / 5 / 3', alignItems: 'flex-start' }}><span>Interest</span></div>

                <div className="asv-table-web-data" style={{ gridArea: '3 / 3 / 3 / 6', textAlign: 'right' }}><span>{formatValue(principal.toString())} MOS</span></div>
                <div className="asv-table-web-data" style={{ gridArea: '4 / 3 / 4 / 6', textAlign: 'right' }}><span>{formatValue(shares.toString())}</span></div>
                <div className="asv-table-web-data" style={{ gridArea: '5 / 3 / 5 / 6', textAlign: 'right' }}><span>{formatValue(interest.toString())} MOS</span></div>
            </div>
        )
    }

    const AllStakeViewMobile = () => {
        return (
            <div className="outer-content-wrapper asv__container">
                <EarlyEndStakeModal />
                {(mobileModalData.current) && <StakeDetailModal />}
                <div className="tdvv__title font-lora">Active Stakes</div>
                {stakeList && stakeList.map((data) => {
                    return (
                        <StakeItemView data={data} key={`stakeId ${data.stakeId}`} isHistory={false} />
                    )
                })}
                {stakeList.length === 0 &&
                    <div style={{ color: 'grey', textAlign: 'center', }}>
                        None
                    </div>
                }

                <div className="tdvv__title font-lora" style={{ marginTop: '3vw' }}>Stake History</div>
                {historyList && historyList.map((data) => {
                    return (
                        <StakeItemView data={data} key={`stakeId ${data.stakeId}`} isHistory={true} />
                    )
                })}
                {historyList.length === 0 &&
                    <div style={{ color: 'grey', textAlign: 'center', }}>
                        None
                    </div>
                }
            </div>
        )
    }

    if (isMobile)
        return (<AllStakeViewMobile />)
    else
        return (<AllStakeViewWeb />)
}

const mapStateToProps = store => ({
    getWalletAddressData: Actions.getWalletAddressData(store),
});

const mapDispatchToProps = {
};


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AllStakeView);