export const PRODUCTION = "PRODUCTION";
export const DEVELOPMENT = "DEVELOPMENT";

export const MAINNET = "Mainnet";
export const TESTNET = "Testnet";

export const TRONGRID_API = "https://api.trongrid.io";
export const TRON_STACK_API = "https://api.tronstack.io";
export const NILE_API = "https://api.nileex.io";
export const SHASTA_API = "https://api.shasta.trongrid.io";


// export const MOS_TOKEN_MAINNET = "TGo4TRxFgxm1wUnigg9fBVPv4BjguVmoKa";
// export const MOS_PLATFORM_MAINNET = "TNT4BKXnrQRMDvHC5vdWsqHdcmu8rmjhpB";
export const MOS_TOKEN_MAINNET = "TLgjFEnzmZZrDbD4pqZQ28xpfV3RxCwYkR";
export const MOS_PLATFORM_MAINNET = "TXh8rvXvFXV7JqAqZbn7nZaxBh7TZDVuM7";
export const USDT_TOKEN_ADDRESS_MAINNET = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t";
export const SRT_TOKEN_ADDRESS_MAINNET = "TEJApGsHLmWdVpfSAmz44j3ygjEwZbtFSo";
// export const USDT_TOKEN_ADDRESS_MAINNET = "TGRNAsKd9Eb4GgGduDLBHnrj8DmksBfuga";
// export const SRT_TOKEN_ADDRESS_MAINNET = "TBRdSDF3mybxBa4JCP4oGusLsV6aP6w2LN";


export const MOS_TOKEN_TESTNET = "TNVrWDSFK4i78hW8mFKnP2WVEUXZuQhfCr";
export const MOS_PLATFORM_TESTNET = "TFqjUn7maxrKyib3egidpXvJezr5CNyZem";
export const USDJ_TOKEN_ADDRESS_TESTNET = "TXhiYRG9ric6hWKxgLB25rFfuNUMYJrKbG";
export const SRT_TOKEN_ADDRESS_TESTNET = "TKnTXBp1ADzgX5VrJPbEx985MhffJUgyio";

export const ZERO_ADDRESS = "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb";

export const MAINNET_CHAIN_ID = "0x38";
export const TESTNET_CHAIN_ID = "0x61";

export const SRT_RATE = 0.0001;
export const MOS_CURRENT_PRICE = 0.0001;
export const USDT_DECIMALS = 6;

export const MOS_GB_ONE_DATE = "2022-08-25";
export const MOS_GB_TWO_DATE = '2023-08-25';

export const MAX_ALLOWANCE_VALUE =
    "115792089237316195423570985008687907853269984665640564039457584007913129639935";

export const NETWORK_API_ARR_MAINNET = [
    "https://api.trongrid.io",
    "https://trx.mytokenpocket.vip",
];
export const NETWORK_API_ARR_TESTNET = [
    "https://api.nileex.io",
    "https://api.shasta.trongrid.io",
]
