import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import reducers from '../reducers';
import sagas from '../sagas';
import createTransform from 'redux-persist/es/createTransform';
import * as Flatted from 'flatted';

let store;
let middlewares;
const sagaMiddleware = createSagaMiddleware();


const transformCircular = createTransform(
  (inboundState, key) => Flatted.stringify(inboundState),
  (outboundState, key) => Flatted.parse(outboundState),
);

const config = {
  key: 'root',
  storage,
  whitelist: ['PROFILE', 'WALLET'],
  transforms: [transformCircular]
};

const reducer = persistCombineReducers(config, reducers);

middlewares = applyMiddleware(sagaMiddleware);

export const getStore = () => store;

const configureStore = () => {
  store = createStore(reducer, middlewares);
  sagaMiddleware.run(sagas);
  const persistor = persistStore(store);
  return { persistor, store };
};

export default configureStore;