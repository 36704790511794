import { MAX_ALLOWANCE_VALUE } from '../constant';
import MosTokenABI from '../assets/abi/MosToken.json';
import TronProvider from '../TronProvider';
import { convertToDecimal, power } from '../utils/Utils';
import { MOS_TOKEN_CONTRACT_ADDRESS } from '../config';

async function _connectReadContract(addr) {
    try {
        return await TronProvider.contract(MosTokenABI, addr);
    } catch (err) {
        console.error(`TronWeb Connect Contract Error: ${err}`);
    }
}

async function _connectWriteContract(addr) {
    try {
        return await window.tronWeb.contract(MosTokenABI, addr);
    } catch (err) {
        console.error(`TronWeb Connect Contract Error: ${err}`);
    }
}

class MosTokenService {
    constructor(props) {
        this.readContract = null;
        this.writeContract = null;
        this.decimal = 18;
    }

    async connectContract() {
        [this.readContract, this.writeContract] = await Promise.all([
            _connectReadContract(MOS_TOKEN_CONTRACT_ADDRESS),
            _connectWriteContract(MOS_TOKEN_CONTRACT_ADDRESS)
        ])

        return;
    }

    async getBalance(walletAddr) {
        try {
            // await this.getDecimals();
            let balance = await this.readContract.balanceOf(walletAddr).call();
            // console.log((convertToDecimal(balance._hex) / (power(this.decimal))));
            // return convertToDecimal(balance._hex) / (power(this.decimal));
            return balance;
        } catch (err) {
            console.error(`Token Contract Balance Error: ${err.message}`);
        }
    }

    async getDecimals() {
        try {
            this.decimal = convertToDecimal(await this.readContract.decimals().call());
            return this.decimal;
        } catch (err) {
            console.error(`Token Contract Decimals Error: ${err.message}`);
        }
    }

    async totalSupply() {
        try {
            let res = await this.readContract._totalSupply().call();
            return (res);
        } catch (err) {
            console.error(`totalSupply Error: `, err);
        }
    }

    async getAllowance(walletAddr, contractAddr) {
        try {
            return convertToDecimal(await this.readContract.allowance(walletAddr, contractAddr).call())
        } catch (err) {
            console.error(`Token Contract Allowance Error: ${err.message}`);
        }
    }

    async getAllowanceWithoutConversion(walletAddr, contractAddr) {
        try {
            return await this.readContract.allowance(walletAddr, contractAddr).call();
        } catch (err) {
            console.error(`Token Contract Allowance Error: ${err.message}`);
        }
    }


    async approveAllowance(contractAddr) {
        try {
            return await this.writeContract.approve(contractAddr, MAX_ALLOWANCE_VALUE).send({
                feeLimit: 100000000
            });
        } catch (err) {
            let message = err;
            if (err?.message) message = err.message;
            console.error(`Token Contract Approve Allowance Error: ${message}`);
        }
    }
}

export default MosTokenService;