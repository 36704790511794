import React from 'react';
import './Copy.css';

const Copy = (props) => {
    return (
        <div
            className="copy-component-container"
            style={props.containerStyle}
            onClick={props.onClick}
        >
            <span
                className={`font-sf-pro copy-component-title ${props.className}`}
                style={props.titleStyle}>
                {props.title}
            </span>
        </div>
    )
}

export default Copy;