import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shortenPriceObj, formatNumber } from '../../../utils/Utils';
import ReactTooltip from 'react-tooltip';

import './LandingTable.css';

const SHORTEN_VALUE = 99999;

const formatValue = (val, dec) => {
    let decimal = dec ?? 2;
    let shortenPrice = shortenPriceObj(val);
    if (val > SHORTEN_VALUE) {
        let value = shortenPrice.price.toFixed(decimal);
        let suffix = shortenPrice.suffix;
        return `${value}${suffix}`;
    } else {
        return formatNumber(Number(val).toFixed(decimal));
    }
}

const LandingTableWeb = (props) => {
    const { t } = useTranslation();
    const [dailyData, setDailyData] = useState([]);

    useEffect(() => {
        if (props.dailyData) {
            let data = [].concat(props.dailyData).reverse();
            setDailyData(data);
        }
    }, [props.dailyData]);

    return (
        <div className="outer-content-wrapper landing-table-container">
            <div className="content-wrapper ">
                <ReactTooltip id='landing-table__gain' aria-haspopup='true' overridePosition={({ left, top },
                    currentEvent, currentTarget, node) => {
                    const d = document.documentElement;
                    left = Math.min(d.clientWidth - node.clientWidth, left);
                    top = Math.min(d.clientHeight - node.clientHeight, top);
                    left = Math.max(0, left);
                    top = Math.max(0, top);
                    return { top, left }
                }}>
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ borderBottom: '1px solid #F3B64E' }}>DayPayout Total</div>
                        <div>Staked MOS</div>
                    </div>

                </ReactTooltip>

                <ReactTooltip id='landing-table__apy' aria-haspopup='true' overridePosition={({ left, top },
                    currentEvent, currentTarget, node) => {
                    const d = document.documentElement;
                    left = Math.min(d.clientWidth - node.clientWidth, left);
                    top = Math.min(d.clientHeight - node.clientHeight, top);
                    left = Math.max(0, left);
                    top = Math.max(0, top);
                    return { top, left }
                }}>
                    <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center' }}>
                        <div>
                            <div style={{ borderBottom: '1px solid #F3B64E' }}>DayPayout Total</div>
                            <div>Staked MOS</div>
                        </div>
                        <div style={{ margin: '0 4px' }}>x</div>
                        <div>
                            <div style={{ borderBottom: '1px solid #F3B64E' }}>365</div>
                            <div>1</div>
                        </div>
                    </div>
                </ReactTooltip>

                <div className="landing-table-web font-sf-pro">
                    <div style={{ borderLeft: 'unset', color: '#F3B64E' }} className="landing-table-web-header"><span>Day</span></div>
                    <div className="landing-table-web-header"><span>Daily Payout Total</span></div>
                    <div className="landing-table-web-header"><span>M-Shares Pool</span></div>
                    <div className="landing-table-web-header"><span>Payout per M-Share</span></div>
                    <div className="landing-table-web-header"><span>% Gain <span data-tip data-for='landing-table__gain' className="landing-table-question-mark">?</span></span></div>
                    <div className="landing-table-web-header"><span>% APY <span data-tip data-for='landing-table__apy' className="landing-table-question-mark">?</span></span></div>

                    {dailyData.length > 0 && dailyData.map((day, index) => {
                        let payout = day.payoutPerMShare * (10 ** 4);
                        return (
                            <div style={{ display: 'contents' }} key={`ltw-day${day.day}`}>
                                <div style={{ borderLeft: 'unset' }} className="landing-table-web-data"><span>{day.day}</span></div>
                                <div className="landing-table-web-data"><span>{formatValue(day.payoutTotal)}</span></div>
                                <div className="landing-table-web-data"><span>{formatValue(day.stakeSharesTotal)}</span></div>
                                <div className="landing-table-web-data"><span>{formatValue(payout, 8)}</span></div>
                                <div className="landing-table-web-data"><span>{formatValue(day.gain, 8)}</span></div>
                                <div className="landing-table-web-data"><span>{formatValue(day.apy, 8)}</span></div>
                            </div>
                        )
                    })}
                </div>

                {/* {dailyData.length === 0 &&
                    <div style={{ color: 'grey', textAlign: 'center', borderTop: 'thin solid grey', padding: '6px 0px' }}>
                        None
                    </div>
                } */}
            </div>
        </div>
    )
}

export default LandingTableWeb;

