import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import './LoadingModal.css';
import SpinnerLoading from '../Spinner/Spinner';

function LoadingModal(props) {

  return (
    <Modal
        className="loader-container"
        show={props.show}
        onHide={_ => {}}
        // dialogClassName={{borderWidth: 0}}
        centered>
            <SpinnerLoading
                {...props}
            />
      </Modal>
  );
}

const mapStateToProps = (store) => ({
});

export default connect(
  mapStateToProps,
  null
)(LoadingModal);
