import React, { useEffect, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { connect } from 'react-redux';

import Actions from '../../actions';
import { SRT_RATE } from '../../constant';
import { useMediaQuery } from '../../customHooks/useMediaQuery';
import TokenService from '../../services/TokenService';
import { formatNumber, numberFormatOwnDecimal, priceFormat, priceFormatOwnDecimal, shortenPriceObj } from '../../utils/Utils';


const SHORTEN_VALUE = 999999;
const formatValue = (val) => {
    let shortenPrice = shortenPriceObj(val);
    if (val > SHORTEN_VALUE) {
        let value = shortenPrice.price.toFixed(2);
        let suffix = shortenPrice.suffix;
        return `${value}${suffix}`;
    } else {
        return formatNumber(val.toFixed(0));
    }
}

const TitleDescValueView = (props) => {
    let isMobile = useMediaQuery();
    let tokenService;
    const [claimAmount, setClaimAmount] = useState(0);
    const [unclaimAmount, setUnClaimAmount] = useState(0);
    const [value] = useState(SRT_RATE);
    const [chartData, setChartData] = useState([]);
    const [total, setTotal] = useState('');

    useEffect(() => {
        // get claim amount, SRT amount
        // get unclaim amount, SRT amount
        // get value for MOS and SRT?
        const { data } = props.getClaimInfoData;
        if (data) {
            tokenService = new TokenService();
            let total = Number(data.total.toString()) / 10 ** 8;
            let totalClaim = Number(data.totalClaim.toString()) / 10 ** tokenService.decimal;
            let totalUnclaim = total - totalClaim;
            setClaimAmount(totalClaim)
            setUnClaimAmount(totalUnclaim < 0 ? 0 : totalUnclaim);
        }
    }, [props.getClaimInfoData])

    useEffect(() => {
        let chartData = [
            { title: 'Claim', value: claimAmount, color: '#176EBF', key: 'claim-tdvv-claimAmount' },
            { title: 'Unclaim', value: unclaimAmount, color: '#00BDFF', key: 'claim-tdvv-unclaimAmount' },
        ]
        setChartData(chartData);
        setTotal(formatValue(claimAmount + unclaimAmount));
    }, [claimAmount, unclaimAmount])

    const TitleValueView = (props) => {
        return (
            <div className="tdvv__item-container" style={props.containerStyle}>
                <div className="tdvv__item-title-container">
                    {(props.showDot ?? true) &&
                        <div className="ttdv__item-dot" />
                    }
                    <div className="ttdv__item-title font-lora">{props.title}</div>
                </div>
                <div className="tdvv__item-amount">{props.amount}</div>
                <div className="tdvv__item-price">{props.price}</div>
            </div>
        )
    }

    const ValueCircleView = (props) => {
        return (
            // <div className="tdvv__circle-background tdvv__blue-circle-background">
            //     <div className="tdvv__circle-title">Total</div>
            //     <div className="tdvv__circle-value">250B</div>
            // </div>
            <PieChart
                data={chartData}
                lineWidth={4}
                paddingAngle={12}
                rounded
                animate
                label={({ x, y, dx, dy }) => {
                    return (
                        <>
                            <text
                                x={x}
                                y={34}
                                dx={dx}
                                dy={dy}
                                dominantBaseline="central"
                                textAnchor="middle"
                                className='tdvv__circle-title'>
                                {`Total`}
                            </text>
                            <text
                                x={x}
                                y={54}
                                dx={dx}
                                dy={dy}
                                dominantBaseline="central"
                                textAnchor="middle"
                                className="tdvv__circle-value">
                                {total}
                            </text>
                        </>
                    );
                }}
                labelPosition={0}
            />
        )
    }
    // .split('\n').map((line, i) => (
    //     <span key={i}>
    //         {line}
    //         <br />
    //     </span>
    // ))
    return (
        <div className="outer-content-wrapper tdvv__container">
            {isMobile ?
                <React.Fragment>
                    <div className="tdvv__right-contaner">
                        <ValueCircleView />
                    </div>
                    <div className="tdvv__title font-lora">Presale SRT Token Swap</div>
                    <div className="tdvv__desc"><br />Swap your SRT for MOS.<br /><br />Senatus Round launched in May 2021, and everyone who held SRT can exchange it for MOS now.<br /><br />If you happen to have any remaining, please exchange them below for $MOS before Day 7 to avoid the late claim penalty.</div>
                    <TitleValueView
                        title={'Claimed SRT Worldwide'}
                        amount={numberFormatOwnDecimal(claimAmount)}
                        price={`≈ $ ${priceFormatOwnDecimal(claimAmount * value)}`}
                        containerStyle={{ marginTop: '10vw' }}
                    />
                    <TitleValueView
                        title={'Unclaim SRT Worldwide'}
                        amount={numberFormatOwnDecimal(unclaimAmount)}
                        price={`≈ $ ${priceFormatOwnDecimal(unclaimAmount * value)}`}
                        containerStyle={{ borderBottom: '0px' }}
                    />
                </React.Fragment>
                :
                <React.Fragment>
                    <div className="tdvv__left-container">
                        <div>
                            <div className="tdvv__title font-lora">Presale SRT Token Swap</div>
                            <div className="tdvv__desc">Swap your SRT for MOS.<br /><br />Senatus Round launched in May 2021, and everyone who held<br /> SRT can exchange it for MOS now.<br /><br />If you happen to have any remaining, please exchange<br />them below for $MOS before Day 7 to avoid the late claim penalty.</div>
                        </div>
                        <div className="ttdv__item">
                            <TitleValueView
                                title={'Claimed SRT Worldwide'}
                                amount={numberFormatOwnDecimal(claimAmount)}
                                price={`≈ $ ${priceFormatOwnDecimal(claimAmount * value)}`}
                            />
                            <TitleValueView
                                title={'Unclaim SRT Worldwide'}
                                amount={numberFormatOwnDecimal(unclaimAmount)}
                                price={`≈ $ ${priceFormatOwnDecimal(unclaimAmount * value)}`}
                                containerStyle={{ borderBottom: '0px' }}
                            />
                        </div>
                    </div>
                    <div className="tdvv__right-contaner">
                        <ValueCircleView />
                    </div>
                </React.Fragment>
            }

        </div>
    )
}


const mapStateToProps = store => ({
    getWalletAddressData: Actions.getWalletAddressData(store),
    getClaimInfoData: Actions.getClaimInfoData(store),
});

const mapDispatchToProps = {
    onClaimInfo: Actions.claimInfo,
};


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TitleDescValueView);