
import AppContainer from '../../components/AppContainer/AppContainer';
import './Claim.css'
import ClaimInput from './ClaimInput';
import TitleDescValueView from './TitleDescValueView';
 
const Claim = (props) => {
    return (
        <AppContainer>
            <TitleDescValueView />
            <ClaimInput />
        </AppContainer>
    )
}

export default Claim;