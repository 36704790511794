
export function convertToDecimal(value) {
    return window.tronWeb?.toDecimal(value);
}

export function convertFromHex(value) {
    return window.tronWeb?.address.fromHex(value);
}

export function power(exponent) {
    return Math.pow(10, exponent);
}

export function getShorterAddress(text) {
    if (!text || text == null || text.length === 0) return '';
    let totalLength = text.length;
    return text.substring(0, 4) + '...' + text.substring(totalLength - 4, totalLength);
}

export function shortenPriceObj(price) {
    let suffix = '';
    if (Number(price) >= 1.0e+9) {
        price = (Number(price) / 1.0e+9);
        suffix = "B";
    } else if (Number(price) >= 1.0e+6) {
        price = (Number(price) / 1.0e+6);
        suffix = "M";
    } else if (Number(price) >= 1.0e+3) {
        price = (Number(price) / 1.0e+3);
        suffix = "K";
    } else {
        price = price;
    }
    return { price, suffix }
}

export function formatNumber(strValue) {
    let returnResult = strValue;
    if (strValue.indexOf('.') !== -1) {
        let numbersToFormat = strValue.split('.')[0];
        let decimals = strValue.split('.')[1];
        let result = numbersToFormat.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        returnResult = result.concat('.', decimals);
    } else {
        returnResult = strValue.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    }
    return returnResult;
};


export function priceFormat(price, decPlaces = 2, thouSeparator = ',', decSeparator = '.') {
    if (price === null || price === '' || isNaN(price)) price = 0;
    var n = Number(price),
        decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
        decSeparator = decSeparator == undefined ? "." : decSeparator,
        thouSeparator = thouSeparator == undefined ? "," : thouSeparator,
        sign = n < 0 ? "-" : "",
        i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;
    return sign + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
};

export function numberFormatOwnDecimal(price, defaultDecimal = 0) {
    if (price === null || price === '' || isNaN(price)) price = 0;
    let decPlaces = defaultDecimal;
    try {
        decPlaces = price.toString().split('.')[1].length;
    } catch {
        decPlaces = defaultDecimal;
    }
    return priceFormat(price, decPlaces);
}

export function priceFormatOwnDecimal(price) {
    return numberFormatOwnDecimal(price, 2);
}