import Actions from '../../actions';

const getDefaultState = () => ({ data: null });

function claimInfo(state, action) {
    if (typeof state === 'undefined') {
        return getDefaultState();
    }

    switch (action.type) {
        case Actions.CLAIM_INFO:
            return { data: action.data };
        case Actions.RESET_CLAIM_INFO:
            return getDefaultState();
        default:
            return state;
    }
}

export default claimInfo;
