import {
    PRODUCTION,
    DEVELOPMENT,
    MAINNET,
    TESTNET,

    TRONGRID_API,
    TRON_STACK_API,
    NILE_API,
    SHASTA_API,

    MAINNET_CHAIN_ID,
    TESTNET_CHAIN_ID,
    MOS_TOKEN_MAINNET,
    MOS_PLATFORM_MAINNET,
    MOS_TOKEN_TESTNET,
    MOS_PLATFORM_TESTNET,
    USDT_TOKEN_ADDRESS_MAINNET,
    USDJ_TOKEN_ADDRESS_TESTNET,
    SRT_TOKEN_ADDRESS_MAINNET,
    SRT_TOKEN_ADDRESS_TESTNET,

    // MOS_ADDRESS_TESTNET,
    // MOS_ADDRESS_MAINNET,
    NETWORK_API_ARR_MAINNET,
    NETWORK_API_ARR_TESTNET,
} from './constant';

// export const ENV = PRODUCTION;
export const ENV = DEVELOPMENT;

const config = {
    [PRODUCTION]: {
        NETWORK: MAINNET,
        // NETWORK_API: TRONGRID_API,
        NETWORK_API: NETWORK_API_ARR_MAINNET,
        READ_API: TRON_STACK_API,
        CHAIN_ID: MAINNET_CHAIN_ID,
        MOS_TOKEN_CONTRACT_ADDRESS: MOS_TOKEN_MAINNET,
        MOS_PLATFORM_CONTRACT_ADDRESS: MOS_PLATFORM_MAINNET,
        // MOS_CONTRACT_ADDRESS: MOS_ADDRESS_MAINNET,

        USDT_TOKEN_ADDRESS: USDT_TOKEN_ADDRESS_MAINNET,
        SRT_TOKEN_ADDRESS: SRT_TOKEN_ADDRESS_MAINNET,
    },
    [DEVELOPMENT]: {
        NETWORK: TESTNET,
        // NETWORK_API: SHASTA_API,
        NETWORK_API: NETWORK_API_ARR_TESTNET,
        READ_API: SHASTA_API,
        CHAIN_ID: TESTNET_CHAIN_ID,
        MOS_TOKEN_CONTRACT_ADDRESS: MOS_TOKEN_TESTNET,
        MOS_PLATFORM_CONTRACT_ADDRESS: MOS_PLATFORM_TESTNET,
        // MOS_CONTRACT_ADDRESS: MOS_ADDRESS_TESTNET,

        USDT_TOKEN_ADDRESS: USDJ_TOKEN_ADDRESS_TESTNET,
        SRT_TOKEN_ADDRESS: SRT_TOKEN_ADDRESS_TESTNET,
    },
};

export let {
    NETWORK,
    NETWORK_API,
    READ_API,
    CHAIN_ID,
    MOS_TOKEN_CONTRACT_ADDRESS,
    MOS_PLATFORM_CONTRACT_ADDRESS,
    // MOS_CONTRACT_ADDRESS,

    USDT_TOKEN_ADDRESS,
    SRT_TOKEN_ADDRESS,
} = config[ENV];