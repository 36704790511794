import React from 'react';
import { useTranslation } from 'react-i18next';
import auctionLogo from '../../assets/images/auction/auction-coin-stack.svg';
import { formatNumber, shortenPriceObj } from '../../utils/Utils';

const SHORTEN_VALUE = 999999;

const AuctionPoolView = (props) => {
    const { t } = useTranslation();

    const formatValue = (val) => {
        let shortenPrice = shortenPriceObj(val);
        if (val > SHORTEN_VALUE) {
            let value = shortenPrice.price.toFixed();
            let suffix = shortenPrice.suffix;
            return `${value}${suffix}`;
        } else {
            return formatNumber(val.toFixed());
        }
    }

    return (
        <div className="outer-content-wrapper auction-top-container">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span className="font-lora auction-top-title">{t('auction pool')}</span>
                <span className="font-sf-pro auction-top-subtitle">{t('auction pool subtitle')}</span>
            </div>
            <div className="content-wrapper auction-top-logo-container">
                <img src={auctionLogo} className="auction-logo" />
                <div className="auction-rate-container">
                    <span className="font-lora auction-rate-title">{t("auction rate")}</span>
                    <span className="font-sf-pro auction-top-subtitle">{formatValue(props.auctionRate)} MOS {t("per")} USDT</span>
                    <span className="font-sf-pro" style={{ fontSize: '12px' }}>{t('auction rate subtitle')}</span>
                </div>
            </div>
        </div>
    )
}

export default AuctionPoolView;