import React from 'react';
import { useMediaQuery } from '../../customHooks/useMediaQuery';

import MobileHeader from './MobileHeader';
import WebHeader from './WebHeader';

const Header = (props) => {
    let isMobile = useMediaQuery();

    return isMobile ?
        (<MobileHeader />)
        :
        (<WebHeader />)

}

export default Header;