import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineArrowDown } from 'react-icons/ai';
import { useParams } from 'react-router';
import { connect } from 'react-redux';

import { useMediaQuery } from '../../customHooks/useMediaQuery';
import Button from '../../components/Button/Button';
import usdtLogo from '../../assets/images/auction/usdt-logo.svg';
import mosLogo from '../../assets/images/mos-coin.svg';
import { formatNumber, shortenPriceObj } from '../../utils/Utils';
import Actions from '../../actions';

const SHORTEN_VALUE = 999999;

const formatValue = (val) => {
    let shortenPrice = shortenPriceObj(val);
    if (val > SHORTEN_VALUE) {
        let value = shortenPrice.price.toFixed(6);
        let suffix = shortenPrice.suffix;
        return `${value}${suffix}`;
    } else {
        return formatNumber(val.toFixed(6));
    }
}

const AuctionBidView = (props) => {
    const { t } = useTranslation();
    let isMobile = useMediaQuery();
    const { refCode } = useParams();

    const [referralInput, setReferralInput] = useState("");
    const [showRefCodeWarning, setShowRefCodeWarning] = useState(false);

    useEffect(() => {
        if (refCode) setReferralInput(refCode);
    }, [refCode]);

    useEffect(() => {
        if (props.getWalletAddressData.data && referralInput.trim() === props.getWalletAddressData.data) {
            setShowRefCodeWarning(true);
        } else {
            setShowRefCodeWarning(false);
        }
    }, [referralInput, props.getWalletAddressData])

    const handleChange = (e) => {
        props.setValue(e.target.value)
    }

    const onMaxPress = () => {
        if (props.usdtBalance) {
            props.setValue(props.usdtBalance);
        } else {
            props.setValue('');
        }
    }

    return (
        <div style={{ display: 'flex', marginTop: '40px' }}>
            <div className="outer-content-wrapper auction-bid-container">
                <span className="font-lora auction-top-title">{t('bid in auction')}</span>

                <div style={{ display: 'flex', flex: 1, color: 'white', paddingTop: '12px' }}>
                    {/* <span className="font-sf-pro">1 USDT = {props.auctionRate === 0 ? 'N/A' : formatValue(props.auctionRate)} MOS</span> */}
                    <span className="font-sf-pro auction-usdt-balance" style={{ marginLeft: 'auto' }}>USDT Balance: {props.usdtBalance ? formatNumber(props.usdtBalance) : '0'}</span>
                </div>
                {/* <span className="font-sf-pro auction-usdt-balance">USDT Balance: {props.usdtBalance ? formatNumber(props.usdtBalance) : '0'}</span> */}
                <div className="bid-grid">
                    <div className="content-wrapper bid-value-container" style={{ gridArea: ' 1 / 1 / 3 / 3' }}>
                        <div style={{ display: 'flex' }}>
                            <img src={usdtLogo} className="bid-coin-logo" />
                            <div className="bid-value-wrapper">
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        className="font-sf-pro auction-deposit-input"
                                        value={props.value}
                                        placeholder={"Amount"}
                                        onChange={handleChange}
                                    />
                                    <div className="font-sf-pro auction-input-max" onClick={() => onMaxPress()}>MAX</div>
                                </div>
                                {/* <span className="font-lora auction-rate-sub">-$236,785</span> */}
                            </div>
                        </div>
                    </div>

                    <div className="content-wrapper bid-arrow-circle" style={{ gridArea: ' 2 / 1 / 4 / 3' }}>
                        {/* <img src={arrow} className="bid-arrow" /> */}
                        <AiOutlineArrowDown className="bid-arrow" />
                    </div>

                    <div className="content-wrapper bid-value-mos-container" style={{ gridArea: ' 3 / 1 / 5 / 3' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={mosLogo} className="bid-coin-logo" />
                            <div className="bid-value-wrapper">
                                <span className="font-sf-pro auction-rate-value">{formatValue(props.auctionRate * Number(props.value))}</span>
                                {/* <span className="font-lora auction-rate-sub">-$236,785  <span style={{ color: '#898989' }}>(-0.0181%)</span></span> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bid-referral-wrapper font-sf-pro" style={{ paddingTop: '8px 0' }}>
                    <span>Referral</span>
                    <input
                        value={referralInput}
                        onChange={(event) => setReferralInput(event.target.value)}
                        placeholder={"Please enter your referral code"}
                    />
                </div>
                {showRefCodeWarning && <span className="bid-referral-warning">Note: You will not be entitled to referral rewards by using your own wallet address</span>}

                <span className="font-sf-pro bid-footnote">1 USDT = {props.auctionRate === 0 ? 'N/A' : formatValue(props.auctionRate)} MOS</span>
                <Button title={t('enter bid')} onClick={() => props.onEnterBid(referralInput)} />
                {/* <Button title={"Increment Day"} onClick={props.triggerNextDay} /> */}
            </div>
            {
                !isMobile &&
                <div className="content-wrapper bid-side-picture" />
            }
        </div >
    )
}

const mapStateToProps = store => ({
    getWalletAddressData: Actions.getWalletAddressData(store),
});

const mapDispatchToProps = {
};


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AuctionBidView);