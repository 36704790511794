import axios from 'axios';

export const getUrl = endpoint => {
    return 'https://api.themos.io' + endpoint;

};


export const fetchApi = (method, endpoint, params) =>
    axios({
        method,
        url: getUrl(endpoint),
        data: params,
    })
        .then(response => ({ response }))
        .catch(error => ({ error }));
