import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { formatNumber } from '../../../utils/Utils';

const MosInfoMobile = (props) => {
    const { t } = useTranslation();

    return (
        <div className="outer-content-wrapper landing-mos-info-container" style={{ color: 'white' }}>
            <ReactTooltip id='mos-info-web-share-rate' aria-haspopup='true' role='example'>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div>{t('share rate')} = </div>
                    <div style={{ textAlign: 'center', paddingLeft: '6px' }}>
                        <div style={{ borderBottom: '1px solid #F3B64E' }}> Total Asset Staked</div>
                        <div>Total Share</div>
                    </div>
                </div>
            </ReactTooltip>

            {/* <div className="landing-mos-info-row-mobile">
                <div className="landing-mos-info-row-mobile-cell">
                    <span className="font-lora landing-mos-info-mobile-title">{t('crypto rank')}</span>
                    <span  className="font-sf-pro landing-mos-info-mobile-value">#745</span>
                </div>
                <div className="vertical-rule" />
                <div className="landing-mos-info-row-mobile-cell">
                    <span className="font-lora landing-mos-info-mobile-title">{t('total stake')}</span>
                    <span  className="font-sf-pro landing-mos-info-mobile-value">#745</span>
                </div>
            </div>
            <div className="horizontal-rule" /> */}

            {/* <div className="landing-mos-info-row-mobile">
                <div className="landing-mos-info-row-mobile-cell">
                    <span className="font-lora landing-mos-info-mobile-title">{t('average stake size').replace('Average', 'Avg.')}</span>
                    <span  className="font-sf-pro landing-mos-info-mobile-value">#745</span>
                </div>
                <div className="vertical-rule" />

                <div className="landing-mos-info-row-mobile-cell">
                    <span className="font-lora landing-mos-info-mobile-title">{t('average stake length').replace('Average', 'Avg.')}</span>
                    <span  className="font-sf-pro landing-mos-info-mobile-value">#745</span>
                </div>
            </div>
            <div className="horizontal-rule" /> */}

            <div className="landing-mos-info-row-mobile">
                {/* <div className="landing-mos-info-row-mobile-cell">
                    <span className="font-lora landing-mos-info-mobile-title">{t('market cap')}</span>
                    <span  className="font-sf-pro landing-mos-info-mobile-value">#745</span>
                </div> */}
                <div className="landing-mos-info-row-mobile-cell">
                    <span className="font-lora landing-mos-info-mobile-title">{t('total stake')}</span>
                    <span className="font-sf-pro landing-mos-info-mobile-value">{props.globalInfo.totalStakes ? formatNumber(props.globalInfo.totalStakes) : '0'}</span>
                </div>
                <div className="vertical-rule" />

                <div className="landing-mos-info-row-mobile-cell">
                    <span className="font-lora landing-mos-info-mobile-title">% {t('staked')}</span>
                    <span className="font-sf-pro landing-mos-info-mobile-value">{props.globalInfo ? props.globalInfo.percentStaked : '0'}%</span>
                </div>
            </div>
            <div className="horizontal-rule" />

            <div className="landing-mos-info-row-mobile">
                <div className="landing-mos-info-row-mobile-cell">
                    <span className="font-lora landing-mos-info-mobile-title" style={{ display: 'flex', alignItems: 'center' }}>{t('share rate')} <a data-tip data-for='mos-info-web-share-rate' className="mos-info-question-mark">?</a></span>
                    <span className="font-sf-pro landing-mos-info-mobile-value">{props.globalInfo ? props.globalInfo.shareRate : '0'}</span>
                </div>
                <div className="vertical-rule" />

                <div className="landing-mos-info-row-mobile-cell">
                    <span className="font-lora landing-mos-info-mobile-title">Average Stake Length</span>
                    <span className="font-sf-pro landing-mos-info-mobile-value">{props.averageStakeInfo ? props.averageStakeInfo["AVG(stakeDays)"] : '0'} Days</span>
                </div>
            </div>
        </div>

    )
}

export default MosInfoMobile;