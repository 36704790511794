export async function getAddress() {
    try {
        return await window.tronWeb.defaultAddress.base58;
    } catch (err) {
        throw new Error(`Get Address Error: ${err}`)
    }
}

export async function connectContract(contractAddr) {
    try {
        return await window.tronWeb.contract().at(contractAddr);
    } catch (err) {
        throw new Error(`Membership Connect Contract Error: ${err}`);
    }
}

export async function getBalance(address = getAddress()) {
    try {
        return await window.tronWeb.trx.getBalance(address)
    } catch (err) {
        throw new Error(`Get Trx Balance Error: ${err.message}`)
    }
}

export async function _unconfirmedTrxInfo(trx) {
    try {
        return await window.tronWeb.trx.getUnconfirmedTransactionInfo(trx);
    } catch (err) {
        throw new Error(err.message)
    }
}

export async function checkTransaction(tx) {
    let res = await window.tronWeb.trx.getTransaction(tx);
    console.log("check transaction res: ", res);
    try {
        if (res.ret[0]?.contractRet === 'SUCCESS') {
            return true
        }
    } catch (e) {
        console.log(e);
        return false;
    }
    return false;
}

// export async function checkTransactionInfo(tx) {
//     let res = await window.tronWeb.trx.getTransactionInfo(tx);
//     console.log("checkTransactionInfo: ", res);
//     try {
//         if (res.receipt.result === 'SUCCESS') {
//             return true
//         }
//     } catch (e) {
//         console.log("check transaction info error: ", e)
//         return false;
//     }
//     return false;
// }