import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shortenPriceObj, formatNumber } from '../../../utils/Utils';

const SHORTEN_VALUE = 99999;

const formatValue = (val, dec) => {
    let decimal = dec ?? 2;
    let shortenPrice = shortenPriceObj(val);
    if (val > SHORTEN_VALUE) {
        let value = shortenPrice.price.toFixed(decimal);
        let suffix = shortenPrice.suffix;
        return `${value}${suffix}`;
    } else {
        return formatNumber(Number(val).toFixed(decimal));
    }
}

const LandingTableMobile = (props) => {
    const { t } = useTranslation();
    const [dailyData, setDailyData] = useState([]);

    useEffect(() => {
        if (props.dailyData) {
            let data = [].concat(props.dailyData).reverse();
            setDailyData(data);
        }
    }, [props.dailyData]);

    return (
        <div className="outer-content-wrapper">
            <div style={{ padding: "12px 0px" }}>
                <div><span className="font-sf-pro landing-table-title">Day</span></div>
                {dailyData.length > 0 && dailyData.map((day, index) => {
                    let payout = day.payoutPerMShare * (10 ** 4);
                    return (
                        <div className="landing-table-mobile font-sf-pro" key={`ltm-day${day.day}`}>
                            <div style={{ gridArea: '1 / 1 / 5 / 2' }} className="landing-table-cells-1"><span>{day.day}</span></div>
                            <div style={{ gridArea: '1 / 2 / 3 / 3' }} className="landing-table-mobile-cells-upper-row"><span>Daily Payout Total</span><span>{formatValue(day.payoutTotal)}</span></div>
                            <div style={{ gridArea: '1 / 3 / 3 / 4' }} className="landing-table-mobile-cells-upper-row"><span>M-Shares Pool</span><span>{formatValue(day.stakeSharesTotal)}</span></div>
                            <div style={{ gridArea: '1 / 4 / 3 / 5' }} className="landing-table-mobile-cells-upper-row"><span>Payout per M-Share</span><span>{formatValue(payout, 4)}</span></div>
                            {/* <div style={{ gridArea: '3 / 2 / 5 / 5', flexDirection: 'row', padding: '0px' }} className="landing-table-mobile-cells-lower-row">
                                <div style={{ padding: '5px', width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>% Gain<div>{formatValue(day.gain, 6)}</div></div>
                                <div className="ltm-vertical-divider" />
                                <div style={{ padding: '5px', width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>% APY<div>{formatValue(day.apy, 6)}</div></div>
                            </div> */}
                            <div style={{ gridArea: '3 / 2 / 5 / 3' }} className="landing-table-mobile-cells-lower-row"><span>% Gain</span><span>{formatValue(day.gain, 6)}</span></div>
                            <div style={{ gridArea: '3 / 3 / 5 / 4' }} className="landing-table-mobile-cells-lower-row"><span>% APY</span><span>{formatValue(day.apy, 6)}</span></div>
                            <div style={{ gridArea: '3 / 4 / 5 / 5' }} className="landing-table-mobile-cells-lower-row"></div>
                        </div>
                    )
                })}
            </div>

        </div>
    )
}

export default LandingTableMobile;

