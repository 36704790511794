import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import useAsyncEffect from 'use-async-effect';
import { Decimal } from 'decimal.js';

import Actions from '../../actions';
import './Refer.css';
import { ellipsis } from '../../utils/Utils';
import { useMediaQuery } from '../../customHooks/useMediaQuery';
import AppContainer from '../../components/AppContainer/AppContainer';
import Copy from '../../components/Copy/Copy';
import MosLogo from '../../assets/images/refer/refer-logo.svg';
import Button from '../../components/Button/Button';
import MosPlatformService from '../../services/MosPlatformService';
import { formatNumber, shortenPriceObj } from '../../utils/Utils';
import { getReferralList } from '../../api';
import { USDT_DECIMALS } from '../../constant';
import MosTokenService from '../../services/MosTokenService';
import { MOS_TOKEN_CONTRACT_ADDRESS } from '../../config';

const SHORTEN_VALUE = 999999;
const MOS_DECIMAL = 8;

const formatValue = (val, decPoint) => {
    let shortenPrice = shortenPriceObj(val);
    let dec = decPoint ?? 4;
    if (val > SHORTEN_VALUE) {
        let value = shortenPrice.price.toFixed(2);
        let suffix = shortenPrice.suffix;
        return `${value}${suffix}`;
    } else {
        return formatNumber(Number(val).toFixed(dec));
    }
}

const ReferralLink = (props) => {
    const { t } = useTranslation();
    let isMobile = useMediaQuery();
    let totalValue = props.referralEarnings;
    let convertedTotalValue = new Decimal(props.referralEarnings).times(props.mosPrice).toFixed(2, Decimal.ROUND_DOWN);
    // let ellipsisLength = isMobile ? 20 : 40;
    return (
        <div style={!isMobile ? { display: 'flex' } : null}>
            <div className="refer-top-left-wrapper">
                <div className="outer-content-wrapper refer-link-container">
                    <span className="font-lora refer-link-title">{t('referral link')}</span>
                    <div className="refer-link-subtitle-div">
                        <span className="font-lora refer-link-subtitle">{t('referral announcement')}</span>
                    </div>
                    <div className="refer-referral-link-container">
                        <span className="font-sf-pro refer-referral-link-content">
                            {props.value.length > 0 ? `${window.location.origin}/auction/${props.value}` : t("connect wallet")}
                        </span>
                        <CopyToClipboard text={`${window.location.origin}/auction/${props.value}`}
                            onCopy={() => { alert("copied") }}>
                            <Copy title={t('copy')} style={{ marginLeft: 'auto' }} />
                        </CopyToClipboard>
                    </div>
                </div>
                {/* <div style={{ display: 'flex', marginTop: '30px' }}> */}
                <div className="outer-content-wrapper refer-earn-container">
                    <span className="font-lora refer-earn-subtitle" style={{ color: '#898989' }}>{t('your referral earnings')}</span>
                    <span className="font-sf-pro refer-total-value">{formatValue(totalValue)}</span>
                    <span className="font-sf-pro refer-earning-value">≈ $ {formatValue(convertedTotalValue, 2)}</span>
                </div>
                {/* </div> */}
            </div>
            {!isMobile &&
                <div className="outer-content-wrapper refer-side-picture">
                    <img src={MosLogo} alt="logo" className="refer-logo" />
                </div>
            }
        </div>


    )
}

const ReferTable = (props) => {
    const { t } = useTranslation();
    let isMobile = useMediaQuery();
    let data = props.referralRecord;

    return isMobile ?
        (
            <div>
                <div>
                    <span className="font-lora refer-link-title" style={{ color: '#f3b64e' }}>{t('referral history')}</span>
                </div>
                <div className="content-wrapper" style={{ padding: '6px', marginTop: '10px' }}>
                    <div><span className="font-sf-pro refer-table-title">{t('day')}</span></div>

                    {data.length > 0 && data.map((data, index) => {
                        let referAmount = new Decimal(data.amount).dividedBy(10 ** MOS_DECIMAL).toDP(2, Decimal.ROUND_DOWN);

                        return (
                            <div className="refer-table-mobile font-sf-pro" key={`${data.downline_address}.${data.amount}`}>
                                <div style={{ gridArea: '1 / 1 / 4 / 2' }} className="refer-table-cells-1"><span>{data.day + 1}</span></div>
                                <div style={{ gridArea: '1 / 2 / 2 / 3' }} className="refer-table-mobile-cells"><span>{t('referring TRON address')}</span><span className="refer-table-address">{data.downline_address}</span></div>
                                <div style={{ gridArea: '2 / 2 / 3 / 3' }} className="refer-table-mobile-cells"><span>{t('details')}</span><span>Done</span></div>
                                <div style={{ gridArea: '3 / 2 / 4 / 3' }} className="refer-table-mobile-cells"><span>{t('awarded MOS')}</span><span>{formatValue(referAmount.toString(), 2)}</span></div>
                            </div>
                        )
                    })}

                    {data.length === 0 &&
                        <div style={{ gridArea: 'auto/1/auto/15', color: 'grey', textAlign: 'center', padding: '6px 0px', borderTop: '1px solid rgba(137,137,137,0.6)' }}>
                            None
                        </div>
                    }

                </div>
            </div>
        ) :
        (
            <div>
                <div>
                    <span className="font-lora refer-link-title" style={{ color: '#f3b64e' }}>{t('referral history')}</span>
                </div>
                <div className="content-wrapper refer-table-web font-sf-pro ">
                    <div style={{ gridArea: '1 / 1 / 2 / 2', borderLeft: 'unset', color: '#F3B64E', display: 'flex', justifyContent: 'flex-end', paddingRight: '20px' }} className="refer-table-web-header"><span>{t('day')}</span></div>
                    <div style={{ gridArea: '1 / 2 / 2 / 3' }} className="refer-table-web-header"><span>{t('referring TRON address')}</span></div>
                    <div style={{ gridArea: '1 / 3 / 2 / 5' }} className="refer-table-web-header"><span>{t('details')}</span></div>
                    <div style={{ gridArea: '1 / 5 / 2 / 6' }} className="refer-table-web-header"><span>{t('awarded MOS')}</span></div>

                    {data.length > 0 && data.map((data, index) => {
                        let referAmount = new Decimal(data.amount).dividedBy(10 ** MOS_DECIMAL).toDP(2, Decimal.ROUND_DOWN);

                        return (
                            <div style={{ display: 'contents' }} key={`${data.downline_address}.${data.amount}`}>
                                <div style={{ gridArea: '2 / 1 / 3 / 2', borderLeft: 'unset', display: 'flex', justifyContent: 'flex-end', paddingRight: '20px' }} className="refer-table-web-data"><span>{data.day + 1}</span></div>
                                <div style={{ girdArea: '2 / 2 / 3 / 3' }} className="refer-table-web-data"><span>{data.downline_address}</span></div>
                                <div style={{ gridArea: '2 / 3 / 3 / 5' }} className="refer-table-web-data"><span>Done</span></div>
                                <div style={{ gridArea: '2 / 5 / 3 / 6' }} className="refer-table-web-data"><span>{formatValue(referAmount.toString(), 2)}</span></div>
                            </div>

                        )
                    })}
                    {data.length === 0 &&
                        <div style={{ gridArea: 'auto/1/auto/15', color: 'grey', textAlign: 'center', padding: '6px 0px', borderTop: '1px solid rgba(137,137,137,0.6)' }}>
                            None
                        </div>
                    }
                </div>
            </div>
        )
}

const Refer = (props) => {
    // let mosPlatformService = useRef();
    const { t } = useTranslation();
    const [walletAddress, setWalletAddress] = useState('');
    const [referralRecord, setReferralRecord] = useState([]);
    const [referralEarnings, setReferralEarnings] = useState(0);
    const [mosPrice, setMosPrice] = useState(0.0000);
    const [mosTokenService, setMosTokenService] = useState(null);
    const [mosPlatformService, setMosPlatformService] = useState(null);

    useAsyncEffect(async () => {
        setTimeout(async () => {
            await initServices();
        }, 3000);
    }, []);

    const initServices = async () => {
        let mosTokenService = new MosTokenService();
        await mosTokenService.connectContract(MOS_TOKEN_CONTRACT_ADDRESS);
        setMosTokenService(mosTokenService);

        let mosPlatformService = new MosPlatformService();
        await mosPlatformService.connectContract();
        setMosPlatformService(mosPlatformService);
    }

    useAsyncEffect(async () => {
        if (mosTokenService && mosPlatformService) {
            getMosPrice();
        }
    }, [mosTokenService, mosPlatformService]);

    const getMosPrice = () => {
        setTimeout(async () => {
            let currentDay = await mosPlatformService.getCurrentDay();
            let pool = await mosPlatformService.poolValue(currentDay - 1);
            let bid = await mosPlatformService.xfLobby(currentDay - 1);

            let mosPool = new Decimal(pool).dividedBy(10 ** MOS_DECIMAL);
            let usdtBid = new Decimal(bid).dividedBy(10 ** USDT_DECIMALS);
            if (currentDay !== 0) {
                let auctRate = mosPool.dividedBy(usdtBid);
                let price = new Decimal(1).dividedBy(auctRate).toFixed(5);
                setMosPrice(price);
            }
        }, 3000);
    }

    useAsyncEffect(async () => {
        if (props.getWalletAddressData.data) {
            setWalletAddress(props.getWalletAddressData.data);
            let referralHistory = await getReferralList(props.getWalletAddressData.data);
            // console.log("referral history: ", referralHistory);
            setReferralRecord(referralHistory);
        }
    }, [props.getWalletAddressData]);

    useEffect(() => {
        let referEarnings = new Decimal(0);
        for (let i = 0; i < referralRecord.length; i++) {
            let data = referralRecord[i];
            referEarnings = referEarnings.plus(data.amount);
        }
        setReferralEarnings(referEarnings.dividedBy(10 ** MOS_DECIMAL).toString());
    }, [referralRecord]);

    // useAsyncEffect(async () => {
    //     if (walletAddress && mosPlatformService) {
    //         let recordCount = await mosPlatformService.current.referralRecordCount(walletAddress);
    //         if (recordCount > 0) {
    //             let list = [];
    //             let referEarnings = new Decimal(0);
    //             for (let i = 0; i < recordCount; i++) {
    //                 let res = await mosPlatformService.current.referralRecord(walletAddress, i);
    //                 let data = {
    //                     amount: new Decimal(res.amount._hex).dividedBy(10 ** MOS_DECIMAL),
    //                     downline: window.tronWeb.address.fromHex(res.downline)
    //                 }
    //                 referEarnings = referEarnings.plus(data.amount);
    //                 list.push(data);
    //             }
    //             // console.log("ref record list: ", list);
    //             // console.log("ref earnings: ", referEarnings.toString());
    //             setReferralEarnings(referEarnings);
    //             setReferralRecord(list);
    //         }
    //     }
    // }, [walletAddress, mosPlatformService]);

    return (
        <AppContainer>
            <ReferralLink value={walletAddress} referralEarnings={referralEarnings} mosPrice={mosPrice} />
            <div className="outer-content-wrapper refer-bottom-container">
                <ReferTable referralRecord={referralRecord} />
            </div>
        </AppContainer>
    );
}

const mapStateToProps = store => ({
    getWalletAddressData: Actions.getWalletAddressData(store),
});

const mapDispatchToProps = {
};


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Refer);
