import { fetchApi } from "./helper";

export const getReferralList = async (uplineAddress) => {
    let getReferralList = await fetchApi('get', `/referrals/${uplineAddress}`);
    // console.log("Get Referral List: ", getReferralList);
    if (getReferralList.response) {
        return getReferralList.response.data;
    }
}

export const getStakesList = async (address) => {
    let getStakesList = await fetchApi('get', `/stakes/${address}`);
    // console.log("Get Stakes List: ", getStakesList);
    if (getStakesList.response) {
        return getStakesList.response.data;
    }
}

export const getAverageStakeLength = async () => {
    let getAverageStakeLength = await fetchApi('get', '/stakeAverage');
    // console.log("Get Average Stake Length: ", getAverageStakeLength);
    if (getAverageStakeLength.response) {
        return getAverageStakeLength.response.data;
    }
}

export const getShareRate = async () => {
    let getShareRate = await fetchApi('get', '/shareRate');
    // console.log("Get Share Rate: ", getShareRate);
    if (getShareRate.response) {
        return getShareRate.response.data;
    }
}

export const getStakeRewardCalculation = async (param) => {
    let getStakeRewardCalculation = await fetchApi('get', `/stakeReward/?day=${param.day}&amount=${param.amount}`);
    // console.log("Get Stake Calculation Rate: ", getStakeRewardCalculation);
    if (getStakeRewardCalculation.response) {
        return getStakeRewardCalculation.response.data;
    }
}

export const getGiftingBonus = async () => {
    let getGiftingBonus = await fetchApi('get', `/giftingBonus`);
    // console.log("Get Gifting Bonus: ", getGiftingBonus);
    if (getGiftingBonus.response) {
        return getGiftingBonus.response.data;
    }
}

export const getDailyData = async () => {
    let getDailyData = await fetchApi('get', `/dailyData`);
    // console.log("Get Daily Data: ", getDailyData);
    if (getDailyData.response) {
        return getDailyData.response.data;
    }
}

export const getDailyPrice = async () => {
    let getDailyPrice = await fetchApi('get', `/dailyPrice`);
    // console.log("Get Daily Price: ", getDailyPrice);
    if (getDailyPrice.response) {
        return getDailyPrice.response.data;
    }
}